import "jspdf-autotable"

import * as html2canvas from "html2canvas"
import * as jsPDF from "jspdf"

import { Button, Col, Modal, Row } from "react-bootstrap"
import React, { Component } from "react"
import { orderActions, usersActions } from "../../_actions"

import Logo from "../../assets/img/OFFICIAL LOGO TM WITH HEALTHY PLANEAT BLOCK.png"
import Pagination from "react-js-pagination"
import Sidebar from "../../components/sidebar"
import _ from "lodash"
import { connect } from "react-redux"
import exportFromJSON from "export-from-json"
import { toast } from "react-toastify"

const days = (type) => {
  const dayKeys = ["Sun", "Mon", "Tue", "Wed", "Thur", "Fri", "Sat"]

  return dayKeys[type]
}

const OrderHarvestItem = (orderInfo) => {
  let json = []
  if (orderInfo[0]) {
    _.each(orderInfo, function (value, i) {
      let countQuantity = 0

      value.buyLists.map((val, index) => {
        countQuantity = val.productQuantity + countQuantity
        json.push(val.productName, countQuantity)
      })
    })
  } else {
    orderInfo.buyLists.map((val, index) => {
      json.push(val.productName)
    })
  }

  if (json && typeof json === "object") {
    json = _.uniqBy(json)
  }

  let dataJsx = []
  json.map((val, i) => {
    dataJsx.push(<td>{val}</td>)
  })

  return dataJsx
}

class Order1 extends Component {
  constructor(props) {
    super(props)

    this.state = {
      orderInfo: [],
      ItemList: [],
      reId: [],
      ItemId: [],
      multiValue: [],
      multiItemValue: [],
      reasonErMsg: "",
      show: false,
      showInvoice: false,
      messagePopup: 0,
      alertPopup: 0,
      emailPopup: 0,
      lat: 0,
      long: "",
      count: 10,
      totalCount: 0,
      // filterHarvest: '',
      pageNo: 1,
      orderInfo1: [],
      profilePicPreview: "",
      userId: localStorage.getItem("userId"),
    }
  }

  componentDidMount() {
    this.getUserInfo()
    this.getOrderList()
  }

  printDocument = () => {
    let self = this

    let {
      search,
      pageNo,
      count,
      sortField,
      sortType,
      inventoryType,
      userId,
      status,
    } = this.state
    let params = `?&userId=${userId}&count=${500}`

    let { dispatch } = this.props
    dispatch(orderActions.getOrderList(params)).then((data) => {
      let inventoryData = data

      self.setState({ loader: false })

      if (inventoryData.statusCode == 1) {
        console.log(data, "datadatadata")
        let inventoryInfo1 = data && data.responseData.OrderDetails.getOrder

        var doc = new jsPDF()

        if (inventoryInfo1) {
          doc.setFontSize(22)
          doc.text(20, 20, "This is a title")

          doc.autoTable({ html: "#divToPrint1" })
          let table1 = inventoryInfo1.map((item, index) => [
            index + 1,
            self.ConvertDate(item.created),
            item.billingInfo.firstName + " " + item.billingInfo.lastName,
            "$" + item.amount,
            item.billingInfo.addressLine1 +
              item.billingInfo.addressLine2 +
              item.billingInfo.city +
              item.billingInfo.state +
              item.billingInfo.country +
              item.billingInfo.postalCode,
            item.harvestStatus === "1"
              ? "In Harvest List"
              : item.harvestStatus === "2"
              ? "Fulfilled"
              : item.harvestStatus === "3"
              ? "Out of Stock"
              : "New Order",
            item.description ? item.description : "",
          ])

          doc.autoTable({
            head: [
              [
                "Order",
                "Placed On",
                "Placed On",
                "Order Total",
                "Pick Up/Delivery Location",
                "Status",
              ],
            ],
            body: table1.length == 0 ? [["no data available "]] : table1,
          })
          setTimeout(() => doc.save("order.pdf"), 3000)
        }
      } else {
        this.setState({ errors: inventoryData.error.responseMessage })
      }
    })
  }
  modalPrint = () => {
    const input2 = document.getElementById("divIdToPrint")
    html2canvas(input2).then((canvas) => {
      document.body.appendChild(canvas)
      const imgData = canvas.toDataURL("image/png")
    })

    html2canvas(document.getElementById("divIdToPrint"), {
      logging: true,
      letterRendering: 1,
      allowTaint: false,
      useCORS: true,
    }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png")
      const pdf = new jsPDF()
      pdf.addImage(imgData, "PNG", 10, 10)
      pdf.save("download.pdf")
    })
  }

  printExcel = () => {
    let self = this

    let { userId } = this.state
    let params = `?&userId=${userId}&count=${500}`

    let { dispatch } = this.props
    dispatch(orderActions.getOrderList(params)).then((data) => {
      let inventoryData = data

      self.setState({ loader: false })

      if (inventoryData.statusCode == 1) {
        let inventoryInfo1 = data && data.responseData.OrderDetails.getOrder

        var doc = new jsPDF()

        if (inventoryInfo1) {
          const fileName = "download"

          const exportType = "csv"
          console.log(inventoryInfo1, "datadatadata")

          if (inventoryInfo1) {
            let len = inventoryInfo1.length - 1

            let arr = []

            console.log("OrderHarvest1OrderHarvest1")
            doc.autoTable({ html: "#divToPrint1" })
            inventoryInfo1.map((item, index) => {
              arr.push({
                ORDER: index + 1,
                CUSTOMER:
                  item.billingInfo.firstName + " " + item.billingInfo.lastName,
                ITEM: this.OrderHarvest1(item),
                UNIT: "sdds",
                QUANTITY: "kg",
                Total: "$" + item.amount,
                "": "",
                "PACK LIST": "",
                "TOTAL COUNT": "",
              })
            })

            exportFromJSON({
              data: arr,
              fileName: "data",
              exportType: exportFromJSON.types.xls,
            })
          }
        }
      } else {
        this.setState({ errors: inventoryData.error.responseMessage })
      }
    })
  }

  sum = (product) => {
    if (!_.isEmpty(product)) {
      let countPrice = 0
      for (let i = 0; i < product.length; i++) {
        countPrice =
          product[i].productPrice * product[i].productQuantity + countPrice
      }

      return countPrice
    }
  }
  sumDelivery = (product) => {
    if (!_.isEmpty(product)) {
      let countPrice = 0
      for (let i = 0; i < product.length; i++) {
        countPrice = product[i].deliveryFees + countPrice
      }

      return countPrice
    }
  }
  getUserInfo = () => {
    this.setState({ errors: "" })

    let self = this
    this.setState({ redirect: false, loader: false })

    let { dispatch } = this.props
    dispatch(usersActions.getUserInfo()).then((data) => {
      this.setState({ loader: false })

      if (data.statusCode == 1) {
        let userInfo = data.responseData.userProfile
        localStorage.setItem("userId", userInfo._id)

        this.setState({
          userInfo: userInfo,
          firstName: userInfo.firstName,
          lastName: userInfo.lastName,
          phone: userInfo.phone,
          email: userInfo.email,
          address: userInfo.address,
          addressLine1:
            userInfo.address.length > 0
              ? userInfo.address[0].addressLine1
                ? userInfo.address[0].addressLine1
                : ""
              : "",
          addressLine2:
            userInfo.address.length > 0
              ? userInfo.address[0].addressLine2
                ? userInfo.address[0].addressLine2
                : ""
              : "",
          state:
            userInfo.address.length > 0
              ? userInfo.address[0].state
                ? userInfo.address[0].state
                : ""
              : "",
          postalCode:
            userInfo.address.length > 0
              ? userInfo.address[0].postalCode
                ? userInfo.address[0].postalCode
                : ""
              : "",
          city:
            userInfo.address.length > 0
              ? userInfo.address[0].city
                ? userInfo.address[0].city
                : ""
              : "",
          addressId:
            userInfo.address.length > 0
              ? userInfo.address[0]._id
                ? userInfo.address[0]._id
                : ""
              : "",
          minimumOrderValue: userInfo.minimumOrderValue,
          profilePic: userInfo.profileImage,
          profilePicPreview: userInfo.profileImage,
          certificate: userInfo.certificateImage,
          certificatePreview: userInfo.certificateImage,
          insuranceDoc: userInfo.insuranceImage,
          insurancePreview: userInfo.insuranceImage,
          radius: userInfo.radius,
          emailPopup: userInfo.notificationEmail,
          messagePopup: userInfo.notificationTextMassage,
          alertPopup: userInfo.notificationAppAlert,
          lat: userInfo.geoLocation[0],
          long: userInfo.geoLocation[1],
        })
      } else {
        this.setState({ errors: data.error.responseMessage })
      }
    })
  }
  handlePageChange = (pageNo) => {
    var self = this
    this.setState({ loader: true, pageNo: pageNo, count: 10 }, () => {
      self.getOrderList()
    })
  }
  getOrderList = () => {
    this.setState({ errors: "" })
    let { search, userId, filterHarvest } = this.state

    this.setState({ redirect: false, loader: true })
    let params = `?&search=${search ? search : ""}&userId=${
      userId ? userId : ""
    }&count=${10}&filterHarvest=${filterHarvest}`

    let { dispatch } = this.props
    dispatch(orderActions.getOrderList(params)).then((data) => {
      this.setState({ loader: false })

      if (data.statusCode == 1) {
        let orderInfo = data.responseData.OrderDetails.getOrder

        this.setState({
          orderInfo: orderInfo,
          totalCount: data.responseData.OrderDetails.totalOrder,
          count: data.responseData.OrderDetails.getOrderCount,
        })
      } else {
        this.setState({ errors: data.error.responseMessage })
      }
    })
  }
  getOrderList = () => {
    this.setState({ errors: "" })
    let { search, userId, count, filterHarvest } = this.state
    let self = this
    this.setState({ redirect: false, loader: true })
    let params = `?&search=${search ? search : ""}&userId=${
      userId ? userId : ""
    }&count=${10}&filterHarvest=${filterHarvest}`

    let { dispatch } = this.props
    dispatch(orderActions.getOrderList(params)).then((data) => {
      this.setState({ loader: false })

      if (data.statusCode == 1) {
        let orderInfo = data.responseData.OrderDetails.getOrder

        this.setState({
          orderInfo: orderInfo,
          totalCount: data.responseData.OrderDetails.totalOrder,
          count: data.responseData.OrderDetails.getOrderCount,
        })
      } else {
        this.setState({ errors: data.error.responseMessage })
      }
    })
  }

  handleFilter = (ele) => {
    this.setState(
      {
        loader: true,
        reasonErMsg: "",
        filterHarvest: ele,
        count: 10,
      },
      () => this.getOrderList()
    )
  }

  handleClose = () => {
    this.setState({ show: false, showInvoice: false })
  }

  ConvertDate = (created) => {
    var created = new Date(created)

    let year = created.getFullYear()
    let month = created.getMonth()
    let date = created.getDate()
    let Hour = created.getHours()
    let Minutes = created.getMinutes()
    let day = created.getDay()

    let formedDate = month + "/" + date + "/" + year
    let formedTime = Hour + ":" + Minutes
    let am = Hour > 12 ? "pm" : "am"
    let data = formedTime + " " + am + " " + days(day) + " " + formedDate

    return data
  }

  notify(text, msg) {
    if (text === "success") {
      toast.success(msg, {
        position: toast.POSITION.TOP_RIGHT,
      })
    } else {
      toast.error(msg, {
        position: toast.POSITION.TOP_RIGHT,
      })
    }
  }

  ChangeHarvestStatus = (id, harvestStatus) => {
    let obj = {
      orderId: id,
      harvestStatus,
    }
    let self = this
    let { dispatch } = this.props
    self.setState({ loader: true })
    self.setState({ loader: false, show: false })
    dispatch(orderActions.changeHarvestStatus(obj)).then((data) => {
      if (data.statusCode == 1) {
        self.setState({ loader: false })
        this.notify("success", data.responseData.message)
        this.getOrderList()
      } else {
        self.setState({ loader: false })
      }
    })
  }
  handleShow = (item) => {
    this.setState({ show: true, ItemList: item })
  }
  handleShowInvoice = (item) => {
    this.setState({ showInvoice: true, ItemList: item })
  }
  OrderMp = (ItemList) => {
    console.log(ItemList, "ItemListItemList")
    let Json =
      ItemList.buyLists &&
      ItemList.buyLists.map((val, i) => (
        <tr>
          <td>
            {val.productQuantity} {val.productName}
          </td>
          <td>
            <span>${val.productPrice * val.productQuantity}</span>
          </td>
          <td>
            <span className="inlinetext-align">
              <span className="inabs">
                <input
                  type="checkbox"
                  indeterminate
                  value={val.productId}
                  onChange={(e) =>
                    this.handleItemMaltiSelct(
                      e,
                      val.productId && val.productId,
                      ItemList.buyLists,
                      ItemList._id
                    )
                  }
                />
              </span>
              <span className="cm-aligntext">
                {val.itemStatus == 1
                  ? "Harvested"
                  : val.itemStatus == 2
                  ? "Fulfilled"
                  : val.itemStatus == 3
                  ? "Out of Stock"
                  : "Pending"}
              </span>
            </span>
          </td>
          <td>
            {val.image ? (
              <img src={val.image} height="40px" width="40px" />
            ) : (
              ""
            )}
          </td>
          <td>{val.farmName}</td>
          <td>{val.address}</td>
        </tr>
      ))

    return Json
  }
  OrderMpInvoice = (ItemList) => {
    console.log(ItemList, "ItemListItemList")
    let Json =
      ItemList.buyLists &&
      ItemList.buyLists.map((val, i) => (
        <tr>
          <p className="marGinOrderTwo">
            {" "}
            {val.productName} - {val.productQuantity}
          </p>
          <td>
            <span>${val.productPrice * val.productQuantity}</span>
          </td>
          <td></td>
        </tr>
      ))

    return Json
  }

  OrderHarvest1 = (ItemList) => {
    let Json =
      ItemList.buyLists && ItemList.buyLists.map((val, i) => val.productName)

    return Json
  }
  OrderHarvest2 = (ItemList) => {
    let Json =
      ItemList.buyLists &&
      ItemList.buyLists.map((val, i) => (
        <>
          {val.productQuantity} <br />
        </>
      ))

    return Json
  }
  OrderHarvest3 = (ItemList) => {
    let Json =
      ItemList.buyLists &&
      ItemList.buyLists.map((val, i) => (
        <>
          $ {val.productQuantity * val.productPrice}
          <br />
        </>
      ))

    return Json
  }

  handleValidate = () => {
    let validate = true
    if (
      this.state.multiValue === "" ||
      this.state.multiValue.length < 0 ||
      this.state.multiValue.length === 0 ||
      this.state.multiValue === undefined
    ) {
      validate = false
      this.setState({
        reasonErMsg: "Please select check box.",
      })
    } else {
      this.setState({
        reasonErMsg: "",
      })
    }

    return validate
  }

  handleMaltiSelct = (e, val) => {
    let { orderInfo, multiValue } = this.state
    this.setState({ reasonErMsg: "" })
    multiValue = []

    if (this.state.reId.includes(val)) {
      for (var i = 0; i < this.state.reId.length; i++) {
        if (this.state.reId[i] === val) {
          this.state.reId.splice(i, 1)
        }
      }
    } else {
      this.state.reId.push(val)
    }

    this.state.orderInfo.map(function (value, index) {
      if (value._id == val) {
        orderInfo[index].status = orderInfo[index].status === 2 ? 3 : 2

        if (orderInfo[index].status == 2) {
          multiValue.push(value._id)
        } else if (orderInfo[index].status == 3) {
          multiValue.splice(value._id)
        }
      }
    })

    this.setState({ multiValue: [...this.state.multiValue, val] })
  }

  handleItemMaltiSelct = (e, val, ItemList) => {
    let { orderInfo, multiItemValue } = this.state
    let self = this
    this.setState({ reasonErMsg: "" })
    multiItemValue = []
    let ItemIdval = []

    if (this.state.itemId != undefined && this.state.itemId.includes(val)) {
      for (var i = 0; i < this.state.itemId.length; i++) {
        if (this.state.itemId[i] === val) {
          this.state.itemId.splice(i, 1)
        }
      }
    } else {
      if (this.state.itemId != undefined) {
        this.state.itemId.push(val)
      }
    }

    ItemList.map(function (value, index) {
      if (value._id == val) {
        ItemList[index].status = orderInfo[index].status === 2 ? 3 : 2

        if (ItemList[index].itemStatus == 2) {
          multiItemValue.push(value._id)
        } else if (ItemList[index].itemStatus == 3) {
          multiItemValue.splice(value._id)
        }
      }
    })
    this.setState({ multiItemValue: [...this.state.multiItemValue, val] })
  }

  handleAction = (e) => {
    console.log(this.state.multiValue, "this.state.multiValue.toString()")
    let unique = [...new Set(this.state.multiValue)]
    console.log(unique.toString(), "unique")
    let params = {
      orderId: unique.toString(),
      harvestStatus: e,
    }
    if (this.handleValidate()) {
      let self = this
      let { dispatch } = this.props
      self.setState({ loader: true })
      self.setState({ loader: false, show: false })

      dispatch(orderActions.changeOrderStatus(params)).then((data) => {
        if (data.statusCode == 1) {
          self.setState({
            loader: false,
            reId: [],
            multiValue: "",
            reasonErMsg: "",
          })
          this.notify("success", data.responseData.message)
          this.getOrderList()
        } else {
          self.setState({ loader: false })
          this.setState({
            reId: [],
            multiValue: "",
            reasonErMsg: "",
            errors: data.error.responseMessage,
          })
        }
      })
    } else {
    }
  }
  handleItemAction = (id, harvestStatus) => {
    let unique = [...new Set(this.state.multiItemValue)]
    let params = {
      orderId: id,
      itemId: unique,
      itemStatus: harvestStatus,
    }

    let self = this
    let { dispatch } = this.props
    self.setState({ loader: true })
    self.setState({ loader: false, show: false })

    dispatch(orderActions.changeOrderItemStatus(params)).then((data) => {
      if (data.statusCode == 1) {
        self.setState({
          loader: false,
          itemId: [],
          multiValue: "",
          reasonErMsg: "",
        })
        this.notify("success", data.responseData.message)
        this.getOrderList()
      } else {
        self.setState({ loader: false })

        this.setState({
          itemId: [],
          multiValue: "",
          reasonErMsg: "",
          errors: data.error.responseMessage,
        })
      }
    })
  }

  render() {
    let {
      filterHarvest,
      reasonErMsg,
      orderInfo,
      count,
      pageNo,
      totalCount,
      dataCount,
      ItemList,
      profilePicPreview,
    } = this.state
    return (
      <>
        <Sidebar />
        <div className="body_container">
          <div className="body_inner_wrapper">
            {this.state.loader ? (
              <div className="loader_wrapper">
                <div class="spinner-border text-info" role="status"></div>
              </div>
            ) : (
              <>
                <header className="header_content">
                  <p>Orders</p>
                  <div className="d-flex text-nowrap header_btn">
                    <Button
                      variant="primary"
                      disabled={_.isEmpty(this.state.orderInfo) ? true : false}
                      onClick={() => this.handleAction(1)}
                    >
                      {" "}
                      Add to Harvest List
                    </Button>
                    <Button
                      variant="info"
                      disabled={_.isEmpty(this.state.orderInfo) ? true : false}
                      onClick={() => this.handleAction(2)}
                    >
                      {" "}
                      Order Fulfilled
                    </Button>

                    <Button
                      variant="secondary"
                      onClick={() => this.handleFilter(1)}
                    >
                      {" "}
                      View Harvest List
                    </Button>
                    <Button
                      variant="warning"
                      onClick={() => this.handleFilter(2)}
                    >
                      {" "}
                      View Order Fulfilled
                    </Button>
                    <Button
                      variant="success"
                      onClick={() => this.handleFilter("")}
                    >
                      {" "}
                      All Orders
                    </Button>

                    <Button onClick={this.printExcel} variant="info">
                      <i class="fas fa-upload"></i> Export as Excel
                    </Button>
                    <div className="print_button">
                      <Button onClick={this.printDocument} variant="light">
                        <i class="fas fa-print"></i> Print{" "}
                      </Button>
                    </div>
                  </div>
                </header>
                {filterHarvest == "" ? (
                  <div className="main_content">
                    <div class="table-responsive">
                      <table class="table cm_bottom_space">
                        <thead>
                          <tr class="broder_top_0">
                            <th scope="col" className="ws-nowrap">
                              Order
                            </th>
                            <th scope="col">Placed On</th>
                            <th scope="col" className="ws-nowrap">
                              Customer
                            </th>
                            <th scope="col" className="ws-nowrap">
                              {" "}
                              Total
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {!_.isEmpty(orderInfo) &&
                            orderInfo.map((item, i) => (
                              <tr key={item._id}>
                                <td>{count * (pageNo - 1) + (i + 1)}</td>
                                <td>{this.ConvertDate(item.created)}</td>
                                <td>
                                  {item.billingInfo.firstName +
                                    " " +
                                    item.billingInfo.lastName}
                                </td>
                                <td>${item.amount}</td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                      {orderInfo && orderInfo.length ? (
                        <div className="d-flex justify-content-end cm-pagination">
                          <Pagination
                            itemClassPrev={"prevClass"}
                            itemClassNext={"nextClass"}
                            activePage={this.state.pageNo}
                            itemsCountPerPage={this.state.count}
                            totalItemsCount={this.state.totalCount}
                            pageRangeDisplayed={3}
                            onChange={this.handlePageChange}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                      {_.isEmpty(this.state.orderInfo) ? (
                        <h3 className="text-center">No data found</h3>
                      ) : (
                        ""
                      )}
                      {reasonErMsg ? (
                        <div className="text-center error ">{reasonErMsg}</div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                ) : (
                  <div className="main_content">
                    <div class="table-responsive">
                      <table class="table cm_bottom_space">
                        <thead>
                          <tr class="broder_top_0">
                            <th scope="col" className="ws-nowrap">
                              Order
                            </th>
                            <th scope="col">Placed On</th>
                            <th scope="col" className="ws-nowrap">
                              Customer
                            </th>
                            <th scope="col" className="ws-nowrap">
                              Item
                            </th>
                            <th scope="col" className="ws-nowrap">
                              {" "}
                              Unit
                            </th>
                            <th scope="col">Price</th>
                            <th scope="col"></th>
                            <th scope="col">Pack List</th>
                            <th scope="col">Total Count</th>
                          </tr>
                        </thead>
                        <tbody>
                          {!_.isEmpty(orderInfo) &&
                            orderInfo.map((item, i) => (
                              <tr key={item._id}>
                                <td>{count * (pageNo - 1) + (i + 1)}</td>
                                <td>{this.ConvertDate(item.created)}</td>
                                <td>
                                  {item.billingInfo.firstName +
                                    " " +
                                    item.billingInfo.lastName}
                                </td>
                                {/*{item}*/}
                                <td> {this.OrderHarvest1(item)}</td>
                                {/*{unit}*/}
                                <td> {this.OrderHarvest2(item)}</td>
                                {/*{price}*/}
                                <td> {this.OrderHarvest3(item)}</td>

                                <td></td>
                              </tr>
                            ))}

                          <OrderHarvestItem {...orderInfo} />
                        </tbody>
                      </table>
                      {orderInfo && orderInfo.length ? (
                        <div className="d-flex justify-content-end cm-pagination">
                          <Pagination
                            itemClassPrev={"prevClass"}
                            itemClassNext={"nextClass"}
                            activePage={this.state.pageNo}
                            itemsCountPerPage={this.state.count}
                            totalItemsCount={this.state.totalCount}
                            pageRangeDisplayed={3}
                            onChange={this.handlePageChange}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                      {_.isEmpty(this.state.orderInfo) ? (
                        <h3 className="text-center ">No data found</h3>
                      ) : (
                        ""
                      )}
                      {reasonErMsg ? (
                        <div className="text-center error ">{reasonErMsg}</div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                )}{" "}
              </>
            )}{" "}
          </div>

          <Modal
            aria-labelledby="example-modal-sizes-title-sm"
            show={this.state.show}
            onHide={this.handleClose}
            className="cm_order_view order-detailsmodal"
          >
            <Modal.Header closeButton>
              <Modal.Title id="example-modal-sizes-title-sm">
                <h6>Order Details</h6>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div id={"divIdToPrint"}>
                <Row>
                  <Col md="12">
                    {console.log(ItemList, "ItemListItemList")}
                    {ItemList.billingInfo && ItemList.billingInfo.firstName ? (
                      <p>
                        Name:{" "}
                        <span>
                          {ItemList.billingInfo.firstName}{" "}
                          {ItemList.billingInfo.lastName}
                        </span>
                      </p>
                    ) : (
                      ""
                    )}

                    {ItemList.billingInfo &&
                    ItemList.billingInfo.phoneNumber ? (
                      <p>
                        Phone: <span>{ItemList.billingInfo.phoneNumber} </span>
                      </p>
                    ) : (
                      ""
                    )}
                    {ItemList && ItemList.amount ? (
                      <p>
                        Order Total: <span>${ItemList.amount} </span>
                      </p>
                    ) : (
                      ""
                    )}
                    {ItemList && ItemList.harvestStatus ? (
                      <p>
                        Status:{" "}
                        <span>
                          {ItemList.harvestStatus == "0" ? "Pending" : ""}{" "}
                          {ItemList.harvestStatus == 1 ? "Harvested" : ""}{" "}
                          {ItemList.harvestStatus == 2 ? "Fulfilled" : ""}
                          {ItemList.harvestStatus == 3 ? "Out of Stock" : ""}
                        </span>
                      </p>
                    ) : (
                      ""
                    )}

                    {ItemList.billingInfo && ItemList.billingInfo ? (
                      <p>
                        Address:{" "}
                        <span>
                          {ItemList.billingInfo.addressLine1}{" "}
                          {ItemList.billingInfo.addressLine2}{" "}
                          {ItemList.billingInfo.city}{" "}
                          {ItemList.billingInfo.state}{" "}
                          {ItemList.billingInfo.country}{" "}
                          {ItemList.billingInfo.postalCode}
                        </span>
                      </p>
                    ) : (
                      ""
                    )}
                  </Col>
                </Row>
                <div className="order_items">
                  <div className="table-responsive">
                    <table className="table">
                      <thead>
                        <th className="ws-nowrap">Product Name</th>
                        <th className="ws-nowrap">Product Price</th>
                        <th className="ws-nowrap">Status</th>
                        <th className="ws-nowrap">Product Image</th>
                        <th className="ws-nowrap">Farm Name</th>
                        <th className="ws-nowrap">Farm Address</th>
                      </thead>
                      <tbody>{this.OrderMp(ItemList)}</tbody>
                    </table>
                  </div>
                </div>
                <div className="">
                  <p>
                    Order Total:
                    <span>${ItemList.amount}</span>
                  </p>

                  <p>
                    Delivery Charges:
                    <span>
                      ${this.sumDelivery(ItemList && ItemList.buyLists)}
                    </span>
                  </p>

                  <p>
                    Total:
                    <span>
                      {" "}
                      $
                      {ItemList.amount +
                        this.sumDelivery(ItemList && ItemList.buyLists)}
                    </span>
                  </p>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <div className="footer_btn_wrapper">
                <Button
                  variant="warning"
                  className="text-light"
                  onClick={() => this.handleItemAction(ItemList._id, 2)}
                >
                  Order Fulfilled
                </Button>
                <Button
                  variant="dark"
                  onClick={() => this.handleItemAction(ItemList._id, 3)}
                >
                  Out Of Stock
                </Button>
                <Button
                  variant="info"
                  onClick={() => this.handleItemAction(ItemList._id, 1)}
                >
                  Add To Harvest
                </Button>
              </div>
              <div className="print_button orderabs">
                <Button onClick={this.modalPrint} variant="light">
                  <i className="fas fa-print"></i> Print{" "}
                </Button>
              </div>
            </Modal.Footer>
          </Modal>

          <Modal
            aria-labelledby="example-modal-sizes-title-sm"
            show={this.state.showInvoice}
            onHide={this.handleClose}
            className="cm_order_view order-detailsmodal"
          >
            <Modal.Header closeButton>
              <Modal.Title id="example-modal-sizes-title-sm">
                <h6>Invoice</h6>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div id={"divIdToPrint"}>
                <Row>
                  <Col md="12">
                    {console.log(
                      ItemList,
                      "ItemListItemList",
                      profilePicPreview
                    )}

                    <p className="text-center">
                      {profilePicPreview ? (
                        <img
                          src={profilePicPreview}
                          height="160px"
                          width="160px"
                        />
                      ) : (
                        ""
                      )}
                    </p>

                    {ItemList.billingInfo && ItemList.billingInfo.firstName ? (
                      <p>
                        Customer:{" "}
                        <span>
                          {ItemList.billingInfo.firstName}{" "}
                          {ItemList.billingInfo.lastName}
                        </span>
                      </p>
                    ) : (
                      ""
                    )}

                    {ItemList.billingInfo &&
                    ItemList.billingInfo.phoneNumber ? (
                      <p>
                        Phone: <span>{ItemList.billingInfo.phoneNumber} </span>
                      </p>
                    ) : (
                      ""
                    )}

                    {ItemList && ItemList.harvestStatus ? (
                      <p>
                        Status:{" "}
                        <span>
                          {ItemList.harvestStatus == "0" ? "Pending" : ""}{" "}
                          {ItemList.harvestStatus == 1 ? "Harvested" : ""}{" "}
                          {ItemList.harvestStatus == 2 ? "Fulfilled" : ""}
                          {ItemList.harvestStatus == 3 ? "Out of Stock" : ""}
                        </span>
                      </p>
                    ) : (
                      ""
                    )}

                    {ItemList.billingInfo && ItemList.billingInfo ? (
                      <p>
                        Order Pick Up Location:{" "}
                        <span>
                          {ItemList.billingInfo.addressLine1}{" "}
                          {ItemList.billingInfo.addressLine2}{" "}
                          {ItemList.billingInfo.city}{" "}
                          {ItemList.billingInfo.state}{" "}
                          {ItemList.billingInfo.country}{" "}
                          {ItemList.billingInfo.postalCode}
                        </span>
                      </p>
                    ) : (
                      ""
                    )}

                    {
                      <p>
                        Address:
                        <span>{this.ConvertDate(ItemList.created)}</span>
                      </p>
                    }
                  </Col>
                </Row>
                <div className="order_items">
                  <div className="table-responsive">
                    <table className="">
                      <thead>
                        <th className="">Order:</th>
                      </thead>
                      <tbody>{this.OrderMpInvoice(ItemList)}</tbody>
                    </table>
                  </div>
                </div>
                <div>
                  <br />
                  <br />

                  <p className="marGinOrder">
                    Order Total:
                    <span>${ItemList.amount}</span>
                  </p>

                  <p className="marGinOrder">
                    Delivery Charges:
                    <span>
                      ${this.sumDelivery(ItemList && ItemList.buyLists)}
                    </span>
                  </p>

                  <p className="marGinOrder">
                    Total:
                    <span>
                      {" "}
                      $
                      {ItemList.amount +
                        this.sumDelivery(ItemList && ItemList.buyLists)}
                    </span>
                  </p>
                  <p className="text-center">
                    Thank you for your order from Four Root Farm and Healthy
                    PlanEat!
                  </p>
                  <br />
                  <br />
                  <p className="text-center">
                    {profilePicPreview ? (
                      <img src={Logo} height="130px" width="160px" />
                    ) : (
                      ""
                    )}
                  </p>
                </div>
              </div>
              &nbsp;
              <br />
              <br />
            </Modal.Body>
            <Modal.Footer>
              <div className="footer_btn_wrapper"></div>
              <div className="print_button orderabs">
                <Button onClick={this.modalPrint} variant="light">
                  <i className="fas fa-print"></i> Print{" "}
                </Button>
              </div>
            </Modal.Footer>
          </Modal>
        </div>
      </>
    )
  }
}

function mapStateToProps(state) {
  const { props, login, user, mapReducer } = state
  return {
    props,
    login,
    user,
    mapReducer,
  }
}

export default connect(mapStateToProps)(Order1)
