import API from "../config/API"
import axios from "axios"

export const usersService = {
  signup,
  forgot,
  reset,
  login,
  logout,
  getUserInfo,
  getBankInfo,
  editProfile,

  addAddress,
  editAddress,

  // USPS
  verifyAddress,
  domesticRate,
  domesticPackageLabel,
  trackDomesticPackage,
  cancelLabel,
  getOAuthToken,
  isLoggedIn,
  entryFacilities,

  uploadDocument,
  changePassword,
  addAccount,
  addFarm,
  editFarm,
  editMedia,
  deleteMedia,

  getInventoryList,
  getInvList,
  addInventoryList,
  editInventoryItem,

  getFarmDetails,
  deleteInventory,
  changeInventoryStatus,
  accountOauthToken,
}

const headersApplicationJson = {
  // 'Authorization': 'Basic aGVhbHRoeVBsYW5ldF9hZG1pbl92WnBHaDQ6YWRtaW5AaGVhbHRoeVBsYW5ldF9GVGtWcFE=',
  "Content-Type": "application/json",
  // 'accessToken': localStorage.getItem('accessToken')
}
const headersMultipart = {
  "Content-type": "multipart/form-data",
}

function signup(params) {
  return axios.post(API.SIGN_UP, params, { headers: headersApplicationJson })
}

function forgot(params) {
  return axios.post(API.FORGOT, params)
}

function reset(params, token) {
  let headers = {
    "Content-Type": "application/json",
    accessToken: token,
    Accept: "application/json",
  }

  return axios.post(API.RESET, params, { headers: headers })
}

function login(params) {
  return axios.post(API.LOGIN, params)
}

function logout(params) {
  return axios.post(API.LOGOUT, params)
}

function getUserInfo(params) {
  return axios.get(API.GET_USER_INFO, params)
}
function getBankInfo(params) {
  return axios.get(API.GET_BANK_DETAILS, params)
}

function getFarmDetails(params) {
  return axios.get(`${API.GET_FARM_INFO}/${params}`)
}

function editProfile(params) {
  return axios.post(API.EDIT_PROFILE, params)
}

function uploadDocument(params) {
  return axios.post(API.UPLOAD_DOC, params)
}

function addAddress(params) {
  return axios.post(API.ADD_ADDRESS, params)
}

function editAddress(params) {
  return axios.post(API.EDIT_ADDRESS, params)
}

// This is for USPS
function verifyAddress(params) {
  return axios.post(API.VERIFY_USPS_ADDRESS, params)
}

function domesticRate(params) {
  return axios.post(API.GET_USPS_DOMESTIC_RATE, params)
}

function domesticPackageLabel(params) {
  return axios.post(API.GET_USPS_DOMESTIC_PACKAGE_LABEL, params)
}

function trackDomesticPackage(params) {
  return axios.post(API.TRACK_USPS_DOMESTIC_PACKAGE, params)
}

function changePassword(params) {
  return axios.post(API.CHANGE_PASSWORD, params)
}

function accountOauthToken(params) {
  return axios.post(API.AUTH_TOKEN, params)
}
function addAccount(params) {
  return axios.post(API.ADD_ACCOUNT, params)
}

function getInventoryList(params) {
  return axios.get(`${API.GET_INVENTORY_LIST}/${params}`)
}

function getInvList(params) {
  return axios.get(`${API.GET_INV_LIST}/${params}`)
}

function addInventoryList(params) {
  return axios.post(API.ADD_INVENTORY_ITEM, params)
}

function editInventoryItem(params) {
  return axios.post(API.EDIT_INVENTORY_ITEM, params)
}

function addFarm(params) {
  return axios.post(API.ADD_FARM, params, { headers: headersMultipart })
}

function editFarm(params) {
  return axios.post(API.EDIT_FARM, params, { headers: headersMultipart })
}

function editMedia(params) {
  return axios.post(API.EDIT_MEDIA, params, { headers: headersMultipart })
}

function deleteInventory(params) {
  return axios.post(API.DELETE_INVENTORY, params)
}

function deleteMedia(params) {
  return axios.post(API.DELETE_MEDIA, params)
}

function changeInventoryStatus(params) {
  return axios.post(API.CHANGE_INVENTORY_STATUS, params)
}

function cancelLabel(params) {
  return axios.post(API.CANCEL_USPS_LABEL, params, {
    headers: headersApplicationJson,
  })
}

function isLoggedIn(params) {
  return axios.post(API.IS_LOGGED_IN, params)
}

function getOAuthToken(params) {
  return axios.post(API.GET_OAUTH_TOKEN, params)
}

function entryFacilities(params) {
  return axios.post(API.ENTRY_FACILITIES, params)
}
