import siteSetting from "../config/env/index"

export const getStripeConnectRedirect = () => {
  let redirectUrl
  if (siteSetting.isFarmer) {
    redirectUrl = siteSetting.config.farmerStripeRedirectUrl
  }
  if (siteSetting.isHost) {
    redirectUrl = siteSetting.config.hostStripeRedirectUrl
  }
  const stripeRedirect = `https://connect.stripe.com/express/oauth/authorize?response_type=code&client_id=${siteSetting.config.client_id}&scope=read_write&redirect_uri=${redirectUrl}`
  return stripeRedirect
}
