export default {
  api: {
    url: "https://api.healthyplaneat.com/healthyplaneat/api/v1/",
    mode: "cors",
  },
  config: {
    privacy_policy: "privacypolicies",
    terms_services: "termsservices",
    client_id: "ca_HUzvSRC4j61hNXHtRVYEI3a81w7lu2cP",
    farmerStripeRedirectUrl:
      "https://farmer.healthyplaneat.com/account-oauth-token",
    hostStripeRedirectUrl:
      "https://host.healthyplaneat.com/account-oauth-token",
    USPSLoginURL:
      "https://cop.usps.com/cop-navigator?wf=merchant_user&client_id=DCvGCXqAy2fUegWd6Y4XYCEoPW9SNnuf&platform=HealthyPlanEat",
    USPSRedirectURL: "https://farmer.healthyplaneat.com/order",
  },
}
