import { Form, Button, Col } from "react-bootstrap"
import React from "react"

const USPSBoxForm = ({
  errors,
  box,
  index,
  boxList,
  handleRemoveBox,
  handleBoxInputChange,
  uspsBoxRates,
  ratesByBox,
  handleSelectRate,
}) => {
  const getErrorMessage = (index, name) => {
    if (errors && errors[index] && errors[index][name]) {
      return <span className="error_msg">{errors[index][name]}</span>
    }
    return null
  }

  const renderRateOptions = () => {
    const rates = ratesByBox[box.id] || []
    if (rates.length > 0) {
      return (
        <Form.Group>
          <Form.Label>Rate Options</Form.Label>
          <Form.Control
            as="select"
            value={box.selectedRate ? box.selectedRate.SKU : ""}
            onChange={(e) => {
              const selectedRate = rates.find(
                (rate) => rate.SKU === e.target.value
              )
              handleSelectRate(box, selectedRate)
            }}
          >
            <option value="">Select a rate</option>
            {rates.map((rate, rateIndex) => (
              <option key={rateIndex} value={rate.SKU}>
                {rate.description} - ${rate.price.toFixed(2)}
              </option>
            ))}
          </Form.Control>
        </Form.Group>
      )
    }
  }

  return (
    <div className="usps_box_border">
      <Form.Row className="usps_form_row">
        <Form.Group as={Col} className="usps_form_group">
          <Form.Label>Box Length (In Inches)</Form.Label>
          <Form.Control
            type="number"
            placeholder="Length"
            value={box.boxLength}
            onChange={(e) => handleBoxInputChange(e, index)}
            name="boxLength"
          />
          {getErrorMessage(index, "boxLength")}
        </Form.Group>
        <Form.Group as={Col} className="usps_form_group">
          <Form.Label>Box Width (In Inches)</Form.Label>
          <Form.Control
            type="number"
            placeholder="Width"
            value={box.boxWidth}
            onChange={(e) => handleBoxInputChange(e, index)}
            name="boxWidth"
          />
          {getErrorMessage(index, "boxWidth")}
        </Form.Group>

        <Form.Group as={Col} className="usps_form_group">
          <Form.Label>Box Height (In Inches)</Form.Label>
          <Form.Control
            type="number"
            placeholder="Height"
            value={box.boxHeight}
            onChange={(e) => handleBoxInputChange(e, index)}
            name="boxHeight"
          />
          {getErrorMessage(index, "boxHeight")}
        </Form.Group>
      </Form.Row>

      <Form.Group className="usps_form_group">
        <Form.Label>Box Weight</Form.Label>
        <Form.Row className="usps_form_row">
          <Form.Group as={Col}>
            <Form.Control
              type="number"
              placeholder="Pounds (lbs)"
              value={box.boxPounds}
              onChange={(e) => handleBoxInputChange(e, index)}
              name="boxPounds"
            />
            {getErrorMessage(index, "boxPounds")}
          </Form.Group>
          <Form.Group as={Col}>
            <Form.Control
              type="number"
              placeholder="Ounces (oz)"
              value={box.boxOunces}
              onChange={(e) => handleBoxInputChange(e, index)}
              name="boxOunces"
            />
            {getErrorMessage(index, "boxOunces")}
          </Form.Group>
        </Form.Row>
      </Form.Group>

      {boxList.length > 1 ? (
        <Form.Group className="usps_flex_end usps_form_group">
          <Button
            variant="outline-danger"
            onClick={() => handleRemoveBox(index)}
          >
            Remove Box
          </Button>
        </Form.Group>
      ) : null}

      {renderRateOptions()}
    </div>
  )
}

export default USPSBoxForm
