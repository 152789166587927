// import React from 'react'
import React, { Component } from "react"

import { Link } from "react-router-dom"
import { Container, Navbar, Row, Col, Form, Button } from "react-bootstrap"
import { usersActions } from "../../_actions"
import { connect } from "react-redux"

class ForgotPassword extends Component {
  constructor(props) {
    super(props)

    this.state = {
      email: "",
      emailErrorMsg: "",
      errors: "",
      success: "",
    }
  }

  componentDidMount() {}

  validateEmail(email) {
    const pattern =
      /(([a-zA-Z0-9\-?\.?]+)@(([a-zA-Z0-9\-_]+\.)+)([a-z]{2,3}))+$/
    const result = pattern.test(email)
    return result
  }

  handleValidation = () => {
    console.log(
      this.state,
      "pppppppppppppppppppppppppppppppppppppppppppppppppppppppppppppppppppppppppppppppss"
    )
    let validate = true
    let { email, password } = this.state

    if (email === "" || email === undefined) {
      validate = false
      this.setState({
        emailErrorMsg: "Please enter your email address.",
      })
    } else if (!this.validateEmail(String(email.toLowerCase()))) {
      validate = false
      this.setState({ emailErrorMsg: "Please enter a valid email address." })
    } else {
      this.setState({
        emailErrorMsg: "",
      })
    }

    return validate
  }

  //sign in
  handleForgotSubmit = (e) => {
    let { email, long, lat, password } = this.state
    this.setState({ redirect: false, errors: "" })
    e.preventDefault()

    let self = this
    if (this.handleValidation()) {
      this.setState({ redirect: false, loader: true })

      let obj = {
        email: email.toLowerCase(),
      }

      let { dispatch } = this.props
      dispatch(usersActions.forgot(obj)).then((data) => {
        console.log(data, "Data:::::::lkjhgfdsdfghj")

        this.setState({ loader: false })
        // let getData = this.props.user;

        if (data.data.statusCode == 1) {
          let userInfo = data.data.responseData.userProfile
          this.setState({ success: data.data.responseData.message, errors: "" })

          // console.log(self.props.history, " this.props.history");
          // self.props.history.push('/order');
        } else {
          this.setState({ errors: data.data.error.responseMessage })
        }
      })
    }
  }

  handleChanged = (e) => {
    console.log("onchange names", e)
    this.setState({
      [e.target.name]: e.target.value,
      // emailError: ''
    })
  }

  handleCloseModal = () => {
    this.setState({
      rs: "",
      success: "",
      firstNameErrorMsg: "",
      errors: "",
      emailErrorMsg: "",
      passwordErrorMsg: "",
      confirmPasswordErrorMsg: "",
    })
  }

  render() {
    let { email, password, passwordErrorMsg, emailErrorMsg, errors, success } =
      this.state
    return (
      <div className="body_container_wrapper login_page">
        <Container>
          <h1>
            <Navbar.Brand href="#home">
              <img
                src={require("../../assets/img/OFFICIAL LOGO TM WITH HEALTHY PLANEAT BLOCK.png")}
                alt="Logo"
              />
            </Navbar.Brand>
          </h1>
          <div className="login_wrapper">
            <Row>
              <Col md="4" className="mx-auto text-center">
                <div>
                  <img src={require("../../assets/img/OFFICIAL LOGO.png")} />
                </div>
                <h6>Forgot Password</h6>

                <div className="error"> {errors} </div>
                <div className="success"> {success} </div>

                <Form.Group>
                  <Form.Control
                    type="email"
                    onChange={this.handleChanged}
                    name="email"
                    value={email}
                    placeholder="Email Address"
                  />
                  {emailErrorMsg ? (
                    <div className="error">{emailErrorMsg}</div>
                  ) : null}
                </Form.Group>

                <Button
                  type="submit"
                  onClick={this.handleForgotSubmit}
                  variant="dark"
                >
                  Submit
                </Button>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    )
  }
}

function mapStateToProps(state) {
  const { props, login, user } = state
  return {
    props,
    login,
    user,
  }
}

export default connect(mapStateToProps)(ForgotPassword)
