import debugFactory from "debug"
import { ResponseFilter } from "../config/response-handler"
import promise from "bluebird"
import { usersConstants } from "../_constants"
import { usersService } from "../_services"

const debug = debugFactory("user-actions")

export const usersActions = {
  // signup,
  forgot,
  reset,
  login,
  getUserInfo,
  getBankInfo,
  logout,
  editProfile,
  changePassword,
  editAddress,
  uploadDocument,
  addAddress,
  getInventoryList,
  getInvList,
  addInventoryList,
  editInventoryItem,
  deleteInventory,
  changeInventoryStatus,
  addAccount,
  accountOauthToken,
}

function login(params) {
  return function (dispatch) {
    return new promise(function (resolve, reject) {
      dispatch(request(params))
      usersService
        .login(params)
        .then((response) => {
          resolve(response)
          let { serverResponseData } = ResponseFilter(response)
          dispatch(success(serverResponseData))
        })
        .catch((err) => {
          reject(err)
          dispatch(failure(err))
        })
    })
  }

  function request(loginRequest) {
    return { type: usersConstants.LOGIN_REQUEST, loginRequest }
  }

  function success(loginSuccess) {
    return { type: usersConstants.LOGIN_SUCCESS, loginSuccess }
  }

  function failure(loginFail) {
    return { type: usersConstants.LOGIN_ERROR, loginFail }
  }
}

function logout(params) {
  return function (dispatch) {
    return new promise(function (resolve, reject) {
      dispatch(request(params))
      usersService
        .logout(params)
        .then((response) => {
          resolve(response)
          let { serverResponseData } = ResponseFilter(response)
          dispatch(success(serverResponseData))
        })
        .catch((err) => {
          reject(err)
          dispatch(failure(err))
        })
    })
  }

  function request(logoutRequest) {
    return { type: usersConstants.LOGOUT_REQUEST, logoutRequest }
  }
  function success(logoutSuccess) {
    return { type: usersConstants.LOGOUT_SUCCESS, logoutSuccess }
  }
  function failure(logoutFail) {
    return { type: usersConstants.LOGOUT_ERROR, logoutFail }
  }
}

function forgot(params) {
  return function (dispatch) {
    return new promise(function (resolve, reject) {
      dispatch(request(params))
      usersService
        .forgot(params)
        .then((response) => {
          resolve(response)
          let { serverResponseData } = ResponseFilter(response)
          dispatch(success(serverResponseData))
        })
        .catch((err) => {
          reject(err)
          dispatch(failure(err))
        })
    })
  }
  function request(forgotRequest) {
    return { type: usersConstants.FORGOT_REQUEST, forgotRequest }
  }
  function success(forgotSuccess) {
    return { type: usersConstants.FORGOT_SUCCESS, forgotSuccess }
  }
  function failure(forgotFail) {
    return { type: usersConstants.FORGOT_ERROR, forgotFail }
  }
}

function reset(params, token) {
  return function (dispatch) {
    return new promise(function (resolve, reject) {
      dispatch(request(params, token))
      usersService
        .reset(params, token)
        .then((response) => {
          resolve(response)
          // let { serverResponseData } = ResponseFilter(response);

          var serverResponseData

          if (response.status) {
            serverResponseData =
              typeof response.data != "undefined"
                ? response.data
                : typeof response.error != "undefined"
                ? response.error
                : null
          }

          dispatch(success(serverResponseData))
        })
        .catch((err) => {
          reject(err)
          dispatch(failure(err))
        })
    })
  }
  function request(resetRequest) {
    return { type: usersConstants.RESET_REQUEST, resetRequest }
  }
  function success(resetSuccess) {
    return { type: usersConstants.RESET_SUCCESS, resetSuccess }
  }
  function failure(resetFail) {
    return { type: usersConstants.RESET_ERROR, resetFail }
  }
}

// function reset(params,token) {
//     return function (dispatch) {
//         return new promise(function (resolve, reject) {
//             dispatch(request(params,token));
//             usersService.reset(params,token)
//                 .then(response => {
//                     resolve(response);
//                     var serverResponseData
//                     if(response.status){
//                         serverResponseData = (typeof response.data != 'undefined')
//                             ? response.data
//                             : ((typeof response.error != 'undefined') ? response.error : null);
//                     }
//                     console.log("wazsexdcrfvtgbyhnjmnhgybfvtrdcesx::::1",response)
//                     console.log("wazsexdcrfvtgbyhnjmnhgybfvtrdcesx::::2",serverResponseData)
//                     dispatch(success(serverResponseData));
//                 })
//                 .catch((err) => {
//                     reject(err);
//                     dispatch(failure(err));
//                 })
//         })
//     };
//     function request(resetRequest) { return { type: usersConstants.RESET_REQUEST, resetRequest } }
//     function success(resetSuccess) { return { type: usersConstants.RESET_SUCCESS, resetSuccess } }
//     function failure(resetFail) { return { type: usersConstants.RESET_ERROR, resetFail } }
// }

function getUserInfo(params) {
  return function (dispatch) {
    return new promise(function (resolve, reject) {
      dispatch(request(params))
      usersService
        .getUserInfo(params)
        .then((response) => {
          let { serverResponseData } = ResponseFilter(response)
          resolve(serverResponseData)
          dispatch(success(serverResponseData))
        })
        .catch((err) => {
          debug(err)
          reject(err)
          dispatch(failure(err))
        })
    })
  }

  function request(getUserInfoRequest) {
    return { type: usersConstants.GET_USER_INFO_REQUEST, getUserInfoRequest }
  }
  function success(getUserInfoSuccess) {
    return { type: usersConstants.GET_USER_INFO_SUCCESS, getUserInfoSuccess }
  }
  function failure(getUserInfoFail) {
    return { type: usersConstants.GET_USER_INFO_ERROR, getUserInfoFail }
  }
}

function getBankInfo(params) {
  return function (dispatch) {
    return new promise(function (resolve, reject) {
      dispatch(request(params))
      debug("get-bank-info-params")
      debug(params)
      usersService
        .getBankInfo(params)
        .then((response) => {
          debug("get-bank-info-response")
          debug(response)
          let { serverResponseData } = ResponseFilter(response)
          debug("get-bank-info-serverResponseData")
          debug(serverResponseData)
          resolve(serverResponseData)
          dispatch(success(serverResponseData))
        })
        .catch((err) => {
          reject(err)
          dispatch(failure(err))
        })
    })
  }

  function request(getBankInfoRequest) {
    return { type: usersConstants.GET_BANK_REQUEST, getBankInfoRequest }
  }
  function success(getBankInfoSuccess) {
    return { type: usersConstants.GET_BANK_SUCCESS, getBankInfoSuccess }
  }
  function failure(getBankInfoFail) {
    return { type: usersConstants.GET_BANK_ERROR, getBankInfoFail }
  }
}

function editProfile(params) {
  return function (dispatch) {
    return new promise(function (resolve, reject) {
      dispatch(request(params))
      usersService
        .editProfile(params)
        .then((response) => {
          let { serverResponseData } = ResponseFilter(response)
          resolve(serverResponseData)
          dispatch(success(serverResponseData))
        })
        .catch((err) => {
          reject(err)
          dispatch(failure(err))
        })
    })
  }

  function request(editProfileRequest) {
    return { type: usersConstants.EDIT_PROFILE_REQUEST, editProfileRequest }
  }
  function success(editProfileSuccess) {
    return { type: usersConstants.EDIT_PROFILE_SUCCESS, editProfileSuccess }
  }
  function failure(editProfileFail) {
    return { type: usersConstants.EDIT_PROFILE_ERROR, editProfileFail }
  }
}
function changePassword(params) {
  return function (dispatch) {
    return new promise(function (resolve, reject) {
      dispatch(request(params))
      usersService
        .changePassword(params)
        .then((response) => {
          let { serverResponseData } = ResponseFilter(response)
          resolve(serverResponseData)
          dispatch(success(serverResponseData))
        })
        .catch((err) => {
          reject(err)
          dispatch(failure(err))
        })
    })
  }
  function request(editPassRequest) {
    return { type: usersConstants.CHANGE_PASS_REQUEST, editPassRequest }
  }
  function success(editPassSuccess) {
    return { type: usersConstants.CHANGE_PASS_SUCCESS, editPassSuccess }
  }
  function failure(editPassFail) {
    return { type: usersConstants.CHANGE_PASS_ERROR, editPassFail }
  }
}
function accountOauthToken(params) {
  return function (dispatch) {
    return new promise(function (resolve, reject) {
      dispatch(request(params))
      usersService
        .accountOauthToken(params)
        .then((response) => {
          let { serverResponseData } = ResponseFilter(response)
          resolve(serverResponseData)
          dispatch(success(serverResponseData))
        })
        .catch((err) => {
          reject(err)
          dispatch(failure(err))
        })
    })
  }
  function request(authRequest) {
    return { type: usersConstants.AUTH_REQUEST, authRequest }
  }
  function success(authSuccess) {
    return { type: usersConstants.AUTH_SUCCESS, authSuccess }
  }
  function failure(authFail) {
    return { type: usersConstants.AUTH_ERROR, authFail }
  }
}
function addAccount(params) {
  return function (dispatch) {
    return new promise(function (resolve, reject) {
      dispatch(request(params))
      usersService
        .addAccount(params)
        .then((response) => {
          let { serverResponseData } = ResponseFilter(response)
          resolve(serverResponseData)
          dispatch(success(serverResponseData))
        })
        .catch((err) => {
          reject(err)
          dispatch(failure(err))
        })
    })
  }
  function request(addAccountRequest) {
    return { type: usersConstants.ADD_ACCOUNT_REQUEST, addAccountRequest }
  }
  function success(addAccountSuccess) {
    return { type: usersConstants.ADD_ACCOUNT_SUCCESS, addAccountSuccess }
  }
  function failure(addAccountFail) {
    return { type: usersConstants.ADD_ACCOUNT_ERROR, addAccountFail }
  }
}

function uploadDocument(params) {
  return function (dispatch) {
    return new promise(function (resolve, reject) {
      dispatch(request(params))
      usersService
        .uploadDocument(params)
        .then((response) => {
          let { serverResponseData } = ResponseFilter(response)
          resolve(serverResponseData)
          dispatch(success(serverResponseData))
        })
        .catch((err) => {
          reject(err)
          dispatch(failure(err))
        })
    })
  }

  function request(uploadDocumentRequest) {
    return { type: usersConstants.UPLOAD_DOC_REQUEST, uploadDocumentRequest }
  }
  function success(uploadDocumentSuccess) {
    return { type: usersConstants.UPLOAD_DOC_SUCCESS, uploadDocumentSuccess }
  }
  function failure(uploadDocumentFail) {
    return { type: usersConstants.UPLOAD_DOC_ERROR, uploadDocumentFail }
  }
}

function addAddress(params) {
  return function (dispatch) {
    return new promise(function (resolve, reject) {
      dispatch(request(params))
      usersService
        .addAddress(params)
        .then((response) => {
          let { serverResponseData } = ResponseFilter(response)
          resolve(serverResponseData)
          dispatch(success(serverResponseData))
        })
        .catch((err) => {
          reject(err)
          dispatch(failure(err))
        })
    })
  }

  function request(addAddressRequest) {
    return { type: usersConstants.ADD_ADDRESS_REQUEST, addAddressRequest }
  }
  function success(addAddressSuccess) {
    return { type: usersConstants.ADD_ADDRESS_SUCCESS, addAddressSuccess }
  }
  function failure(addAddressFail) {
    return { type: usersConstants.ADD_ADDRESS_ERROR, addAddressFail }
  }
}

function editAddress(params) {
  return function (dispatch) {
    return new promise(function (resolve, reject) {
      dispatch(request(params))
      usersService
        .editAddress(params)
        .then((response) => {
          let { serverResponseData } = ResponseFilter(response)
          resolve(serverResponseData)
          dispatch(success(serverResponseData))
        })
        .catch((err) => {
          reject(err)
          dispatch(failure(err))
        })
    })
  }

  function request(editaddressRequest) {
    return { type: usersConstants.EDIT_ADDRESS_REQUEST, editaddressRequest }
  }
  function success(editaddressSuccess) {
    return { type: usersConstants.EDIT_ADDRESS_SUCCESS, editaddressSuccess }
  }
  function failure(editaddressFail) {
    return { type: usersConstants.EDIT_ADDRESS_ERROR, editaddressFail }
  }
}

// function editProfile(params) {

function getInventoryList(params) {
  return function (dispatch) {
    return new promise(function (resolve, reject) {
      dispatch(request(params))
      usersService
        .getInventoryList(params)
        .then((response) => {
          let { serverResponseData } = ResponseFilter(response)
          resolve(serverResponseData)
          dispatch(success(serverResponseData))
        })
        .catch((err) => {
          reject(err)
          dispatch(failure(err))
        })
    })
  }

  function request(getInventoryListRequest) {
    return {
      type: usersConstants.GET_INVENTORY_LIST_REQUEST,
      getInventoryListRequest,
    }
  }
  function success(getInventoryListSuccess) {
    return {
      type: usersConstants.GET_INVENTORY_LIST_SUCCESS,
      getInventoryListSuccess,
    }
  }
  function failure(getInventoryListFail) {
    return {
      type: usersConstants.GET_INVENTORY_LIST_ERROR,
      getInventoryListFail,
    }
  }
}

function getInvList(params) {
  return function (dispatch) {
    return new promise(function (resolve, reject) {
      dispatch(request(params))
      usersService
        .getInvList(params)
        .then((response) => {
          let { serverResponseData } = ResponseFilter(response)
          resolve(serverResponseData)
          dispatch(success(serverResponseData))
        })
        .catch((err) => {
          reject(err)
          dispatch(failure(err))
        })
    })
  }

  function request(getInvListRequest) {
    return { type: usersConstants.GET_INV_LIST_REQUEST, getInvListRequest }
  }
  function success(getInvListSuccess) {
    return { type: usersConstants.GET_INV_LIST_SUCCESS, getInvListSuccess }
  }
  function failure(getInvListFail) {
    return { type: usersConstants.GET_INV_LIST_ERROR, getInvListFail }
  }
}

function addInventoryList(params) {
  return function (dispatch) {
    return new promise(function (resolve, reject) {
      dispatch(request(params))
      usersService
        .addInventoryList(params)
        .then((response) => {
          let { serverResponseData } = ResponseFilter(response)
          resolve(serverResponseData)
          dispatch(success(serverResponseData))
        })
        .catch((err) => {
          reject(err)
          dispatch(failure(err))
        })
    })
  }

  function request(addInventoryListRequest) {
    return {
      type: usersConstants.ADD_INVENTORY_LIST_REQUEST,
      addInventoryListRequest,
    }
  }
  function success(addInventoryListSuccess) {
    return {
      type: usersConstants.ADD_INVENTORY_LIST_SUCCESS,
      addInventoryListSuccess,
    }
  }
  function failure(addInventoryListFail) {
    return {
      type: usersConstants.ADD_INVENTORY_LIST_ERROR,
      addInventoryListFail,
    }
  }
}
function editInventoryItem(params) {
  return function (dispatch) {
    return new promise(function (resolve, reject) {
      dispatch(request(params))
      usersService
        .editInventoryItem(params)
        .then((response) => {
          let { serverResponseData } = ResponseFilter(response)
          resolve(serverResponseData)
          dispatch(success(serverResponseData))
        })
        .catch((err) => {
          reject(err)
          dispatch(failure(err))
        })
    })
  }

  function request(editInventoryItemRequest) {
    return {
      type: usersConstants.EDIT_INVENTORY_ITEM_REQUEST,
      editInventoryItemRequest,
    }
  }
  function success(editInventoryItemSuccess) {
    return {
      type: usersConstants.EDIT_INVENTORY_ITEM_SUCCESS,
      editInventoryItemSuccess,
    }
  }
  function failure(editInventoryItemFail) {
    return {
      type: usersConstants.EDIT_INVENTORY_ITEM_ERROR,
      editInventoryItemFail,
    }
  }
}

function deleteInventory(params) {
  return function (dispatch) {
    return new promise(function (resolve, reject) {
      dispatch(request(params))
      usersService
        .deleteInventory(params)
        .then((response) => {
          let { serverResponseData } = ResponseFilter(response)
          resolve(serverResponseData)
          dispatch(success(serverResponseData))
        })
        .catch((err) => {
          reject(err)
          dispatch(failure(err))
        })
    })
  }

  function request(deleteInventoryRequest) {
    return {
      type: usersConstants.DELETE_INVENTORY_REQUEST,
      deleteInventoryRequest,
    }
  }
  function success(deleteInventorySuccess) {
    return {
      type: usersConstants.DELETE_INVENTORY_SUCCESS,
      deleteInventorySuccess,
    }
  }
  function failure(deleteInventoryFail) {
    return { type: usersConstants.DELETE_INVENTORY_ERROR, deleteInventoryFail }
  }
}

function changeInventoryStatus(params) {
  return function (dispatch) {
    return new promise(function (resolve, reject) {
      dispatch(request(params))
      usersService
        .changeInventoryStatus(params)
        .then((response) => {
          let { serverResponseData } = ResponseFilter(response)
          resolve(serverResponseData)
          dispatch(success(serverResponseData))
        })
        .catch((err) => {
          reject(err)
          dispatch(failure(err))
        })
    })
  }

  function request(changeInventoryRequest) {
    return {
      type: usersConstants.CHANGE_INVENTORY_REQUEST,
      changeInventoryRequest,
    }
  }
  function success(changeInventorySuccess) {
    return {
      type: usersConstants.CHANGE_INVENTORY_SUCCESS,
      changeInventorySuccess,
    }
  }
  function failure(changeInventoryFail) {
    return { type: usersConstants.CHANGE_INVENTORY_ERROR, changeInventoryFail }
  }
}
