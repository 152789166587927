import { usersConstants } from "../_constants"

const initialState = {}

export function mapReducer(state = initialState, action) {
  switch (action.type) {
    case usersConstants.MAP_SUCCESS:
      console.log("kbhjgvhcfgxdzfsxdgfchgjvhbkjn", action)
      let mapReducer = action.data
      return mapReducer

    default:
      return state
  }
}

export function mapFarmReducer(state = initialState, action) {
  switch (action.type) {
    case usersConstants.MAP_FARM_SUCCESS:
      console.log("kbhjgvhcfgxdzfsxdgfchgjvhbkjn", action)
      let mapFarmReducer = action.data
      return mapFarmReducer

    default:
      return state
  }
}

export function mapPlaceReducer(state = initialState, action) {
  switch (action.type) {
    case usersConstants.MAP_PLACE_SUCCESS:
      console.log("kbhjgvhcfgxdzfsxdgfchgjvhbkjn", action)
      let mapPlaceReducer = action.data
      return mapPlaceReducer

    default:
      return state
  }
}

export function mapPickReducer(state = initialState, action) {
  switch (action.type) {
    case usersConstants.MAP_PICK_SUCCESS:
      console.log("kbhjgvhcfgxdzfsxdgfchgjvhbkjn", action)
      let mapPickReducer = action.data
      return mapPickReducer

    default:
      return state
  }
}

export function mapDeliveryReducer(state = initialState, action) {
  switch (action.type) {
    case usersConstants.MAP_DELIVERY_SUCCESS:
      console.log("kbhjgvhcfgxdzfsxdgfchgjvhbkjn", action)
      let mapDeliveryReducer = action.data
      return mapDeliveryReducer

    default:
      return state
  }
}
