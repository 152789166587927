import axios from "axios"
import API from "../config/API"

export const distributionService = {
  addDist,
  editDist,
  deleteDist,
  getDist,
  getDistibutionDetailsById,
  getMarketList,
}

function getAccessToken(i) {
  const headers = {
    Authorization:
      "Basic aGVhbHRoeVBsYW5ldF9hZG1pbl92WnBHaDQ6YWRtaW5AaGVhbHRoeVBsYW5ldF9GVGtWcFE=",
    "Content-Type": "application/json",
    accessToken: localStorage.getItem("accessToken"),
  }
  return headers
}

function getDist(params) {
  return axios.get(`${API.GET_DISTRIBUTION}/${params}`)
}
function getDistibutionDetailsById(params) {
  return axios.get(`${API.GET_DISTRIBUTION_BY_ID}/${params}`)
}

function getMarketList(params) {
  return axios.get(`${API.GET_MARKET_LIST}/${params}`)
}

function addDist(params) {
  return axios.post(API.ADD_DISTRIBUTION, params)
}

function editDist(params) {
  return axios.post(API.EDIT_DISTRIBUTION, params)
}

function deleteDist(params) {
  return axios.post(API.DELETE_DISTRIBUTION, params)
}

function changeDist(params) {
  return axios.post(API.CHANGE_INVENTORY_STATUS, params)
}
