export const orderConstants = {
  GET_ORDER_REQUEST: "GET_ORDER_REQUEST",
  GET_ORDER_SUCCESS: "GET_ORDER_SUCCESS",
  GET_ORDER_ERROR: "GET_ORDER_ERROR",

  GET_ORDER_EXCEL_REQUEST: "GET_ORDER_EXCEL_REQUEST",
  GET_ORDER_EXCEL_SUCCESS: "GET_ORDER_EXCEL_SUCCESS",
  GET_ORDER_EXCEL_ERROR: "GET_ORDER_EXCEL_ERROR",

  GET_ALL_ORDER_EXCEL_REQUEST: "GET_ALL_ORDER_EXCEL_REQUEST",
  GET_ALL_ORDER_EXCEL_SUCCESS: "GET_ALL_ORDER_EXCEL_SUCCESS",
  GET_ALL_ORDER_EXCEL_ERROR: "GET_ALL_ORDER_EXCEL_ERROR",

  VIEW_ORDER_REQUEST: "VIEW_ORDER_REQUEST",
  VIEW_ORDER_SUCCESS: "VIEW_ORDER_SUCCESS",
  VIEW_ORDER_ERROR: "VIEW_ORDER_ERROR",

  CHANGE_HARVEST_REQUEST: "CHANGE_HARVEST_REQUEST",
  CHANGE_HARVEST_SUCCESS: "CHANGE_HARVEST_SUCCESS",
  CHANGE_HARVEST_ERROR: "CHANGE_HARVEST_ERROR",

  CHANGE_HARVEST_BY_FARMER_REQUEST: "CHANGE_HARVEST_BY_FARMER_REQUEST",
  CHANGE_HARVEST_BY_FARMER_SUCCESS: "CHANGE_HARVEST_BY_FARMER_SUCCESS",
  CHANGE_HARVEST_BY_FARMER_ERROR: "CHANGE_HARVEST_BY_FARMER_ERROR",

  CHANGE_ORDER_REQUEST: "CHANGE_ORDER_REQUEST",
  CHANGE_ORDER_SUCCESS: "CHANGE_ORDER_SUCCESS",
  CHANGE_ORDER_ERROR: "CHANGE_ORDER_ERROR",

  CHANGE_ORDER_BY_FARMER_REQUEST: "CHANGE_ORDER_BY_FARMER_REQUEST",
  CHANGE_ORDER_BY_FARMER_SUCCESS: "CHANGE_ORDER_BY_FARMER_SUCCESS",
  CHANGE_ORDER_BY_FARMER_ERROR: "CHANGE_ORDER_BY_FARMER_ERROR",

  CHANGE_ORDER_ITEM_REQUEST: "CHANGE_ORDER_ITEM_REQUEST",
  CHANGE_ORDER_ITEM_SUCCESS: "CHANGE_ORDER_ITEM_SUCCESS",
  CHANGE_ORDER_ITEM_ERROR: "CHANGE_ORDER_ITEM_ERROR",
}

export const ORDER_HARVEST_STATUS = {
  ALL: "all",
  PENDING: 0,
  HARVEST: 1,
  FULFILLED: 2,
}
