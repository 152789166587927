import React from "react"
import { Table } from "react-bootstrap"
import { ORDER_HARVEST_STATUS } from "../_constants/order.constants.js"
import { orderShippedBuyList, orderNonShippedBuyList } from "../util/order"
const debug = require("debug")("harvest-list")

const ORDER_COURIER_TYPE = {
  DELIVERY: "Delivery",
  PICKUP: "Pickup",
}

const distributionKeySeparator = "||"
const distributionKeyParts = (key) => {
  return key.split(distributionKeySeparator)
}

const HarvestListDistribution = (props) => {
  const { farmerOrders, packListHeading } = props

  if (!Array.isArray(farmerOrders)) return null

  const packListEntries = farmerOrders.reduce(
    (totalsAccumulator, farmerOrderEntry) => {
      const orderPackList = farmerOrderEntry.buyListEntry.productList.reduce(
        (orderAccumulator, product) => {
          const item = `${product.productName} ${product.variety}`
          if (isNaN(orderAccumulator[item])) {
            orderAccumulator[item] = +product.productQuantity
          } else {
            orderAccumulator[item] += +product.productQuantity
          }
          return orderAccumulator
        },
        {}
      )

      Object.keys(orderPackList).forEach((packListItemKey) => {
        if (isNaN(totalsAccumulator[packListItemKey])) {
          totalsAccumulator[packListItemKey] = orderPackList[packListItemKey]
        } else {
          totalsAccumulator[packListItemKey] += orderPackList[packListItemKey]
        }
      })
      return totalsAccumulator
    },
    {}
  )

  return (
    <>
      <Table responsive>
        <thead className="harvest-list-thead">
          <h4 className="harvest-list-orders-heading">Orders</h4>
          <tr>
            <th className="">Customer</th>
            <th className="">Items</th>
          </tr>
        </thead>
        <tbody>
          {farmerOrders.map((farmerOrderEntry) => {
            return (
              <tr className="harvest-list-order-row">
                <td className="vertical-align-top hl-th-min-width-standard harvest-list-order-customer">
                  {farmerOrderEntry.customer}
                </td>
                <td className="padding-left-0 padding-right-0 harvest-list-orders-sub-table">
                  <Table className="margin-bottom-base">
                    <thead className="harvest-list-sub-thead">
                      <tr>
                        <th className="padding-top-0 hl-th-min-width-standard">
                          Item
                        </th>
                        <th className="padding-top-0 hl-th-min-width-standard">
                          Variety
                        </th>
                        <th className="padding-top-0 hl-th-min-width-small padding-right-base padding-left-base text-align-center">
                          Quantity
                        </th>
                        <th className="padding-top-0 hl-th-min-width-standard">
                          Unit
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {farmerOrderEntry.buyListEntry.productList.map(
                        (product) => {
                          return (
                            <tr>
                              <td className="vertical-align-top padding-top-base padding-right-base">
                                {product.productName}
                              </td>
                              <td className="vertical-align-top padding-top-base padding-right-base">
                                {product.variety}
                              </td>
                              <td className="vertical-align-top padding-top-base text-align-center padding-right-base padding-left-base">
                                {product.productQuantity}
                              </td>
                              <td className="vertical-align-top padding-top-base padding-right-base">
                                {product.unit}
                              </td>
                            </tr>
                          )
                        }
                      )}
                    </tbody>
                  </Table>
                </td>
              </tr>
            )
          })}
        </tbody>
      </Table>
      <Table
        responsive
        className="hl-table-max-width-large-off harvest-list-pack-list"
      >
        <thead className="harvest-list-pack-list-thead">
          <h4 className="harvest-list-orders-heading">{packListHeading}</h4>
          <tr>
            <th className="">Item</th>
            <th className="hl-th-min-width-small padding-right-base padding-left-base text-align-center">
              Total Count
            </th>
          </tr>
        </thead>
        <tbody>
          {Object.keys(packListEntries).map((packListItemKey) => {
            return (
              <tr>
                <td className="vertical-align-top">{packListItemKey}</td>
                <td className="vertical-align-top text-align-center">
                  {packListEntries[packListItemKey]}
                </td>
              </tr>
            )
          })}
        </tbody>
      </Table>
    </>
  )
}

export const HarvestList = (props) => {
  const { orderInfo, farmUserId } = props

  if (!Array.isArray(orderInfo)) return null

  const distributions = orderInfo.reduce((distributionsAccumulator, order) => {
    // this is a view intendered for an individual farmer, so we modify
    // the buylist to reflect just thir orders, that are in the harveste list
    const farmerBuyList = order.buyList.find((buyList) => {
      return (
        buyList.farmUserId === farmUserId &&
        buyList.harvestStatus === ORDER_HARVEST_STATUS.HARVEST
      )
    })
    // every order should only have one entry that is for the farmer
    // and it should always be in harvest status, the harveststatus filter
    // is happening on the server, but we can double down here
    if (!farmerBuyList) return distributionsAccumulator

    order = {
      ...order,
      buyList: [farmerBuyList],
    }

    const courierType = farmerBuyList.courierType
    let distributionKey = undefined

    const shippedBuyList = orderShippedBuyList({ order, farmUserId })
    const nonShippedBuyList = orderNonShippedBuyList({ order, farmUserId })
    debug("order")
    debug("shippedBuyList")
    debug(shippedBuyList)
    debug("nonShippedBuyList")
    debug(nonShippedBuyList)
    const distributionKeysHarvestListValues = []

    if (shippedBuyList.length === 1) {
      distributionKeysHarvestListValues.push({
        distributionKey: "Shipping",
        harvestListValue: {
          customer: order.customer,
          buyListEntry: shippedBuyList[0],
        },
      })
    }

    if (
      nonShippedBuyList.length === 1 &&
      courierType === ORDER_COURIER_TYPE.DELIVERY
    ) {
      // day of the delivery
      // farmerBuyList.day, same as order.time
      // location of delivery. needed?
      // farmerBuyList.address
      distributionKeysHarvestListValues.push({
        distributionKey: `${courierType}${distributionKeySeparator}${farmerBuyList.day}`,
        harvestListValue: {
          customer: order.customer,
          buyListEntry: nonShippedBuyList[0],
        },
      })
    } else if (
      nonShippedBuyList.length === 1 &&
      courierType === ORDER_COURIER_TYPE.PICKUP
    ) {
      // this includes the day and time
      // farmerBuyList.address
      // the pickup location
      // order.location
      // the pickup time
      // order.time
      distributionKeysHarvestListValues.push({
        distributionKey: `${courierType}${distributionKeySeparator}${order.location}${distributionKeySeparator}${order.time}`,
        harvestListValue: {
          customer: order.customer,
          buyListEntry: nonShippedBuyList[0],
        },
      })
    }

    distributionKeysHarvestListValues.forEach(
      ({ distributionKey, harvestListValue }) => {
        if (Array.isArray(distributionsAccumulator[distributionKey])) {
          distributionsAccumulator[distributionKey].push(harvestListValue)
        } else {
          distributionsAccumulator[distributionKey] = [harvestListValue]
        }
      }
    )

    return distributionsAccumulator
  }, {})

  debug("distributions")
  debug(distributions)

  const allDistributions = Object.keys(distributions).reduce(
    (allDistributionsAccumulator, distributionKey) => {
      allDistributionsAccumulator = allDistributionsAccumulator.concat(
        distributions[distributionKey]
      )
      return allDistributionsAccumulator
    },
    []
  )

  return (
    <>
      {Object.keys(distributions)
        .concat(["Totals for all distributions"])
        .map((distributionKey) => {
          if (allDistributions.length === 0) return null

          // append 'totals' so that it comes last in the list, and set its value to the all distrubtions object
          let farmerOrders = distributions[distributionKey]
          let packListHeading = "Pack List"
          if (!farmerOrders) {
            farmerOrders = allDistributions
            packListHeading = "Total Pack List for All Distributions"
          }

          return (
            <div className="harvest-list harvest-list-block ">
              <hgroup className="margin-bottom-base">
                {distributionKeyParts(distributionKey).map((headingPart) => {
                  return (
                    <h6 className="font-size-base font-weight-700 margin-bottom-0">
                      {headingPart}
                    </h6>
                  )
                })}
              </hgroup>
              <HarvestListDistribution
                farmerOrders={farmerOrders}
                packListHeading={packListHeading}
              />
            </div>
          )
        })}
    </>
  )
}
