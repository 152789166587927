import { usersConstants } from "../_constants"

const initialState = {}

export function inventory(state = initialState, action) {
  switch (action.type) {
    case usersConstants.GET_INVENTORY_LIST_SUCCESS:
      let getInventoryListSuccessInfo = action.getInventoryListSuccess
      return getInventoryListSuccessInfo

    case usersConstants.GET_INV_LIST_SUCCESS:
      let getInvListSuccessInfo = action.getInvListSuccess
      return getInvListSuccessInfo

    case usersConstants.ADD_INVENTORY_LIST_SUCCESS:
      let addInventoryListSuccessInfo = action.addInventoryListSuccess
      return addInventoryListSuccessInfo

    case usersConstants.EDIT_INVENTORY_ITEM_SUCCESS:
      let editInventoryItemSuccessInfo = action.editInventoryItemSuccess
      return editInventoryItemSuccessInfo

    case usersConstants.DELETE_INVENTORY_SUCCESS:
      let deleteInventorySuccessInfo = action.deleteInventorySuccess
      return deleteInventorySuccessInfo

    case usersConstants.CHANGE_INVENTORY_SUCCESS:
      let changeInventorySuccessInfo = action.changeInventorySuccess
      return changeInventorySuccessInfo

    default:
      return state
  }
}
