import { Button, Modal, Tab, Tabs } from "react-bootstrap"
import React, { Component } from "react"

import { Link } from "react-router-dom"
import Pagination from "react-js-pagination"
import Sidebar from "../../components/sidebar"
import _ from "lodash"
import { connect } from "react-redux"
import { distributionActions } from "../../_actions"
import {
  distributionTypeStringForInt,
  distributionClientStringForInt,
} from "../../_constants/distribution.constants"

const days = (type) => {
  const daysOfTheWeek = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ]

  return daysOfTheWeek[type]
}

function ListTime(props) {
  let Jsx = []

  props.data.time.map((value, i) =>
    Jsx.push(
      <span className="cm_time_wrapper">
        {value.starttime}-{value.endTime}&nbsp;&nbsp;
      </span>
    )
  )
  return Jsx
}

class Distribution extends Component {
  constructor(props) {
    super(props)

    this.state = {
      distributionId: "",
      dlt: false,
      errors: "",
      success: "",
      isEdit: false,
      address: "",
      addressLine1: "",
      addressLine2: "",
      state: "",
      postalCode: "",
      city: "",
      lat: 0,
      lng: 0,
      distInfo: [],
      userId: localStorage.getItem("userId"),
      viewPopUp: false,
      distId: "",
      totalCount: "",
      search: "",
      sortField: "",
      sortType: "",
      pageNo: 1,
      count: 10,
      dataCount: 0,
      category: "",
      notes: "",
    }
  }

  componentDidMount() {
    this.getDistibutionDetails()
  }

  openViewPopup = (item) => {
    this.setState({
      viewPopUp: true,

      distId: item._id,
    })
  }
  handleClose = (item) => {
    this.setState({
      viewPopUp: false,
      distId: "",
      dlt: false,
    })
  }

  DeleteItem = (e, item) => {
    this.setState({ dlt: true, distributionId: item._id })
  }
  handleDelete = (e, item) => {
    let { userId, distributionId } = this.state
    this.setState({ errors: "" })

    let self = this

    this.setState({ redirect: false, show: false })

    let obj = {
      distributionId: distributionId,
      userId: userId,
    }
    let { dispatch } = this.props
    dispatch(distributionActions.deleteDistibutionDetails(obj)).then((data) => {
      self.setState({ loader: false })
      if (data.statusCode === 1) {
        self.setState({ dlt: false })
        self.getDistibutionDetails()
      } else {
        self.setState({ errors: data.error.responseMessage })
      }
    })
  }

  getDistibutionDetails = () => {
    let { userId, category, pageNo, count, sortField, sortType } = this.state
    this.setState({ errors: "" })

    let self = this
    this.setState({ loader: true })

    let params = `?userId=${userId}&farmerId=${userId}&pageNo=${
      pageNo - 1
    }&count=${count}&sortField=${sortField ? sortField : ""}&sortType=${
      sortType ? sortType : ""
    }&category=${category ? category : ""}`

    let { dispatch } = this.props
    dispatch(distributionActions.getDistibutionDetails(params)).then((data) => {
      self.setState({ loader: false })
      if (!_.isEmpty(data)) {
        if (data.statusCode === 1) {
          let distInfo = data.responseData.result

          self.setState({
            distInfo: distInfo.distributionList,
            dataCount: distInfo.totalDistributionResult,
            totalCount: distInfo.totalAllDistribution,
          })
        } else {
          self.setState({ errors: data.error.responseMessage })
        }
      }
    })
  }

  handlePageChange = (pageNo) => {
    var self = this
    this.setState({ loader: true, pageNo: pageNo, count: 10 }, () => {
      self.getDistibutionDetails()
    })
  }

  handleFilter = (e) => {
    this.setState({ category: e, pageNo: 1 }, () =>
      this.getDistibutionDetails()
    )
  }

  render() {
    let { distInfo, dlt, count, pageNo } = this.state

    return (
      <>
        <Sidebar />
        <div>
          <div className="header_wrapper">
            <div className="header_logo">
              <img src={require("../../assets/img/OFFICIAL LOGO.png")} alt="" />
            </div>
            <div className="header_search">
              <div className="form-group has-search"></div>
            </div>
            <div className="bell_icon">
              <i className="fas fa-bell"></i>
            </div>
          </div>

          <div className="body_container">
            <div className="body_inner_wrapper">
              {this.state.loader ? (
                <div className="loader_wrapper">
                  <div className="spinner-border text-info" role="status"></div>
                </div>
              ) : (
                <>
                  <header className="header_content">
                    <p>Distribution</p>
                    <div className="d-flex text-nowrap header_btn">
                      <Link to="/distribution/add" className="text-light">
                        <Button variant="warning">
                          <i class="fas fa-plus-circle"></i>
                          Add Distribution
                        </Button>
                      </Link>
                    </div>
                  </header>

                  <div className="main_content">
                    <Tabs
                      defaultActiveKey="1"
                      activeKey={1}
                      id="uncontrolled-tab-example"
                      className="border-0"
                    >
                      <Tab eventKey="1">
                        <div className="cm-inventory_gridview ">
                          <div className="table-responsive overflow_remove">
                            <table className="table cm_bottom_space">
                              <thead>
                                <tr className="broder_top_0">
                                  <th scope="col" className="s_no ws-nowrap">
                                    S. No.
                                  </th>
                                  <th scope="col" className="ws-nowrap">
                                    Orders Open
                                  </th>
                                  <th scope="col" className="ws-nowrap">
                                    Orders Close
                                  </th>
                                  <th scope="col" className="ws-nowrap">
                                    Type
                                  </th>
                                  <th scope="col" className="ws-nowrap">
                                    Pick Up / Delivery Day
                                  </th>
                                  <th scope="col" className="ws-nowrap">
                                    {" "}
                                    Pick Up Location
                                  </th>
                                  <th scope="col" width="300">
                                    {" "}
                                    Delivery For
                                  </th>

                                  <th scope="col">Action</th>
                                </tr>
                              </thead>
                              <tbody>
                                {!_.isEmpty(distInfo) &&
                                  distInfo.map((item, i) => (
                                    <tr key={item._id}>
                                      <td className="s_no">
                                        {count * (pageNo - 1) + (i + 1)}
                                      </td>

                                      {item.placeOrderOpen ? (
                                        <td>
                                          {days(item.placeOrderOpen.day)}{" "}
                                          {item.placeOrderOpen.time}{" "}
                                          {item.placeOrderOpen.meridiem}
                                        </td>
                                      ) : (
                                        ""
                                      )}

                                      {item.placeOrderClose ? (
                                        <td width="140">
                                          {days(item.placeOrderClose.day)}{" "}
                                          {item.placeOrderClose.time}{" "}
                                          {item.placeOrderClose.meridiem}
                                        </td>
                                      ) : (
                                        ""
                                      )}

                                      {item.selectPickDel ? (
                                        <td width="140">
                                          {" "}
                                          {distributionTypeStringForInt({
                                            distributionType:
                                              item.selectPickDel,
                                          })}
                                        </td>
                                      ) : (
                                        ""
                                      )}

                                      {item && item.selectPickDel === 2 ? (
                                        <td>
                                          {item.delivery.map((value, i) =>
                                            value ? (
                                              <span className="px-1 d-block">
                                                {days(value.day)}{" "}
                                                {value.time ? (
                                                  <i>
                                                    {" "}
                                                    {<ListTime data={value} />}
                                                  </i>
                                                ) : (
                                                  ""
                                                )}{" "}
                                              </span>
                                            ) : (
                                              ""
                                            )
                                          )}
                                        </td>
                                      ) : (
                                        <td width="140">
                                          {" "}
                                          {item
                                            ? item.pickup.map((value, i) =>
                                                value ? (
                                                  <span className="px-1 d-block">
                                                    {days(value.day)}{" "}
                                                    {value.time ? (
                                                      <i>
                                                        {
                                                          <ListTime
                                                            data={value}
                                                          />
                                                        }
                                                      </i>
                                                    ) : (
                                                      ""
                                                    )}
                                                  </span>
                                                ) : (
                                                  ""
                                                )
                                              )
                                            : ""}
                                        </td>
                                      )}

                                      <td>
                                        {item
                                          ? item.distributionLocation.address
                                          : ""}
                                      </td>
                                      <td>
                                        {distributionClientStringForInt({
                                          deliveryFor: item.deliveryFor,
                                        })}
                                      </td>

                                      <td width="60" align="center">
                                        <div className="action_wrapper">
                                          <Link
                                            to={`distribution/edit/${item._id}`}
                                            className="font_14"
                                          >
                                            <i
                                              style={{ color: "blue" }}
                                              className="fas fa-pen"
                                            ></i>
                                          </Link>
                                          <span className="font_14">
                                            <i
                                              style={{ color: "red" }}
                                              onClick={(e) =>
                                                this.DeleteItem(e, item)
                                              }
                                              className="fas fa-trash-alt"
                                            ></i>
                                          </span>
                                        </div>
                                      </td>
                                    </tr>
                                  ))}
                              </tbody>
                            </table>
                            {distInfo && distInfo.length ? (
                              <div className="d-flex justify-content-end cm-pagination">
                                <Pagination
                                  itemClassPrev={"prevClass"}
                                  itemClassNext={"nextClass"}
                                  activePage={this.state.pageNo}
                                  itemsCountPerPage={this.state.count}
                                  totalItemsCount={this.state.totalCount}
                                  pageRangeDisplayed={3}
                                  onChange={this.handlePageChange}
                                />
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      </Tab>
                    </Tabs>

                    {_.isEmpty(distInfo) ? (
                      <h3 className="text-center">No data found</h3>
                    ) : (
                      ""
                    )}

                    <Modal
                      show={dlt}
                      onHide={this.handleClose}
                      className="cm_order_view dlt_item"
                    >
                      <Modal.Header closeButton>
                        <Modal.Title></Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <img
                          src={require("../../assets/img/Delete-Email.png")}
                          alt=""
                        />
                        <br />
                        <br />
                        <p>
                          Are you sure you want to delete this distribution?
                        </p>
                      </Modal.Body>
                      <Modal.Footer>
                        <div className="footer_btn_wrapper">
                          <Button
                            variant="warning"
                            onClick={(e) => this.handleDelete()}
                          >
                            Delete
                          </Button>
                          <Button variant="dark" onClick={this.handleClose}>
                            Cancel
                          </Button>
                        </div>
                      </Modal.Footer>
                    </Modal>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </>
    )
  }
}

function mapStateToProps(state) {
  const { props, login, distribution, user } = state

  return {
    props,
    user,
    distribution,
  }
}

export default connect(mapStateToProps)(Distribution)
