import debugFactory from "debug"
import React, { Component } from "react"
import { Button, Col, Container, Form, Modal, Row } from "react-bootstrap"

import { connect } from "react-redux"
import _ from "lodash"

import { farmActions, usersActions } from "../../_actions"
import siteSetting from "../../config/env"
import { getStripeConnectRedirect } from "../../util/stripe"
import { stripeAccountIdFromUserProfile } from "../../util/user"

// import {notify} from '../../components/common/Tooster'

const debug = debugFactory("add-account")

class AddAccount extends Component {
  constructor(props) {
    super(props)
    this.state = {
      redirectToHome: false,
      isLoader: false,
      show: false,
      email: "",
      firstName: "",
      lastName: "",
      address1: "",
      address2: "",
      zip: "",
      city: "",
      phone: "",
      country: "",
      state: "",
      userId: localStorage.getItem("userId"),
      errors: "",
      cardHolderErrorMsg: "",
      addcartOption: false,
      cardId: "",
      cardInfo: [],
      subscribtionInfo: [],
      addcarderrmsg: "",
      cartListDetails: [],
      priceDetails: "",
      accountNumber: "",
      fullName: "",
      routingNumber: "",
      fullNameErrorMsg: "",
      accountNumberErrorMsg: "",
      routingNumberErrorMsg: "",
      isAdd: false,
      accountInfo: [],
      accountId: "",
      name: "",
      farmInfo: [],
    }
  }

  onHandleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
      accountNumberErrorMsg: "",
    })
  }

  componentDidMount() {
    // this.getUserCustomerInfo();
    this.getUserInfo()
  }

  redirectTo() {
    const stripeRedirect = getStripeConnectRedirect()
    debug(stripeRedirect)
    window.location.href = stripeRedirect
  }

  // getUserCustomerInfo=()=>{
  //     let self = this;
  //     self.setState({isLoader:true });
  //
  //     _Api(Url.GET_USER_STRIPE_CUSTOMER_INFO.method, Url.GET_USER_STRIPE_CUSTOMER_INFO.url)
  //         .then(response=>{
  //             if(response.statusCode == 1) {
  //                 let cardId = '';
  //                 if(response.responseData.result.invoice_settings.default_payment_method) {
  //                     cardId = response.responseData.result.invoice_settings.default_payment_method;
  //                 } else {
  //                     cardId = response.responseData.result.default_source;
  //                 }
  //
  //                 let cardInfo = response.responseData.result.sources.data;
  //                 let subscribtionInfo = response.responseData.result.subscriptions.data;
  //                 self.setState({cardId: cardId, cardInfo: cardInfo, subscribtionInfo: subscribtionInfo, isLoader:false });
  //             }
  //         })
  // }

  handleChckoutClose = () => {
    this.setState({ redirectToHome: true })
  }

  handleChanged = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
      errors: [],
      responseError: "",
    })
  }

  addCardforPayment(e) {
    this.setState({ addcartOption: true })
  }

  selectCard(item, e) {
    let self = this
    self.setState({ cardId: item.id })
  }

  handleChange = ({ error }) => {
    if (error) {
      this.setState({
        errorMessage: error.message,
        errors: "",
        fullNameErrorMsg: "",
        accountNumberErrorMsg: "",
        routingNumberErrorMsg: "",
      })
    }
  }

  handleNameSave = (e) => {
    let self = this
    this.setState(
      {
        [e.target.name]: e.target.value,
        [e.target.name + "ErrorMsg"]: "",
        errorMessage: "",
      },
      () => this.setState({ errors: "" })
    )
  }

  getUserInfo = () => {
    this.setState({ errors: "" })

    let self = this
    this.setState({ redirect: false, loader: true })

    let { dispatch } = this.props
    dispatch(usersActions.getUserInfo()).then((data) => {
      this.setState({ loader: false })
      // let getData = this.props.user;

      if (data.statusCode == 1) {
        let userInfo = data.responseData.userProfile
        localStorage.setItem("userId", userInfo._id)

        const accountId = stripeAccountIdFromUserProfile({
          userProfile: userInfo,
        })

        self.setState(
          {
            userInfo: userInfo,

            fullName: userInfo.firstName + " " + userInfo.lastName,
            lastName: userInfo.lastName,
            phone: userInfo.phone,
            accountId,
            email: userInfo.email,
            address: userInfo.address,
            addressLine1:
              userInfo.address.length > 0
                ? userInfo.address[0].addressLine1
                  ? userInfo.address[0].addressLine1
                  : ""
                : "",
            addressLine2:
              userInfo.address.length > 0
                ? userInfo.address[0].addressLine2
                  ? userInfo.address[0].addressLine2
                  : ""
                : "",
            state:
              userInfo.address.length > 0
                ? userInfo.address[0].state
                  ? userInfo.address[0].state
                  : ""
                : "",
            postalCode:
              userInfo.address.length > 0
                ? userInfo.address[0].postalCode
                  ? userInfo.address[0].postalCode
                  : ""
                : "",
            city:
              userInfo.address.length > 0
                ? userInfo.address[0].city
                  ? userInfo.address[0].city
                  : ""
                : "",
            addressId:
              userInfo.address.length > 0
                ? userInfo.address[0]._id
                  ? userInfo.address[0]._id
                  : ""
                : "",
            minimumOrderValue: userInfo.minimumOrderValue,
            minimumWholeSaleOrderValue: userInfo.minimumWholeSaleOrderValue,
            profilePic: userInfo.profileImage,
            profilePicPreview: userInfo.profileImage,
            certificate: userInfo.certificateImage,
            certificatePreview: userInfo.certificateImage,
            certificatePreview1: userInfo.certificateImage,
            insuranceDoc: userInfo.insuranceImage,
            insurancePreview: userInfo.insuranceImage,
            radius: userInfo.radius,
            emailPopup: userInfo.notificationEmail,
            messagePopup: userInfo.notificationTextMassage,
            alertPopup: userInfo.notificationAppAlert,
            lat: _.isEmpty(userInfo.geoLocation) ? userInfo.geoLocation[0] : 0,
            long: _.isEmpty(userInfo.geoLocation) ? userInfo.geoLocation[1] : 0,
          },
          () => {
            this.getBankInfo()
          }
        )
      } else {
        this.setState({ errors: data.error.responseMessage })
      }
    })
  }

  getBankInfo = () => {
    this.setState({ errors: "" })

    let self = this
    this.setState({ redirect: false, loader: true })
    let { accountId } = this.state

    let params = `?accountId=${accountId}`

    let { dispatch } = this.props
    dispatch(usersActions.getBankInfo(params))
      .then((data) => {
        self.setState({ loader: false })
        if (data.statusCode == 1) {
          let userInfo = data.responseData.result
          debug("get-bank-info-userInfo")
          debug(userInfo)
          self.setState({
            accountInfo: userInfo.sources?.data,
            routingNumber: userInfo.sources?.data[0].routing_number,
            status: userInfo.sources?.data[0].status,
          })
        } else {
          this.setState({ errors: data.error.responseMessage })
        }
      })
      .catch((error) => {
        debug("get-bank-info-error")
        debug(error)
      })
  }

  handleSubmit = (e) => {
    e.preventDefault()

    let { fullName, accountNumber, routingNumber, userId } = this.state

    this.setState({
      fullName,
      accountNumber,
      routingNumber,
      addcarderrmsg: "",
      cardHolderErrorMsg: "",
    })
    let self = this

    if (fullName === "" || fullName === undefined) {
      this.setState({ fullNameErrorMsg: "Please enter full name." })
    } else if (accountNumber === "" || accountNumber === undefined) {
      this.setState({ accountNumberErrorMsg: "Please enter account number." })
    } else if (routingNumber === "" || routingNumber === undefined) {
      this.setState({ routingNumberErrorMsg: "Please enter routing number." })
    } else {
      let obj = {
        fullName: fullName,
        accountNumber: accountNumber,
        routingNumber: routingNumber,
        userId: userId,
      }
      let { dispatch } = this.props

      dispatch(usersActions.addAccount(obj)).then((data) => {
        self.setState({ loader: false })
        if (data.statusCode == 1) {
          self.setState({ isAdd: false })
          self.getUserInfo()
          self.getBankInfo()
        } else {
          self.setState({ errors: data.error.errors.raw.message })
        }
      })
    }
  }

  handleValidate = () => {
    let validate = false
    let errors = []

    let {
      firstName,
      lastName,
      address1,
      address2,
      zip,
      city,
      phone,
      country,
      state,
    } = this.state
    this.setState({ errors: "" })

    if (_.isEmpty(firstName)) {
      validate = true
      errors["firstName"] = "First name is required."
    }
    if (_.isEmpty(lastName)) {
      validate = true
      errors["lastName"] = "Last name is required."
    }
    if (_.isEmpty(zip)) {
      validate = true
      errors["zip"] = "Zip is required."
    }
    if (_.isEmpty(city)) {
      validate = true
      errors["city"] = "City is required."
    }
    if (_.isEmpty(phone)) {
      validate = true
      errors["phone"] = "Phone is required."
    }
    if (_.isEmpty(country)) {
      validate = true
      errors["country"] = "Country is required."
    }
    if (_.isEmpty(state)) {
      validate = true
      errors["state"] = "State is required."
    }
    this.setState({ errors })
    return validate
  }

  continue2ndProcess = () => {
    let {
      firstName,
      lastName,
      address1,
      address2,
      zip,
      city,
      phone,
      country,
      state,
    } = this.state
    if (!this.handleValidate()) {
      console.log("Pass")
    }
  }

  handleCheckout = () => {
    let {
      cardId,
      firstName,
      lastName,
      address1,
      address2,
      zip,
      city,
      phone,
      country,
      state,
    } = this.state
    if (!this.handleValidate()) {
      let req = {
        stripeCardId: cardId,
        firstName: firstName,
        lastName: lastName,
        addressLine1: address1,
        addressLine2: address2,
        city: city,
        postalCode: zip,
        phoneNumber: phone,
        country: country,
        state: state,
      }

      this.setState({ isLoader: true })
      // _Api(Url.USER_PAYMENT.method,Url.USER_PAYMENT.url,req)
      //     .then(resp=>{
      this.setState({ isLoader: false, show: true })
      //         console.log('payment info:',resp)
      //     }).catch(err=>{
      //     this.setState({isLoader:false})
      //     notify('error', err.error.responseMessage)
      // })
    }
  }

  render() {
    let {
      accountInfo,
      email,
      accountId,
      fullNameErrorMsg,
      accountNumberErrorMsg,
      routingNumberErrorMsg,
      name,
      isAdd,
      errors,
      cardId,
      cardInfo,
      addcartOption,
      addcarderrmsg,
      cardHolderErrorMsg,
      priceDetails,
      accountNumber,
      fullName,
      routingNumber,
    } = this.state

    return (
      <div className="body_container_wrapper ordersummary">
        {/*{_.isEmpty(accountInfo) && accountInfo?*/}
        <Container>
          <div className="">
            {errors ? (
              <div className="error">
                {errors === "No customer is available" ? "" : errors}
              </div>
            ) : null}

            {/*<Col md="6">*/}
            <div className="">
              <div className="payment-items">
                <h6> Account</h6>

                <br />
                <br />
                <br />
                <br />
                <div className="">
                  <form onSubmit={this.handleSubmit}>
                    {accountId ? (
                      <Row>
                        <Col lg="2" md="3">
                          <p>Account Id:</p>
                        </Col>
                        <Col lg="6" md="9">
                          <div className="form-control">{accountId}</div>
                        </Col>
                      </Row>
                    ) : (
                      ""
                    )}
                    {fullName ? (
                      <Row>
                        <Col lg="2" md="3">
                          <p>Full Name:</p>
                        </Col>
                        <Col lg="6" md="9">
                          <div className="form-control">{fullName}</div>
                        </Col>
                      </Row>
                    ) : (
                      ""
                    )}

                    {email ? (
                      <Row>
                        <Col lg="2" md="3">
                          <p>Email:</p>
                        </Col>
                        <Col lg="6" md="9">
                          <div className="form-control">{email}</div>
                        </Col>
                      </Row>
                    ) : (
                      ""
                    )}
                    {/*<Button*/}
                    {/*    // type="submit"*/}
                    {/*    onClick={this.redirectTo}*/}
                    {/*    // redirectTo*/}
                    {/*    className="text-light mr-2"*/}
                    {/*    variant="warning">Add Account</Button>*/}
                    {/*<div className="text-left">*/}
                    {/*    <Link type="submit" className="btn btn-primary btn-blue card_save">Save</Link>*/}
                    {/*</div>*/}
                  </form>
                </div>
              </div>
            </div>
            {/*</Col>/*/}
          </div>
        </Container>
        {/*:*/}
        {/*<Container>*/}
        {/*        <div className="">*/}

        {/*            /!*<Col md="6">*!/*/}
        {/*            <div className="">*/}

        {/*                <div className="payment-items">*/}
        {/*                    <h6> Account Details</h6>*/}
        {/*                    <br/>*/}
        {/*                    <br/>*/}

        {/*                    <div className="">*/}

        {/*                        /!*{!_.isEmpty(cardInfo) && !addcartOption?*!/*/}
        {/*                        /!*    <div className="addcard" onClick={this.addCardforPayment.bind(this)}> Add card for payment </div>*!/*/}
        {/*                        /!*:null}*!/*/}

        {/*                        {_.isEmpty(cardInfo) || addcartOption ?*/}
        {/*                            <>*/}
        {/*                                <form className="">*/}
        {/*                                    <Row>*/}
        {/*                                        <Col md="2">*/}
        {/*                                            <p>Account Number:</p>*/}
        {/*                                        </Col>*/}
        {/*                                        <Col md="5">*/}
        {/*                                            {accountNumber ? <span*/}
        {/*                                                className="form-control">************{accountNumber}</span> : ''}*/}

        {/*                                        </Col>*/}
        {/*                                    </Row>*/}
        {/*                                    <Row>*/}
        {/*                                        <Col md="2">*/}
        {/*                                            <p>Full Name:</p>*/}
        {/*                                        </Col>*/}
        {/*                                        <Col md="5">*/}
        {/*                                            {fullName ?*/}
        {/*                                                <span className="form-control">{fullName}</span> : ''}*/}

        {/*                                        </Col>*/}
        {/*                                    </Row>*/}

        {/*                                    <Row>*/}
        {/*                                        <Col md="2">*/}
        {/*                                            <p>Routing Number:</p>*/}
        {/*                                        </Col>*/}
        {/*                                        <Col md="5">*/}
        {/*                                            {routingNumber ? <span*/}
        {/*                                                className="form-control">{routingNumber}</span> : ''}*/}

        {/*                                        </Col>*/}
        {/*                                    </Row>*/}

        {/*                                </form>*/}
        {/*                            </>*/}
        {/*                            : null}*/}

        {/*                    </div>*/}
        {/*                </div>*/}
        {/*            </div>*/}
        {/*            /!*</Col>/*!/*/}

        {/*        </div>*/}
        {/*    </Container>*/}
        {/*}*/}

        <div className="confirmation_popup">
          <Modal
            show={this.state.show}
            onHide={this.handleChckoutClose}
            className="cm_confirmation"
          >
            <Modal.Header closeButton>
              <Modal.Title>
                <img src={require("../../assets/img/docpng.png")} />
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {/* <h6>Order Placed Successfully!</h6> */}
              Order Placed Successfully!
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={this.handleChckoutClose}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
  const { props, user } = state
  return {
    props,
    user,
  }
}

export default connect(mapStateToProps)(AddAccount)
