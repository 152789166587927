import * as jsPDF from "jspdf"

import {
  Button,
  Card,
  CardDeck,
  Col,
  Form,
  Modal,
  Row,
  Tab,
  Tabs,
} from "react-bootstrap"
import React, { Component } from "react"

import { Link } from "react-router-dom"
import Sidebar from "../../components/sidebar"
import { InventoryItemForm, InventoryItemCard } from "healthyplaneat-common"
import _ from "lodash"
import { connect } from "react-redux"
import { usersActions } from "../../_actions"

import {
  validateStripeSubscriptionInterval,
  isSubscriptionItem,
} from "../../util/inventory"

import {
  INVENTORY_DISTRIBUTION_TYPE_OPTIONS,
  inventoryDistributionTypeForOption,
  inventoryDistributionTypeOptionForValue,
} from "../../_constants/inventory.constants"
const debug = require("debug")("inventory")

const Units = (type) => {
  switch (type) {
    // case 1:
    //     return 'wreath';
    //     break;
    // case 2:
    //     return 'bouquet';
    //     break;
    case 3:
      return "bunch"
      break
    // case 4:
    //     return 'lb';
    //     break;
    case 5:
      return "pint"
      break
    case 6:
      return "head"
      break

    case 7:
      return "bag "
      break
    case 8:
      return "half pint"
      break
    // case 9:
    //     return '1/2 lb bag'
    //     break;
    case 10:
      return "Pound"
      break

    case 11:
      return "Half Pound Bag"
      break

    case 12:
      return "Half Pound"
      break
    case 13:
      return "others"
      break

    default:
      return ""
      break
  }
}

class Inventory extends Component {
  constructor(props) {
    super(props)

    this.state = {
      custPrice: "",
      custUnit: "",
      invType: "",
      invName: "",
      invOtherNameErrorMsg: "",
      wholeselUnit: "",
      wholeselPrice: "",
      descript: "",
      otherWholeSalUnit: "",
      errors: "",
      success: "",
      isEdit: true,
      show: false,
      dlt: false,
      edit: false,
      viewInventory: false,
      inventoryInfo: [],
      search: "",
      pageNo: 1,
      count: 1000,
      view: 1,
      inventoryId: "",
      image: "",
      imagePreview: "",
      fileImage: "",
      fileImagePreview: "",
      imageErrorMsg: "",
      fileImageErrorMsg: "",
      itemName: "",
      otherWholeselUnitErrorMsg: "",
      inventoryType: 1,
      inventoryTypeEdit: 1,
      otherCustUnitErrorMsg: "",
      otherItemName: "",
      otherEditItemName: "",
      consumerUnit: "",
      otherConsumerUnit: "",
      consumerPrice: 0,
      maximumConsumerQuantity: "",
      wholeselerUnit: "",
      otherWholeSalerUnit: "",
      wholeselerPrice: "",
      maximumWholeselerQuantity: "",
      description: "",
      status: "",
      userId: localStorage.getItem("userId"),
      custUnitErrorMsg: "",
      custPriceErrorMsg: "",
      invTypeErrorMsg: "",
      invNameErrorMsg: "",
      maxConsumerQuantityErrorMsg: "",
      wholeselUnitErrorMsg: "",
      wholeselPriceErrorMsg: "",
      maxWholeselerQuantityErrorMsg: "",
      descriptErrorMsg: "",
      loader: false,
      organic: 1,
      pledge: 1,
      editOrganic: 1,
      editPledge: 1,
      variety: "",
      editVariety: "",
      organicEditErrorMsg: "",
      varietyErrorMsg: "",
      itemList: [],
      invId: "",
      itemId: "",
      accountId: "",
      isSubscription: false,
      subscriptionInterval: "",
      subscriptionIntervalCount: -1,
      subscriptionIntervalErrorMsg: "",
      subscriptionIntervalCountErrorMsg: "",
      editItemInitialValue: {},
      distributionTypeOption:
        INVENTORY_DISTRIBUTION_TYPE_OPTIONS.PICKUP_DELIVERY,
    }
  }

  componentDidMount() {
    this.getInventoryList()
    this.getInvList()
  }

  handleAddSubmit = ({ inventoryItemForm }) => {
    this.setState({ redirect: false, show: false, loader: true })
    let { dispatch } = this.props
    debug("add-submit")
    dispatch(usersActions.addInventoryList(inventoryItemForm)).then((data) => {
      this.setState({ loader: false, show: false })
      if (data.statusCode == 1) {
        this.setState({
          errors: "",
        })
        this.getInventoryList()
      } else {
        this.setState({ show: true, errors: data.error.responseMessage })
      }
    })
  }

  handleEditSubmit = ({ inventoryItemForm }) => {
    const debugSubmit = debug.extend("edit-submit")
    let { inventoryTypeEdit } = this.state
    let { dispatch } = this.props
    this.setState({ loader: true, edit: false })
    dispatch(usersActions.editInventoryItem(inventoryItemForm)).then((data) => {
      this.setState({ loader: false })
      if (data.statusCode == 1) {
        this.setState({ edit: false, inventoryType: inventoryTypeEdit })
        this.getInventoryList()
      } else {
        this.setState({ edit: true, errors: data.error.responseMessage })
      }
    })
  }

  getInventoryList = () => {
    this.setState({ errors: "" })

    let self = this
    this.setState({ loader: true })

    let {
      search,
      pageNo,
      count,
      sortField,
      sortType,
      inventoryType,
      userId,
      status,
    } = this.state
    let params = `?pageNo=${
      pageNo - 1
    }&search=${search}&count=${count}&sortField=${sortField}&sortType=${sortType}&filterInv=${inventoryType}&status=${status}&userId=${userId}`

    let { dispatch } = this.props
    dispatch(usersActions.getInventoryList(params)).then((data) => {
      let inventoryData = this.props.inventory

      self.setState({ loader: false })

      if (inventoryData.statusCode == 1) {
        let inventoryInfo = inventoryData.responseData.result.invetoryList
        inventoryInfo.sort((a, b) => {
          const aItemName =
            a.itemName === "others" ? a.otherItemName : a.itemName
          const bItemName =
            b.itemName === "others" ? b.otherItemName : b.itemName
          if (aItemName < bItemName) {
            return -1
          }
          if (aItemName > bItemName) {
            return 1
          }
          return 0
        })
        this.setState({
          inventoryInfo: inventoryInfo,
          accountId: inventoryData.responseData.result.accountId,
        })
      } else {
        this.setState({ errors: inventoryData.error.responseMessage })
      }
    })
  }

  getInvList = () => {
    this.setState({ errors: "" })

    let self = this
    this.setState({ loader: true })

    let {
      search,
      pageNo,
      count,
      sortField,
      sortType,
      inventoryType,
      userId,
      status,
    } = this.state
    let params = `?userId=${userId}&count=${500}`

    let { dispatch } = this.props
    dispatch(usersActions.getInvList(params)).then((data) => {
      if (data.statusCode == 1) {
        let itemList = data.responseData.result.itemList
        this.setState({
          itemList: itemList,
        })
      } else {
        this.setState({ errors: data.error.responseMessage })
      }
    })
  }

  handleClose = () => {
    this.setState({
      show: false,
      viewInventory: false,
      imageErrorMsg: "",
      custPriceErrorMsg: "",
      custUnitErrorMsg: "",
      invTypeErrorMsg: "",
      varietyErrorMsg: "",
      invNameErrorMsg: "",
      organicEditErrorMsg: "",
      maxConsumerQuantityErrorMsg: "",
      wholeselPriceErrorMsg: "",
      maxWholeselerQuantityErrorMsg: "",
      wholeselUnitErrorMsg: "",
      descriptErrorMsg: "",
      // image: '',
      invId: "",
      errors: "",
      invName: "",
      custUnit: "",
      imagePreview: "",
      custPrice: "",
      maximumConsumerQuantity: "",
      maximumWholeselerQuantity: "",
      wholeselPrice: "",
      wholeselUnit: "",
      descript: "",
      variety: "",
    })
  }
  handleShow = () => {
    this.setState({ show: true })
  }

  openViewPopup = (e, item) => {
    this.setState({
      viewInventory: true,
      inventoryItem: item,
    })
  }
  CloseDeleteScreen = () => {
    this.setState({ dlt: false })
  }
  DeleteItem = (e, item) => {
    this.setState({ dlt: true, inventoryId: item._id })
  }
  CloseEdit = () => {
    this.setState({ edit: false })
  }
  EditItem = (e, item) => {
    this.setState({
      edit: true,
      inventoryItem: item,
    })
  }

  inventoryType = (e) => {
    if (e == "1") {
      // Vegetables
      this.setState({ inventoryType: 1 }, () => this.getInventoryList())
    } else if (e == "2") {
      // Seedlings
      this.setState({ inventoryType: 2 }, () => this.getInventoryList())
    } else if (e == "3") {
      // CSA Shares
      this.setState({ inventoryType: 3 }, () => this.getInventoryList())
    } else if (e == "4") {
      // Subscription Boxes
      this.setState({ inventoryType: 4 }, () => this.getInventoryList())
    } else if (e == "5") {
      // Others
      this.setState({ inventoryType: 5 }, () => this.getInventoryList())
    } else if (e == "6") {
      // Fruits
      this.setState({ inventoryType: 6 }, () => this.getInventoryList())
    } else if (e == "7") {
      // Meat and Eggs
      //
      this.setState({ inventoryType: 7 }, () => this.getInventoryList())
    } else if (e == "8") {
      // Dairy
      this.setState({ inventoryType: 8 }, () => this.getInventoryList())
    }
  }
  changeView = (e) => {
    this.setState({ view: e }, () => {})
  }
  handleStatusChange = (ac, status) => {
    let { userId } = this.state
    let self = this
    let obj = {
      inventoryId: status._id,
      status: status.status == 2 ? 1 : 2,
      userId: userId,
    }
    this.setState({ loader: true })

    let { dispatch } = this.props
    dispatch(usersActions.changeInventoryStatus(obj)).then((data) => {
      let inventoryData = this.props.inventory
      self.setState({ loader: false })

      if (inventoryData.statusCode == 1) {
        this.getInventoryList()
      } else {
        this.setState({ errors: inventoryData.error.responseMessage })
      }
    })

    // changeInventoryStatus(obj).then(res => {

    // })
  }

  handleDelete = (ac, status) => {
    let { inventoryId, userId } = this.state
    let self = this

    let obj = {
      inventoryId: inventoryId,
      userId: userId,
    }
    this.setState({ loader: false, dlt: false })

    let { dispatch } = this.props
    dispatch(usersActions.deleteInventory(obj)).then((data) => {
      let inventoryData = this.props.inventory
      self.setState({ loader: false })

      if (inventoryData.statusCode == 1) {
        this.setState({ dlt: false })

        this.getInventoryList()
      } else {
        this.setState({ errors: inventoryData.error.responseMessage })
      }
    })
  }

  handleSearch = (e) => {
    this.setState({ search: e.target.value })

    this.setState({ search: e.target.value })
    if (e.target.value.length > 2) {
      this.setState({ search: e.target.value }, () => this.getInventoryList())
    }
    if (e.target.value === "") {
      this.setState({ search: e.target.value }, () => this.getInventoryList())
    }
  }

  printDocument = () => {
    {
      let self = this

      let {
        search,
        pageNo,
        count,
        sortField,
        sortType,
        inventoryType,
        userId,
        status,
      } = this.state
      let params = `?&search=${search}&userId=${userId}&count=${500}`

      let { dispatch } = this.props
      dispatch(usersActions.getInventoryList(params)).then((data) => {
        let inventoryData = this.props.inventory

        self.setState({ loader: false })

        if (inventoryData.statusCode == 1) {
          let inventoryInfo1 = inventoryData.responseData.result.invetoryList
          var doc = new jsPDF()

          if (this.state.inventoryInfo) {
            // let self = this.props
            let len = this.state.inventoryInfo.length - 1
            let arr = []

            doc.setFontSize(12)

            doc.autoTable({ html: "#divToPrint1" })
            let table1 = inventoryInfo1.map((item, index) => [
              index + 1,
              item.itemName === "others" ? item.otherItemName : item.itemName,
              item.variety ? item.variety : "",
              item.wholeselerPrice && item.wholeselerUnit
                ? "$" +
                  item.wholeselerPrice +
                  " " +
                  "/" +
                  " " +
                  Units(item.wholeselerUnit)
                : "",
              item.consumerPrice && item.consumerUnit
                ? "$" +
                  item.consumerPrice +
                  " " +
                  "/" +
                  " " +
                  Units(item.consumerUnit)
                : "",
              item.description ? item.description : "",
            ])

            doc.autoTable({
              head: [
                [
                  "#",
                  "Item",
                  "Variety",
                  "Wholesale Price Per Unit",
                  "Consumer Price Per Unit",
                  "Description",
                ],
              ],
              body: table1.length == 0 ? [["no data available "]] : table1,
            })
            setTimeout(() => doc.save("Inventory.pdf"), 5000)
          }
        } else {
          this.setState({ errors: inventoryData.error.responseMessage })
        }
      })
    }
  }

  render() {
    let {
      accountId,
      invOtherNameErrorMsg,
      otherCustUnitErrorMsg,
      otherWholeselUnitErrorMsg,
      otherItemName,
      otherEditItemName,
      search,
      itemList,
      errors,
      otherConsumerUnit,
      otherCustUnit,
      edit,
      dlt,
      show,
      inventoryInfo,
      count,
      pageNo,
      view,
      wholesalePrice,
      otherWholeSalerUnit,
      otherWholeSalUnit,
      itemName,
      inventoryType,
      inventoryTypeEdit,
      consumerUnit,
      consumerPrice,
      maximumConsumerQuantity,
      wholeselerUnit,
      wholeselerPrice,
      maximumWholeselerQuantity,
      description,
      status,
      image,
      imagePreview,
      imageErrorMsg,
      viewInventory,
      loader,
      pledge,
      fileImage,
      fileImagePreview,
      fileImageErrorMsg,
      custPrice,
      custUnit,
      invType,
      invName,
      wholeselUnit,
      wholeselPrice,
      descript,
      custUnitErrorMsg,
      custPriceErrorMsg,
      invTypeErrorMsg,
      invNameErrorMsg,
      maxConsumerQuantityErrorMsg,
      wholeselUnitErrorMsg,
      wholeselPriceErrorMsg,
      maxWholeselerQuantityErrorMsg,
      descriptErrorMsg,
      organic,
      variety,
      editVariety,
      organicEditErrorMsg,
      varietyErrorMsg,
      editPledge,
      editOrganic,
      isSubscription,
      subscriptionInterval,
      subscriptionIntervalCount,
      subscriptionIntervalErrorMsg,
      subscriptionIntervalCountErrorMsg,
      distributionTypeOption,
      userId,
      inventoryItem,
    } = this.state

    const inventoryCards = inventoryInfo.map((item) => {
      return (
        <InventoryItemCard
          inventoryItem={item}
          onDetails={({ inventoryItem }) => {
            this.openViewPopup({}, inventoryItem)
          }}
          onEdit={({ inventoryItem }) => {
            this.EditItem({}, inventoryItem)
          }}
          onDelete={({ inventoryItem }) => {
            this.DeleteItem({}, inventoryItem)
          }}
          onStatusChange={({ inventoryItem }) => {
            this.handleStatusChange({}, inventoryItem)
          }}
        />
      )
    })

    const inventoryRows = (
      <div className="cm-inventory_gridview">
        <div class="table-responsive" id="divToPrint">
          <table class="table cm_bottom_space">
            <thead>
              <tr class="broder_top_0">
                <th scope="col">S.No.</th>
                <th scope="col">Image</th>
                <th scope="col">Name</th>
                <th scope="col">Subscriptions Enabled</th>
                <th scope="col">Wholesale Price</th>
                <th scope="col">Consumer Price</th>
                <th scope="col">Wholesale Quantity</th>
                <th scope="col">Consumer Quantity</th>
                <th scope="col">Description</th>
                <th scope="col">Active/Inactive</th>
                <th scope="col">Action</th>
              </tr>
            </thead>
            <tbody>
              {!_.isEmpty(inventoryInfo) &&
                inventoryInfo.map((item, i) => (
                  <tr key={item._id}>
                    <th>{(pageNo - 1) * count + (i + 1)}</th>

                    <td>
                      <img height="20px" width="20px" src={item.image} alt="" />
                    </td>
                    <td>
                      <div onClick={(e) => this.openViewPopup(e, item)}>
                        {item.itemName === "others"
                          ? item.otherItemName
                          : item.itemName}
                      </div>
                    </td>
                    <td>
                      {isSubscriptionItem({ inventoryItem: item }) ? (
                        <p>Subscriptions Enabled</p>
                      ) : (
                        <p>Subscriptions Disabled</p>
                      )}
                    </td>
                    <td>
                      <span className="text-warning">
                        ${item.wholeselerPrice ? item.wholeselerPrice : ""}
                      </span>
                    </td>
                    <td>
                      <span className="text-info">
                        ${item.consumerPrice ? item.consumerPrice : ""}
                      </span>
                    </td>

                    <td>
                      {item.maximumWholeselerQuantity
                        ? item.maximumWholeselerQuantity
                        : ""}{" "}
                      {item.wholeselerUnit === 13
                        ? item.otherWholeSalerUnit
                        : Units(item.wholeselerUnit)}
                    </td>
                    <td>
                      {item.maximumConsumerQuantity
                        ? item.maximumConsumerQuantity
                        : ""}{" "}
                      {item.consumerUnit === 13
                        ? item.otherConsumerUnit
                        : Units(item.consumerUnit)}
                    </td>
                    <td>{item.description ? item.description : ""} </td>

                    <td>
                      <Form.Check
                        type="switch"
                        id={item._id}
                        checked={item.status === 1 ? true : false}
                        onChange={(e) => this.handleStatusChange(e, item)}
                        label=""
                      />
                    </td>
                    <td width="100">
                      <div className="action_wrapper">
                        <span>
                          <i
                            class="fas fa-eye"
                            onClick={(e) => this.openViewPopup(e, item)}
                          ></i>
                        </span>
                        <span>
                          <i
                            style={{ color: "#F78F1E" }}
                            class="fas fa-pen"
                            onClick={(e) => this.EditItem(e, item)}
                          ></i>
                        </span>
                        <span>
                          <i
                            style={{ color: "red" }}
                            onClick={(e) => this.DeleteItem(e, item)}
                            class="fas fa-trash-alt"
                          ></i>
                        </span>
                        {/*<span><i onClick={(e)=>this.handleDelete(e,item)} class="fas fa-trash-alt"></i></span>*/}
                      </div>
                    </td>
                  </tr>
                ))}{" "}
            </tbody>
          </table>
        </div>
      </div>
    )

    return (
      <>
        <Sidebar />

        <div>
          <div className="header_wrapper">
            <div className="header_logo">
              <img src={require("../../assets/img/OFFICIAL LOGO.png")} alt="" />
            </div>
            <div className="header_search">
              <div className="form-group has-search">
                <span className="fa fa-search form-control-feedback"></span>
                <Form.Control
                  type="search"
                  placeholder="Search..."
                  name="search"
                  value={search}
                  onChange={this.handleSearch}
                />
              </div>
            </div>
            <div className="bell_icon">
              <i className="fas fa-bell"></i>
            </div>
          </div>

          <div className="body_container">
            <div className="body_inner_wrapper">
              {this.state.loader ? (
                <div className="loader_wrapper">
                  <div class="spinner-border text-info" role="status"></div>
                </div>
              ) : (
                <>
                  <header className="header_content">
                    <p>Inventory</p>

                    <p className="addurbilltext">
                      {accountId ? (
                        ""
                      ) : (
                        <Link to="/settings" style={{ color: "green" }}>
                          Please add your billing information to show your
                          inventories to the customer or wholesaler.
                        </Link>
                      )}
                    </p>
                    <div className="d-flex text-nowrap header_btn">
                      <div className="print_button">
                        <Button variant="light" onClick={this.printDocument}>
                          <i className="fas fa-print"></i> Print Inventory{" "}
                        </Button>
                      </div>
                      <Button
                        onClick={(e) => this.changeView(1)}
                        variant="light"
                      >
                        <i class="fas fa-th-large"></i>
                      </Button>
                      <Button
                        onClick={(e) => this.changeView(2)}
                        variant="light"
                      >
                        <i class="fas fa-bars"></i>
                      </Button>
                      <Button
                        variant="warning"
                        className="text-light"
                        onClick={this.handleShow}
                      >
                        <i class="fas fa-plus-circle"></i> Add Inventory
                      </Button>
                    </div>
                  </header>

                  <div className="main_content cm-inventory">
                    {view === 1 ? (
                      <div className="cm-inventory">
                        <Tabs
                          defaultActiveKey="1"
                          onSelect={(e) => this.inventoryType(e)}
                          activeKey={inventoryType}
                          id="uncontrolled-tab-example"
                        >
                          <Tab eventKey="1" title="Vegetables">
                            <CardDeck>{inventoryCards}</CardDeck>
                          </Tab>
                          <Tab eventKey="2" title="Seedlings">
                            <CardDeck>{inventoryCards}</CardDeck>
                          </Tab>
                          <Tab eventKey="3" title="CSA Shares">
                            <CardDeck>{inventoryCards}</CardDeck>
                          </Tab>
                          <Tab eventKey="4" title="Subscription Boxes">
                            <CardDeck>{inventoryCards}</CardDeck>
                          </Tab>
                          <Tab eventKey="6" title="Fruits">
                            <CardDeck>{inventoryCards}</CardDeck>
                          </Tab>
                          <Tab eventKey="7" title="Meat and Eggs">
                            <CardDeck>{inventoryCards}</CardDeck>
                          </Tab>
                          <Tab eventKey="8" title="Dairy">
                            <CardDeck>{inventoryCards}</CardDeck>
                          </Tab>
                          <Tab eventKey="5" title="Others">
                            <CardDeck>{inventoryCards}</CardDeck>
                          </Tab>
                        </Tabs>
                      </div>
                    ) : (
                      <Tabs
                        defaultActiveKey="1"
                        onSelect={(e) => this.inventoryType(e)}
                        activeKey={inventoryType}
                        id="uncontrolled-tab-example"
                      >
                        <Tab eventKey="1" title="Vegetables">
                          {inventoryRows}
                        </Tab>
                        <Tab eventKey="2" title="Seedlings">
                          {inventoryRows}
                        </Tab>
                        <Tab eventKey="3" title="CSA Shares">
                          {inventoryRows}
                        </Tab>
                        <Tab eventKey="4" title="Subscription Boxes">
                          {inventoryRows}
                        </Tab>
                        <Tab eventKey="6" title="Fruits">
                          {inventoryRows}
                        </Tab>
                        <Tab eventKey="7" title="Meat and Eggs">
                          {inventoryRows}
                        </Tab>
                        <Tab eventKey="8" title="Dairy">
                          {inventoryRows}
                        </Tab>
                        <Tab eventKey="5" title="Others">
                          {inventoryRows}
                        </Tab>
                      </Tabs>
                    )}
                    {_.isEmpty(inventoryInfo) ? (
                      <h3 className="text-center">No data found</h3>
                    ) : (
                      ""
                    )}
                  </div>
                </>
              )}
            </div>

            <Modal
              show={edit}
              onHide={this.CloseEdit}
              className="cm_order_view edit_item inventry"
            >
              {errors ? <h6 className="ml-9 network-error">{errors} </h6> : ""}
              <Modal.Header closeButton>
                <Modal.Title>Edit Inventory</Modal.Title>
              </Modal.Header>

              <Modal.Body>
                <InventoryItemForm
                  itemList={itemList}
                  readOnly={false}
                  inventoryItem={inventoryItem}
                  userId={userId}
                  handleSubmit={({ inventoryItemForm }) => {
                    debug("handle-submit")
                    debug(inventoryItemForm)
                    this.handleEditSubmit({ inventoryItemForm })
                  }}
                  handleClose={() => {
                    this.CloseEdit()
                  }}
                />
              </Modal.Body>

              <Modal.Footer></Modal.Footer>
            </Modal>

            <Modal
              show={dlt}
              onHide={this.CloseDeleteScreen}
              className="cm_order_view dlt_item"
            >
              <Modal.Header closeButton>
                <Modal.Title></Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <img
                  src={require("../../assets/img/Delete-Email.png")}
                  alt=""
                />
                <br />
                <br />
                <p>Are you sure you want to delete this item?</p>
              </Modal.Body>
              <Modal.Footer>
                <div className="footer_btn_wrapper">
                  <Button
                    variant="warning"
                    onClick={(e) => this.handleDelete()}
                  >
                    Delete
                  </Button>
                  {/*<span><i onClick={(e)=>this.handleDelete(e,item)} class="fas fa-trash-alt"></i></span>*/}

                  <Button variant="dark" onClick={this.CloseDeleteScreen}>
                    Cancel
                  </Button>
                </div>
              </Modal.Footer>
            </Modal>

            <Modal
              show={show}
              onHide={this.handleClose}
              className="cm_order_view inventry"
            >
              {errors ? <h6 className="mt-4 network-error">{errors} </h6> : ""}

              <Modal.Header closeButton>
                <Modal.Title>Add Inventory</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <InventoryItemForm
                  itemList={itemList}
                  readOnly={false}
                  userId={userId}
                  handleSubmit={({ inventoryItemForm }) => {
                    debug("handle-submit")
                    debug(inventoryItemForm)
                    this.handleAddSubmit({ inventoryItemForm })
                  }}
                  handleClose={() => {
                    this.handleClose()
                  }}
                />
              </Modal.Body>
              <Modal.Footer></Modal.Footer>
            </Modal>

            <Modal
              show={viewInventory}
              onHide={this.handleClose}
              className="cm_order_view edit_item inventry view_sec"
            >
              <Modal.Header closeButton>
                <Modal.Title>View Inventory</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <InventoryItemForm
                  itemList={itemList}
                  readOnly={true}
                  inventoryItem={inventoryItem}
                  userId={userId}
                  handleSubmit={() => {}}
                  handleClose={() => {
                    this.handleClose()
                  }}
                />
              </Modal.Body>

              <Modal.Footer></Modal.Footer>
            </Modal>
          </div>
        </div>
      </>
    )
  }
}

function mapStateToProps(state) {
  const { props, login, user, inventory } = state
  return {
    props,
    login,
    user,
    inventory,
  }
}

export default connect(mapStateToProps)(Inventory)

// export default Inventory
