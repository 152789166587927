import { USER_TYPE } from "healthyplaneat-common"
import siteSetting from "../config/env/index"

export const getUserType = () => {
  if (siteSetting.isHost) return USER_TYPE.PICKUPHOST
  if (siteSetting.isFarmer) return USER_TYPE.FARMER
  throw new Error("undefined user type")
}

export const stripeAccountIdFromUserProfile = ({ userProfile }) => {
  let accountId
  if (siteSetting.isFarmer && userProfile.accountId) {
    // farmer stripe account id
    accountId = userProfile.accountId
  }
  if (siteSetting.isHost && userProfile.host?.stripeAccountId) {
    accountId = userProfile.host.stripeAccountId
  }
  return accountId
}
