import React, { Component } from "react"
import { Button, Col, Form, Row } from "react-bootstrap"
import Sidebar from "../../components/sidebar"
import { farmActions } from "../../_actions"
import { connect } from "react-redux"
import { Link } from "react-router-dom"
import _ from "lodash"
import Geocode from "react-geocode"

Geocode.setApiKey("AIzaSyCDo6u0GLnkdPy9OHiS53-4yDhp_PEaCWA")
Geocode.enableDebug()
Geocode.setLanguage("en")

let videoHeight, videoWidth, videoPreview

class FarmEdit extends Component {
  constructor(props) {
    super(props)

    this.state = {
      errors: "",
      success: "",
      userInfo: [],
      isEdit: false,
      valiDate: true,
      farmImagePreview: "",
      farmImageErrorMsg: "",
      farmImage: [],
      farmLogoPreview: "",
      farmLogo: "",
      farmLogoErrorMsg: "",
      farmVideoPreview: "",
      farmVideo: "",
      farmVideoErrorMsg: "",
      farmMediaErrorMsg: "",

      userId: localStorage.getItem("userId"),
      farmName: "",
      farmStatus: "",
      infoAboutFarm: "",
      infoAboutFarmer: "",
      farmNameErrorMsg: "",
      infoAboutFarmErrorMsg: "",
      infoAboutFarmerErrorMsg: "",
      farmCRID: "",
      farmEPSAccountNumber: "",

      farmId: "",
      farmInfo: [],
      media: [],
      loader: true,
      miniloader: false,
      miniloaderV: false,
      indexing: "",

      address: "",
      addressLine1: "",
      addressLine2: "",
      state: "",
      postalCode: "",
      city: "",
      lat: 0,
      shortInfo: "",
      lng: 0,
    }
  }

  componentDidMount() {
    this.getFarmDetails()
  }

  handleValidation = () => {
    let validate = true
    let { farmName, infoAboutFarm, infoAboutFarmer, farmLogo } = this.state

    if (farmName === "" || farmName === undefined) {
      validate = false
      this.setState({
        farmNameErrorMsg: "Please enter your farm name.",
      })
    } else {
      this.setState({
        farmNameErrorMsg: "",
      })
    }
    if (infoAboutFarm === "" || infoAboutFarm === undefined) {
      validate = false
      this.setState({
        infoAboutFarmErrorMsg: "Please enter information about farm.",
      })
    } else {
      this.setState({
        infoAboutFarmErrorMsg: "",
      })
    }
    if (infoAboutFarmer === "" || infoAboutFarmer === undefined) {
      validate = false
      this.setState({
        infoAboutFarmerErrorMsg: "Please enter information about you.",
      })
    } else {
      this.setState({
        infoAboutFarmerErrorMsg: "",
      })
    }

    if (farmLogo) {
      if (farmLogo === "" || farmLogo === undefined) {
        validate = false
        this.setState({
          farmLogoErrorMsg: "Please select farm logo",
        })
      } else if (farmLogo.size > 5000000) {
        validate = false
        this.setState({
          farmLogoErrorMsg: "Farm logo size should be less than 5Mb",
        })
      } else {
        this.setState({
          farmLogoErrorMsg: "",
        })
      }
    }
    return validate
  }

  handleChanged = (e) => {
    let self = this
    let { addressLine1, addressLine2, city, state, postalCode } = this.state
    if (e.target.name === "addressLine1") {
      addressLine1 = e.target.value
    }
    if (e.target.name === "addressLine2") {
      addressLine2 = e.target.value
    }

    if (e.target.name === "city") {
      city = e.target.value
    }
    if (e.target.name === "state") {
      state = e.target.value
    }
    if (e.target.name === "postalCode") {
      postalCode = e.target.value
    }

    let addr =
      addressLine1 +
      " " +
      addressLine2 +
      " " +
      city +
      " " +
      state +
      " " +
      postalCode
    if (
      e.target.name === "addressLine1" ||
      e.target.name === "addressLine2" ||
      e.target.name === "city" ||
      e.target.name === "state" ||
      e.target.name === "postalCode"
    ) {
      Geocode.fromAddress(addr).then(
        (response) => {
          const { lat, lng } = response.results[0].geometry.location

          self.setState({ lat: lat, lng: lng })
        },
        (error) => {
          console.error(error)
        }
      )
    }

    console.log("onchange names", e)
    this.setState({
      [e.target.name]: e.target.value,
      infoAboutFarmerErrorMsg: "",
      infoAboutFarmErrorMsg: "",
      farmNameErrorMsg: "",
      farmLogoErrorMsg: "",
      farmVideoErrorMsg: "",
      farmImageErrorMsg: "",
    })
  }
  editMode = () => {
    this.setState({ isEdit: true })
  }

  viewMode = () => {
    this.setState({ isEdit: false })
  }

  handleLogoUpload = (event) => {
    console.log(event, "eventeventevent", event.target.files[0])
    let reader = new FileReader()
    let file = event.target.files[0]

    let { valiDate } = this.state

    reader.addEventListener(
      "load",
      () => {
        this.setState({ farmLogoPreview: reader.result }, function () {})
      },
      false
    )
    if (file) {
      reader.readAsDataURL(file)
    }
    if (file) {
      if (
        file.type === "image/jpeg" ||
        file.type === "image/gif" ||
        file.type === "image/png" ||
        file.type === "image/svg+xml"
      ) {
        if (file.size > 5000000) {
          valiDate = false
          this.setState({
            farmLogo: event.target.files[0],
            farmLogoErrorMsg: "Farm logo size should be less than 5Mb.",
          })
        } else {
          this.setState({
            farmLogo: event.target.files[0],
            farmLogoErrorMsg: "",
          })
        }
      } else {
        valiDate = false
        this.setState({ farmLogoErrorMsg: "File type not supported." })
      }
    }
  }

  handleEditSubmit = (e) => {
    let {
      userId,
      farmLogo,
      farmId,
      addressLine1,
      addressLine2,
      city,
      state,
      postalCode,
      mapPosition,
      lat,
      lng,
      shortInfo,
      farmImage,
      farmVideo,
      farmName,
      farmStatus,
      infoAboutFarm,
      infoAboutFarmer,
      description,
    } = this.state
    e.preventDefault()
    // let {addressLine1, addressLine2, city, state, postalCode, mapPosition, lat, lng} = this.props.mapFarmReducer

    let self = this
    if (this.handleValidation()) {
      // this.setState({redirect: false, loader: true});

      let obj = new FormData()
      obj.append("userId", userId)
      obj.append("farmId", farmId)
      obj.append("farmLogo", farmLogo)
      obj.append("farmName", farmName)
      obj.append("farmStatus", 1)
      obj.append("infoAboutFarm", infoAboutFarm)
      obj.append("shortInfo", shortInfo)
      obj.append("infoAboutFarmer", infoAboutFarmer)
      obj.append("farmCRID", this.state.farmCRID)
      obj.append("farmEPSAccountNumber", this.state.farmEPSAccountNumber)
      // if (mapPosition) obj.append('lat', mapPosition.lat);
      // if (mapPosition) obj.append('long', mapPosition.lng);
      // if (addressLine1) obj.append('addressLine1', addressLine1);
      // if (addressLine2) obj.append('addressLine2', addressLine2);
      // if (city) obj.append('city', city);
      // if (state) obj.append('state', state);
      // if (postalCode) obj.append('postalCode', postalCode);
      if (lat) obj.append("lat", lat)
      if (lng) obj.append("long", lng)
      if (addressLine1) obj.append("addressLine1", addressLine1)
      if (addressLine2) obj.append("addressLine2", addressLine2)
      if (city) obj.append("city", city)
      if (state) obj.append("state", state)
      if (postalCode) obj.append("postalCode", postalCode)
      this.setState({ errors: "", loader: false })
      self.setState({ loader: true })

      let { dispatch } = this.props
      dispatch(farmActions.editFarm(obj)).then((data) => {
        self.setState({ loader: false })
        if (data.statusCode == 1) {
          this.props.history.push("/farm")
          self.setState({ isEdit: false })
        } else {
          self.setState({ errors: data.error.responseMessage })
        }
      })
    }
  }
  getFarmDetails = () => {
    let {
      userId,
      farmLogo,
      farmImage,
      farmVideo,
      farmLogoPreview,
      farmVideoPreview,
      farmName,
      farmStatus,
      infoAboutFarm,
      infoAboutFarmer,
      description,
    } = this.state

    let self = this
    let params = `?userId=${userId}&farmerId=${userId}`
    this.setState({ errors: "" })
    self.setState({ loader: true })

    let { dispatch } = this.props
    dispatch(farmActions.getFarmDetails(params)).then((data) => {
      let farmInfo = data.responseData.result
      self.setState({ loader: false })

      console.log(
        "muuuuuuuuuuuuuuuuuuuuuu3333333",
        farmInfo.length > 0 ? farmInfo[0] : ""
      )
      if (data.statusCode == 1) {
        self.setState({
          farmInfo: farmInfo,
          farmLogo: farmInfo.length > 0 ? farmInfo[0].farmLogo : "",
          shortInfo: farmInfo.length > 0 ? farmInfo[0].shortInfo : "",
          farmId: farmInfo.length > 0 ? farmInfo[0]._id : "",
          farmLogoPreview: farmInfo.length > 0 ? farmInfo[0].farmLogo : "",
          media: farmInfo.length > 0 ? farmInfo[0].media : "",
          farmName: farmInfo.length > 0 ? farmInfo[0].farmName : "",
          infoAboutFarm: farmInfo.length > 0 ? farmInfo[0].infoAboutFarm : "",
          infoAboutFarmer:
            farmInfo.length > 0 ? farmInfo[0].infoAboutFarmer : "",
          addressLine1:
            farmInfo.length > 0 && !_.isEmpty(farmInfo[0].farmAddress)
              ? farmInfo[0].farmAddress[0].addressLine1
              : "",
          addressLine2:
            farmInfo.length > 0 && !_.isEmpty(farmInfo[0].farmAddress)
              ? farmInfo[0].farmAddress[0].addressLine2
              : "",
          city:
            farmInfo.length > 0 && !_.isEmpty(farmInfo[0].farmAddress)
              ? farmInfo[0].farmAddress[0].city
              : "",
          state:
            farmInfo.length > 0 && !_.isEmpty(farmInfo[0].farmAddress)
              ? farmInfo[0].farmAddress[0].state
              : "",
          postalCode:
            farmInfo.length > 0 && !_.isEmpty(farmInfo[0].farmAddress)
              ? farmInfo[0].farmAddress[0].postalCode
              : "",

          lng: farmInfo.length > 0 ? farmInfo[0].geoLocation[0] : 0,
          lat: farmInfo.length > 0 ? farmInfo[0].geoLocation[1] : 0,
        })
      } else {
        self.setState({ errors: data.error.responseMessage })
      }
    })
  }

  editImage = (event, item, i) => {
    let reader = new FileReader()
    let file = event
    let { valiDate, farmMediaErrorMsg } = this.state

    let { userId, farmId } = this.state
    let fileImage = event.target.files[0]
    if (fileImage) {
      if (event.target.files[0].size < 5000000) {
        let self = this

        let obj = new FormData()

        obj.append("farmId", farmId)
        if (fileImage) obj.append("media", fileImage)
        if (userId) obj.append("userId", userId)
        if (item) obj.append("mediaId", item._id)
        // this.setState({loader: true})
        this.setState({ miniloader: true, indexing: i, farmMediaErrorMsg: "" })

        let { dispatch } = this.props
        dispatch(farmActions.editMedia(obj)).then((data) => {
          self.setState({ miniloader: false })
          // self.setState({loader: false})
          if (data.statusCode == 1) {
            // console.log(data.statusCode)
            self.setState({
              edit: false,
              media: data.responseData.result.media,
            })
            // self.getFarmDetails()
          } else {
            self.setState({ errors: data.error.responseMessage })
          }
        })
      } else {
        // valiDate = false;
        this.setState({
          farmMediaErrorMsg: "File size must be less than 5Mb.",
        })
      }
    }
  }
  deleteImage = (event, item, i) => {
    let { userId, farmId } = this.state
    let self = this
    let obj = {
      farmId: farmId,
      userId: userId,
      mediaId: item._id,
    }
    console.log(obj, "ssssssssssssssss", item, i)
    // this.setState({loader: true})
    this.setState({ miniloader: true, indexing: i, farmMediaErrorMsg: "" })

    let { dispatch } = this.props
    dispatch(farmActions.deleteMedia(obj)).then((data) => {
      self.setState({ miniloader: false })

      if (data.statusCode == 1) {
        self.setState({ edit: false, media: data.responseData.result.media })
      } else {
        self.setState({ errors: data.error.responseMessage })
      }
    })
  }

  editVideo = (event, item) => {
    let reader = new FileReader()
    let file = event

    let { userId, valiDate, farmId } = this.state

    let farmVideo = event.target.files[0]

    let self = this

    let obj = new FormData()

    obj.append("farmId", farmId)

    if (farmVideo) obj.append("media", farmVideo)
    if (userId) obj.append("userId", userId)
    if (item) obj.append("mediaId", item._id)

    if (farmVideo) {
      if (
        farmVideo.type === "video/mp4" ||
        farmVideo.type === "video/webm" ||
        farmVideo.type === "video/ogg"
      ) {
        this.setState({ miniloaderV: true })

        let { dispatch } = this.props
        dispatch(farmActions.editMedia(obj)).then((data) => {
          self.setState({ miniloaderV: false, farmVideoErrorMsg: "" })
          if (data.statusCode == 1) {
            self.setState({ edit: false })
            self.getFarmDetails()
          } else {
            self.setState({ errors: data.error.responseMessage })
          }
        })
      } else {
        valiDate = false
        this.setState({ farmVideoErrorMsg: "File type not supported." })
      }
    }
  }

  addImage = (event, item) => {
    let reader = new FileReader()
    let file = event
    let { userId, farmId } = this.state
    let fileImage = event.target.files[0]
    let self = this
    let obj = new FormData()

    obj.append("farmId", farmId)
    if (fileImage) obj.append("media", fileImage)
    if (userId) obj.append("userId", userId)
    if (userId) obj.append("mediaId", item._id)

    this.setState({ miniloader: true })

    let { dispatch } = this.props
    dispatch(farmActions.editMedia(obj)).then((data) => {
      self.setState({ miniloader: false })
      if (data.statusCode == 1) {
        self.getFarmDetails()
      } else {
        self.setState({ errors: data.error.responseMessage })
      }
    })
  }

  handleDelete = (e, value) => {
    let { farmId, userId } = this.state
    let obj = {
      farmId: farmId,
      userId: value._id,
    }
    this.setState({ errors: "", loader: true })

    let { dispatch } = this.props

    dispatch(farmActions.deleteMedia(obj)).then((data) => {
      this.setState({ loader: false })
      let inventoryData = this.props.inventory
      if (inventoryData.statusCode == 1) {
        this.getFarmDetails()
      } else {
        // this.setState({errors: inventoryData.error.responseMessage})
      }
    })
  }
  handleVideoUpload = (event) => {
    let doc = {
      path: URL.createObjectURL(event.target.files[0]),
      type: event.target.files[0].type,
      preview: URL.createObjectURL(event.target.files[0]),
      fileName: event.target.files[0].name,
    }

    videoPreview = doc

    console.log("docdoc", doc, "eventeventevent", event.target)
    // let reader = new FileReader();
    // let file = event.target.files[0];

    let { valiDate, userId, farmId } = this.state
    let file = event.target.files[0]
    let self = this

    let obj = new FormData()

    obj.append("farmId", farmId)

    if (file) obj.append("media", file)
    if (this.state.userId) obj.append("userId", this.state.userId)
    // if (item) obj.append('mediaId', item._id)

    if (
      file.type === "video/mp4" ||
      file.type === "video/webm" ||
      file.type === "video/ogg"
    ) {
      console.log(
        doc,
        event.target.files[0],
        "event.target.files[0]event.target.files[0]"
      )
      console.log(file, "szxxxxxxxxxxxxxxxxxxxxxxxxxzxSx")

      this.setState({
        farmVideo: file,
        farmVideoErrorMsg: "",
      })

      this.setState({ miniloaderV: true })

      let { dispatch } = this.props
      dispatch(farmActions.editMedia(obj)).then((data) => {
        self.setState({ miniloaderV: false, farmVideoErrorMsg: "" })
        if (data.statusCode == 1) {
          self.setState({ edit: false })
          self.getFarmDetails()
        } else {
          self.setState({ errors: data.error.responseMessage })
        }
      })
    } else {
      valiDate = false
      this.setState({ farmVideoErrorMsg: "File type not supported." })
    }
  }
  onKeyPress(e) {
    e = e || window.event
    var charCode = typeof e.which == "undefined" ? e.keyCode : e.which
    var charStr = String.fromCharCode(charCode)

    if (!charStr.match(/^[0-9]+$/)) e.preventDefault()
  }

  render() {
    console.log(this.state.farmMediaErrorMsg, "farmMediaErrorMsgs")

    let {
      isEdit,
      farmInfo,
      media,
      loader,
      indexing,
      shortInfo,
      farmMediaErrorMsg,
      farmImagePreview,
      farmImageErrorMsg,
      farmImage,
      farmLogoPreview,
      farmLogo,
      farmLogoErrorMsg,
      farmVideoPreview,
      farmVideo,
      farmVideoErrorMsg,
      userId,
      farmName,
      farmStatus,
      infoAboutFarm,
      infoAboutFarmer,
      farmNameErrorMsg,
      infoAboutFarmErrorMsg,
      infoAboutFarmerErrorMsg,
    } = this.state

    return (
      <>
        <Sidebar />

        <div>
          <div className="header_wrapper">
            <div className="header_logo">
              <img src={require("../../assets/img/OFFICIAL LOGO.png")} />
            </div>
            <div className="header_search">
              <div className="form-group has-search">
                <span className="fa fa-search form-control-feedback"></span>
                <Form.Control type="search" placeholder="search" />
              </div>
            </div>
            <div className="bell_icon">
              <i className="fas fa-bell"></i>
            </div>
          </div>

          <div className="body_container">
            <div className="body_inner_wrapper">
              {this.state.loader ? (
                <div className="loader_wrapper">
                  <div class="spinner-border text-info" role="status"></div>
                </div>
              ) : (
                <>
                  <header className="header_content">
                    <p>Farm</p>
                  </header>
                  <div className="main_content">
                    <form onSubmit={this.handleEditSubmit}>
                      <div className="farm_page">
                        <Row>
                          <Col lg="2" md="3">
                            <p>Farm Logo:</p>
                          </Col>
                          <Col md="7">
                            <div
                              className="file_uploader bg_size mx-auto edit_dlt"
                              style={{
                                backgroundImage: `url(${farmLogoPreview})`,
                              }}
                            >
                              <div class="overlay"></div>
                              <i className="fas fa-pen"></i>
                              <Form.File
                                label="Custom file input"
                                name="farmLogo"
                                accept="image/x-png,image/gif,image/jpeg"
                                onChange={this.handleLogoUpload}
                                lang="en"
                                custom
                              />
                            </div>
                            <p className="text-center">
                              <small>Recommended size 5Mb </small>
                            </p>

                            {farmLogoErrorMsg ? (
                              <div className="error">{farmLogoErrorMsg}</div>
                            ) : null}
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="2" md="3">
                            <p>Farm Name:</p>
                          </Col>
                          <Col md="7">
                            <Form.Control
                              type="text"
                              onChange={this.handleChanged}
                              name="farmName"
                              value={farmName}
                              placeholder="Farm name "
                            />
                            {console.log(
                              farmNameErrorMsg,
                              "ssssssssssssssssssss"
                            )}
                            {farmNameErrorMsg ? (
                              <div className="error">{farmNameErrorMsg}</div>
                            ) : null}
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="2" md="3">
                            <p>Short Info About Farm:</p>
                          </Col>
                          <Col md="7">
                            <Form.Control
                              type="text"
                              onChange={this.handleChanged}
                              name="shortInfo"
                              value={shortInfo}
                              placeholder="Short farm info "
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="2" md="3">
                            <p>Info About Farm:</p>
                          </Col>
                          <Col md="7">
                            <textarea
                              placeholder="Enter here"
                              onChange={this.handleChanged}
                              name="infoAboutFarm"
                              value={infoAboutFarm}
                              className="form-control"
                              rows="4"
                              cols="50"
                            ></textarea>
                            {infoAboutFarmErrorMsg ? (
                              <div className="error">
                                {infoAboutFarmErrorMsg}
                              </div>
                            ) : null}
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="2" md="3">
                            <p>Info About Farmer:</p>
                          </Col>
                          <Col md="7">
                            <textarea
                              placeholder="Enter here"
                              onChange={this.handleChanged}
                              name="infoAboutFarmer"
                              value={infoAboutFarmer}
                              className="form-control"
                              rows="4"
                              cols="50"
                            ></textarea>
                            {infoAboutFarmerErrorMsg ? (
                              <div className="error">
                                {infoAboutFarmerErrorMsg}
                              </div>
                            ) : null}
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="2" md="3">
                            <p>Farm Image:</p>
                          </Col>
                          <Col md="7">
                            {farmInfo.length > 0 ? (
                              <div className="fileupload_wraper">
                                {media.map((value, i) => {
                                  return value.urlType === "I" ? (
                                    <div
                                      className="file_uploader bg_size edit-wrapper"
                                      style={{
                                        backgroundImage: `url(${value.thumbUrl})`,
                                      }}
                                    >
                                      <div className="overlay"></div>
                                      <div className="edit_dlt">
                                        <i className="fas fa-pen text-light"></i>
                                        <Form.File
                                          label="Custom file input"
                                          name="farmImage"
                                          accept="image/x-png,image/gif,image/jpeg"
                                          onChange={(event) =>
                                            this.editImage(event, value, i)
                                          }
                                          lang="en"
                                          custom
                                        />
                                        {/*           <span><i*/}
                                        {/*onClick={(e) => this.handleDelete(e, value)}*/}
                                        {/*className="fas fa-trash-alt"></i></span>*/}
                                        <span className="dlticon">
                                          <i
                                            onClick={(e) =>
                                              this.deleteImage(e, value)
                                            }
                                            className="fas fa-trash-alt c-pnt"
                                          ></i>
                                        </span>
                                      </div>
                                      {indexing === i
                                        ? this.state.miniloader && (
                                            <div className="mini_loader">
                                              <div
                                                class="spinner-border text-info"
                                                role="status"
                                              ></div>
                                            </div>
                                          )
                                        : ""}
                                    </div>
                                  ) : (
                                    ""
                                  )
                                })}
                                {media.length < 5 ? (
                                  <div className="file_uploader bg_size">
                                    <div className="edit_dlt">
                                      <i className="fas fa-plus-circle"></i>
                                      <Form.File
                                        label="Custom file input"
                                        name="farmImage"
                                        accept="image/x-png,image/gif,image/jpeg"
                                        onChange={(event) =>
                                          this.editImage(event)
                                        }
                                        lang="en"
                                        custom
                                      />
                                    </div>
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>
                            ) : (
                              ""
                            )}

                            <p>
                              <small>Recommended size 5Mb </small>
                            </p>
                            {farmMediaErrorMsg ? (
                              <div className="error">{farmMediaErrorMsg}</div>
                            ) : null}
                          </Col>
                        </Row>
                        {/*<Row>*/}
                        {/*    <Col md="2">*/}
                        {/*        <p>Farm Video:</p>*/}
                        {/*    </Col>*/}
                        {/*    {console.log(farmInfo, "farmInfofarmInfofarmInfo1",farmInfo.length > 0,media)}*/}
                        {/*    {media.length > 0 ?*/}
                        {/*        <Col md="7">*/}
                        {/*            <div className="fileupload_wraper">*/}
                        {/*                {media.map(value => {*/}
                        {/*                    console.log("farmInfofarmInfofarmInfo2", value.urlType === "V")*/}
                        {/*                    return (*/}
                        {/*                        value.urlType === "V" ?*/}
                        {/*                            <>*/}
                        {/*                                <div*/}
                        {/*                                    className=" file_uploader bg_size edit-wrapper max-w-100"*/}
                        {/*                                    style={{backgroundImage: `url(${value.thumbUrl})`}}>*/}
                        {/*                                    <div className="overlay"></div>*/}
                        {/*                                    <div className="edit_dlt">*/}
                        {/*                                        <i className="fas fa-pen"></i>*/}
                        {/*                                        <Form.File*/}
                        {/*                                            label="Custom file input"*/}
                        {/*                                            accept="video/mp4,video/x-m4v,video/*"*/}
                        {/*                                            name="farmVideo"*/}
                        {/*                                            onChange={(event) => this.editVideo(event, value)}*/}
                        {/*                                            lang="en" custom/>*/}
                        {/*                                        /!*           <span><i*!/*/}

                        {/*                                        /!*onClick={(e) => this.handleDelete(e, value)}*!/*/}
                        {/*                                        /!*className="fas fa-trash-alt"></i></span>*!/*/}
                        {/*                                        /!*<span><i onClick={(e) => this.handleDelete(e, value)} className="fas fa-trash-alt"></i></span>*!/*/}
                        {/*                                    </div>*/}
                        {/*                                    {this.state.miniloaderV &&*/}
                        {/*                                    <div className="mini_loader">*/}
                        {/*                                        <div*/}
                        {/*                                            class="spinner-border text-info"*/}
                        {/*                                            role="status">*/}
                        {/*                                        </div>*/}
                        {/*                                    </div>*/}
                        {/*                                    }*/}
                        {/*                                </div>*/}

                        {/*                                {farmVideoErrorMsg ?*/}
                        {/*                                    <div*/}
                        {/*                                        className="error">{farmVideoErrorMsg}</div> : null}*/}
                        {/*                            </> :*/}

                        {/*                            ''*/}

                        {/*                    )*/}

                        {/*                })}*/}
                        {/*            </div>*/}
                        {/*        </Col> :*/}

                        {/*        <Col md="7">*/}
                        {/*            <div className="fileupload_wraper">*/}
                        {/*                <>*/}
                        {/*                    <div*/}
                        {/*                        className="file_uploader bg_size bg_size max-w-100 video_upload videothumb">*/}
                        {/*                        {console.log(videoPreview, " ljhugyfhcgxchghjkgjfhc:::", videoPreview !== undefined, "ath this.state.videoPreview.path")}*/}
                        {/*                        {videoPreview !== undefined ?*/}
                        {/*                            <VideoThumbnail*/}
                        {/*                                renderThumbnail={true}*/}
                        {/*                                videoUrl={videoPreview != undefined ? videoPreview.path : ''}*/}
                        {/*                                thumbnailHandler={(thumbnail) => {*/}
                        {/*                                    console.log("ljhugyfhcgxchghjkgjfhc:::::;2")*/}
                        {/*                                    let img = new Image();*/}
                        {/*                                    img.src = thumbnail;*/}
                        {/*                                    img.onload = function () {*/}
                        {/*                                        console.log("ljhugyfhcgxchghjkgjfhc:::::;3")*/}
                        {/*                                        videoHeight = img.height;*/}
                        {/*                                        videoWidth = img.width;*/}
                        {/*                                    }*/}
                        {/*                                }}*/}
                        {/*                                width={videoWidth}*/}
                        {/*                                height={videoHeight}*/}
                        {/*                            />*/}

                        {/*                            :*/}

                        {/*                            <div><i*/}
                        {/*                                className="fas fa-plus-circle"></i>*/}
                        {/*                                <br/>*/}
                        {/*                                <span*/}
                        {/*                                    className="placeholder">Upload Video </span>*/}
                        {/*                            </div>}*/}

                        {/*                        <Form.File label="Custom file input"*/}
                        {/*                                   name="farmVideo"*/}
                        {/*                                   className="video_upload p-cm"*/}
                        {/*                                   accept="video/mp4,video/x-m4v,video/*"*/}
                        {/*                                   onChange={this.handleVideoUpload}*/}
                        {/*                                   lang="en" custom/>*/}

                        {/*                    </div>*/}

                        {/*                    {farmVideoErrorMsg ?*/}
                        {/*                        <div*/}
                        {/*                            className="error">{farmVideoErrorMsg}</div> : null}*/}

                        {/*                </>*/}
                        {/*            </div>*/}
                        {/*        </Col>*/}
                        {/*    }*/}

                        {/*</Row>*/}

                        {/*{this.state.lat ?*/}
                        <Form.Group>
                          {/*<p>Location On Map:</p>*/}
                          <div>
                            {console.log(
                              this.state.lat,
                              this.state.lng,
                              "xsdsddddddddddddddddd.lat"
                            )}
                            <Row>
                              <Col lg="2" md="3">
                                {" "}
                                <label htmlFor="">Farm Address Line 1</label>
                              </Col>
                              <Col md="7">
                                <input
                                  type="text"
                                  name="addressLine1"
                                  className="form-control"
                                  placeholder="Farm Address Line 1"
                                  onChange={this.handleChanged}
                                  value={this.state.addressLine1}
                                />
                              </Col>
                            </Row>
                            {/*</div>*/}
                            <Row>
                              <Col lg="2" md="3">
                                <label htmlFor="">Farm Address Line 2</label>
                              </Col>
                              <Col md="7">
                                {" "}
                                <input
                                  type="text"
                                  name="addressLine2"
                                  className="form-control"
                                  placeholder="Farm Address Line 2"
                                  onChange={this.handleChanged}
                                  value={this.state.addressLine2}
                                />
                              </Col>
                            </Row>

                            <Row>
                              <Col lg="2" md="3">
                                <label htmlFor="">City</label>
                              </Col>
                              <Col md="7">
                                <input
                                  type="text"
                                  name="city"
                                  className="form-control"
                                  placeholder="City"
                                  onChange={this.handleChanged}
                                  value={this.state.city}
                                />
                              </Col>
                            </Row>
                            <Row>
                              <Col lg="2" md="3">
                                <label htmlFor="">State</label>
                              </Col>
                              <Col md="7">
                                <input
                                  type="text"
                                  name="state"
                                  className="form-control"
                                  placeholder="State"
                                  onChange={this.handleChanged}
                                  value={this.state.state}
                                />
                              </Col>
                            </Row>
                            <Row>
                              <Col lg="2" md="3">
                                <label htmlFor="">Postal Code </label>
                              </Col>
                              <Col md="7">
                                <input
                                  type="number"
                                  name="postalCode"
                                  onKeyPress={this.onKeyPress.bind(this)}
                                  placeholder="Postal Code"
                                  className="form-control"
                                  onChange={this.handleChanged}
                                  value={this.state.postalCode}
                                />
                              </Col>
                            </Row>

                            {/*<Map1*/}

                            {/*    google={this.props.google}*/}
                            {/*    center={{*/}
                            {/*        lat: this.state.lat,*/}
                            {/*        lng: this.state.lng*/}
                            {/*    }}*/}
                            {/*    height='200px'*/}
                            {/*    zoom={15}*/}

                            {/*/>*/}

                            {/*12.5204, 75.8567*/}

                            {/* <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d21784730.15225112!2d77.54500981874999!3d70.53342541794991!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sin!4v1588745436848!5m2!1sen!2sin" width="890" height="450" frameborder="0" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe> */}
                          </div>
                        </Form.Group>

                        {/*    : <Form.Group>*/}
                        {/*        <p>Location On Map:</p>*/}
                        {/*        <div>*/}

                        {/*            {console.log(this.state.lat, this.state.lng, "xsdsddddddddddddddddd.lat")}*/}

                        {/*            <Map1*/}

                        {/*                google={this.props.google}*/}
                        {/*                center={{*/}
                        {/*                    lat: 34.155834,*/}
                        {/*                    lng: -119.202789*/}
                        {/*                }}*/}
                        {/*                height='200px'*/}
                        {/*                zoom={15}*/}

                        {/*            />*/}

                        {/*            /!*12.5204, 75.8567*!/*/}

                        {/*            /!* <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d21784730.15225112!2d77.54500981874999!3d70.53342541794991!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sin!4v1588745436848!5m2!1sen!2sin" width="890" height="450" frameborder="0" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe> *!/*/}
                        {/*        </div>*/}
                        {/*    </Form.Group>*/}
                        {/*}*/}
                        <Row>
                          <Col lg="2" md="3">
                            <p>CRID:</p>
                          </Col>
                          <Col md="7">
                            <Form.Control
                              type="text"
                              name="farmCRID"
                              value={this.state.farmCRID}
                              onChange={this.handleChanged}
                              placeholder="Enter CRID"
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="2" md="3">
                            <p>EPS Account Number:</p>
                          </Col>
                          <Col md="7">
                            <Form.Control
                              type="text"
                              name="farmEPSAccountNumber"
                              value={this.state.farmEPSAccountNumber}
                              onChange={this.handleChanged}
                              placeholder="Enter EPS Account Number"
                            />
                          </Col>
                        </Row>

                        <Row>
                          <Col md="8">
                            <div className="text-right cmbtngrp">
                              <Button type="submit" variant="warning">
                                Save
                              </Button>
                              <Link
                                className="btn btn-dark"
                                variant="warning"
                                to="/farm"
                              >
                                Cancel
                              </Link>
                              {/*<Button variant="warning">Save</Button>*/}
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </form>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </>
    )
  }
}

function mapStateToProps(state) {
  const { props, login, user, mapFarmReducer, farm } = state
  console.log("saeeeeeeeeeeeeeeemapFarmReducer222222", mapFarmReducer)

  return {
    props,
    login,
    user,
    mapFarmReducer,
    farm,
  }
}

export default connect(mapStateToProps)(FarmEdit)
