import { isEqual } from "lodash"
import { DISTRIBUTION_TYPE } from "./distribution.constants"
const debug = require("debug")("inventory-constants")

export const INVENTORY_DISTRIBUTION_TYPES = {
  PICKUP_DELIVERY: [DISTRIBUTION_TYPE.PICKUP, DISTRIBUTION_TYPE.DELIVERY],
  SHIPPING: [DISTRIBUTION_TYPE.SHIPPING],
}

export const INVENTORY_DISTRIBUTION_TYPE_OPTIONS = {
  PICKUP_DELIVERY: 1,
  SHIPPING: 2,
}

export const inventoryDistributionTypeForOption = (option) => {
  option = +option
  if (option === INVENTORY_DISTRIBUTION_TYPE_OPTIONS.PICKUP_DELIVERY)
    return INVENTORY_DISTRIBUTION_TYPES.PICKUP_DELIVERY
  if (option === INVENTORY_DISTRIBUTION_TYPE_OPTIONS.SHIPPING)
    return INVENTORY_DISTRIBUTION_TYPES.SHIPPING
  return null
}

export const inventoryDistributionTypeOptionForValue = (value) => {
  debug("inventoryDistributionTypeOptionForValue:value")
  debug(value)
  try {
    if (typeof value === "string") value = JSON.parse(value)
  } catch (error) {
    return INVENTORY_DISTRIBUTION_TYPE_OPTIONS.PICKUP_DELIVERY
  }
  if (isEqual(value, INVENTORY_DISTRIBUTION_TYPES.PICKUP_DELIVERY))
    return INVENTORY_DISTRIBUTION_TYPE_OPTIONS.PICKUP_DELIVERY
  if (isEqual(value, INVENTORY_DISTRIBUTION_TYPES.SHIPPING))
    return INVENTORY_DISTRIBUTION_TYPE_OPTIONS.SHIPPING
  // this default is also found in the db
  debug("inventoryDistributionTypeOptionForValue:default")
  return INVENTORY_DISTRIBUTION_TYPE_OPTIONS.PICKUP_DELIVERY
}
