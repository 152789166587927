import debugFactory from "debug"
import React, { Component } from "react"
import { connect } from "react-redux"
import Sidebar from "../../components/sidebar"
import siteSetting from "../../config/env/index"

import { PickUpHostOrders } from "healthyplaneat-common"

const debug = debugFactory("pickUpHost")

class PickUpHost extends Component {
  constructor(props) {
    super(props)

    this.state = {
      apiDomain: siteSetting.api.url,
    }
  }

  componentDidMount() {}

  handlePrint() {
    window.print()
  }

  render() {
    const userId = this.props.user?.userInfo?.responseData?.userProfile?._id
    const { apiDomain } = this.state
    return (
      <>
        <Sidebar />
        <div className="body_container" id="pick-up-host">
          <div className="body_inner_wrapper p2">
            <div className="pick-up-host-title mb2 row">
              <div className="col-10">
                <h1 className="h3 font-main">Pick Up Host Orders</h1>
              </div>
              <div className="col-2 righted">
                <button
                  className="btn btn-info pick-up-host-print-button"
                  onClick={() => this.handlePrint()}
                >
                  Print
                </button>
              </div>
            </div>
            <PickUpHostOrders apiDomain={apiDomain} userId={userId} />
          </div>
        </div>
      </>
    )
  }
}

function mapStateToProps(state) {
  const { props, user } = state

  return {
    props,
    user,
  }
}

export default connect(mapStateToProps)(PickUpHost)
