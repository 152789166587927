import "jspdf-autotable"

import * as html2canvas from "html2canvas"
import * as jsPDF from "jspdf"

import { Button, Col, Row } from "react-bootstrap"
import React, { Component } from "react"
import { farmActions, orderActions, usersActions } from "../../_actions"

import Logo from "../../assets/img/OFFICIAL LOGO TM WITH HEALTHY PLANEAT BLOCK.png"
import { Preview } from "react-html2pdf"
import Sidebar from "../../components/sidebar"
import _ from "lodash"
import { connect } from "react-redux"
import exportFromJSON from "export-from-json"
import moment from "moment-timezone"

class ViewOrder extends Component {
  constructor(props) {
    super(props)

    this.state = {
      orderInfo: [],
      ItemList: [],
      reId: [],
      ItemId: [],
      multiValue: [],
      multiItemValue: [],
      reasonErMsg: "",
      show: false,
      farmName: "",
      farmId: "",
      showInvoice: false,
      messagePopup: 0,
      alertPopup: 0,
      emailPopup: 0,
      lat: 0,
      long: "",
      count: 10,
      totalCount: 0,
      filterHarvest: "",
      pageNo: 1,
      orderInfo1: [],
      profilePicPreview: "",
      userId: localStorage.getItem("userId"),
    }
  }

  componentDidMount() {
    this.getUserInfo()
    this.getOrderList()
    this.getFarmDetails()
  }

  getFarmDetails = () => {
    let { userId } = this.state
    this.setState({ errors: "" })

    let self = this
    this.setState({ loader: true })

    let params = `?userId=${userId}&farmerId=${userId}`

    let { dispatch } = this.props
    dispatch(farmActions.getFarmDetails(params)).then((data) => {
      if (!_.isEmpty(data)) {
        let farmInfo = data.responseData.result
        self.setState({ loader: false })

        if (data.statusCode === 1) {
          self.setState({
            farmId: farmInfo[0]._id,
            profilePicPreview: farmInfo.length > 0 ? farmInfo[0].farmLogo : "",
            farmName: farmInfo.length > 0 ? farmInfo[0].farmName : "",
            addressLine1:
              farmInfo.length > 0 && !_.isEmpty(farmInfo[0].farmAddress)
                ? farmInfo[0].farmAddress[0].addressLine1
                : "",
            addressLine2:
              farmInfo.length > 0 && !_.isEmpty(farmInfo[0].farmAddress)
                ? farmInfo[0].farmAddress[0].addressLine2
                : "",
            city:
              farmInfo.length > 0 && !_.isEmpty(farmInfo[0].farmAddress)
                ? farmInfo[0].farmAddress[0].city
                : "",
            state:
              farmInfo.length > 0 && !_.isEmpty(farmInfo[0].farmAddress)
                ? farmInfo[0].farmAddress[0].state
                : "",
            postalCode:
              farmInfo.length > 0 && !_.isEmpty(farmInfo[0].farmAddress)
                ? farmInfo[0].farmAddress[0].postalCode
                : "",
          })
        } else {
          self.setState({ errors: data.error.responseMessage })
        }
      }
    })
  }

  printDocument = () => {
    let self = this

    let { userId } = this.state
    let params = `?&userId=${userId}&count=${500}`

    let { dispatch } = this.props
    dispatch(orderActions.getOrderList(params)).then((data) => {
      let inventoryData = data

      self.setState({ loader: false })

      if (inventoryData.statusCode === 1) {
        let inventoryInfo1 = data && data.responseData.OrderDetails.getOrder

        var doc = new jsPDF()

        if (inventoryInfo1) {
          doc.autoTable({ html: "#divToPrint1" })
          let table1 = inventoryInfo1.map((item, index) => [
            index + 1,
            self.ConvertDate(item.created),
            item.billingInfo.firstName + " " + item.billingInfo.lastName,
            "$" + item.amount,
            item.billingInfo.addressLine1 +
              item.billingInfo.addressLine2 +
              item.billingInfo.city +
              item.billingInfo.state +
              item.billingInfo.country +
              item.billingInfo.postalCode,
            item.harvestStatus === "1"
              ? "In Harvest List"
              : item.harvestStatus === "2"
              ? "Fulfilled"
              : item.harvestStatus === "3"
              ? "Out of Stock"
              : "New Order",
            item.description ? item.description : "",
          ])

          doc.autoTable({
            head: [
              [
                "Order",
                "Placed On",
                "Placed On",
                "Order Total",
                "Pick Up/Delivery Location",
                "Status",
              ],
            ],
            body: table1.length === 0 ? [["no data available "]] : table1,
          })
          setTimeout(() => doc.save("order.pdf"), 3000)
        }
      } else {
        this.setState({ errors: inventoryData.error.responseMessage })
      }
    })
  }

  modalPrint = () => {
    html2canvas(document.getElementById("divIdToPrint"), {
      useCORS: true,
    }).then(() => {
      html2canvas(document.getElementById("divIdToPrint"), {
        useCORS: true,
      }).then((canvas) => {
        const imgData = canvas.toDataURL("image/png")
        const pdf = new jsPDF()
        pdf.addImage(imgData, "JPEG", 10, 5, 240, 205)
        pdf.save("download.pdf")
      })
    })
  }

  printExcel = () => {
    let self = this

    let { userId } = this.state
    let params = `?&userId=${userId}&count=${500}`

    let { dispatch } = this.props
    dispatch(orderActions.getOrderList(params)).then((data) => {
      let inventoryData = data

      self.setState({ loader: false })

      if (inventoryData.statusCode === 1) {
        let inventoryInfo1 = data && data.responseData.OrderDetails.getOrder

        var doc = new jsPDF()

        if (inventoryInfo1) {
          if (inventoryInfo1) {
            let arr = []
            doc.autoTable({ html: "#divToPrint1" })
            inventoryInfo1.map((item, index) => {
              arr.push({
                Order: index + 1,
                "Placed On": self.ConvertDate(item.created),
                Customer:
                  item.billingInfo.firstName + " " + item.billingInfo.lastName,
                Total: "$" + item.amount,
                Location:
                  item.billingInfo.addressLine1 +
                  item.billingInfo.addressLine2 +
                  item.billingInfo.city +
                  item.billingInfo.state +
                  item.billingInfo.country +
                  item.billingInfo.postalCode,
                Status:
                  item.harvestStatus === "1"
                    ? "Fulfilled"
                    : item.harvestStatus === "2"
                    ? "In Harvest List"
                    : item.harvestStatus === "3"
                    ? "Out of Stock"
                    : "New Order",
              })
            })
            exportFromJSON({
              data: arr,
              fileName: "data",
              exportType: exportFromJSON.types.xls,
            })
          }
        }
      } else {
        this.setState({ errors: inventoryData.error.responseMessage })
      }
    })
  }

  sum = (product) => {
    if (!_.isEmpty(product)) {
      let countPrice = 0
      for (let i = 0; i < product.length; i++) {
        if (product[i].farmUserId === this.state.userId) {
          countPrice = product[i].totalPayPrice
        }
      }
      return countPrice.toFixed(2)
    }
  }

  sumOrder = (product) => {
    if (!_.isEmpty(product)) {
      let countPrice = 0
      for (let i = 0; i < product.length; i++) {
        if (product[i].farmUserId === this.state.userId) {
          countPrice = product[i].totalPrice
        }
      }

      return countPrice.toFixed(2)
    }
  }

  sumOrderAndDelivery = (product) => {
    if (!_.isEmpty(product)) {
      let countPrice = 0
      for (let i = 0; i < product.length; i++) {
        if (product[i].farmUserId === this.state.userId) {
          countPrice = product[i].totalPayPrice
        }
      }

      return countPrice.toFixed(2)
    }
  }

  sumDelivery = (product) => {
    if (!_.isEmpty(product)) {
      let countPrice = 0
      for (let i = 0; i < product.length; i++) {
        if (product[i].farmUserId === this.state.userId) {
          countPrice = product[i].deliveryFees
        }
      }

      return countPrice.toFixed(2)
    }
  }

  getUserInfo = () => {
    this.setState({ errors: "", redirect: false, loader: false })

    let { dispatch } = this.props
    dispatch(usersActions.getUserInfo()).then((data) => {
      this.setState({ loader: false })

      if (data.statusCode === 1) {
        let userInfo = data.responseData.userProfile
        localStorage.setItem("userId", userInfo._id)

        this.setState({
          userInfo: userInfo,
          firstName: userInfo.firstName,
          lastName: userInfo.lastName,
          phone: userInfo.phone,
          email: userInfo.email,
          address: userInfo.address,
          addressLine1:
            userInfo.address.length > 0
              ? userInfo.address[0].addressLine1
                ? userInfo.address[0].addressLine1
                : ""
              : "",
          addressLine2:
            userInfo.address.length > 0
              ? userInfo.address[0].addressLine2
                ? userInfo.address[0].addressLine2
                : ""
              : "",
          state:
            userInfo.address.length > 0
              ? userInfo.address[0].state
                ? userInfo.address[0].state
                : ""
              : "",
          postalCode:
            userInfo.address.length > 0
              ? userInfo.address[0].postalCode
                ? userInfo.address[0].postalCode
                : ""
              : "",
          city:
            userInfo.address.length > 0
              ? userInfo.address[0].city
                ? userInfo.address[0].city
                : ""
              : "",
          addressId:
            userInfo.address.length > 0
              ? userInfo.address[0]._id
                ? userInfo.address[0]._id
                : ""
              : "",
          minimumOrderValue: userInfo.minimumOrderValue,
          certificate: userInfo.certificateImage,
          certificatePreview: userInfo.certificateImage,
          insuranceDoc: userInfo.insuranceImage,
          insurancePreview: userInfo.insuranceImage,
          radius: userInfo.radius,
          emailPopup: userInfo.notificationEmail,
          messagePopup: userInfo.notificationTextMassage,
          alertPopup: userInfo.notificationAppAlert,
          lat: userInfo.geoLocation[0],
          long: userInfo.geoLocation[1],
        })
      } else {
        this.setState({ errors: data.error.responseMessage })
      }
    })
  }

  getOrderList = () => {
    this.setState({ errors: "" })
    let { userId } = this.state
    this.setState({ redirect: false, loader: true })
    let params = `?&userId=${userId ? userId : ""}&orderId=${
      this.props.match.params.id
    }`

    let { dispatch } = this.props
    dispatch(orderActions.viewOrderList(params)).then((data) => {
      this.setState({ loader: false })

      if (data.statusCode === 1) {
        let orderInfo = data.responseData.OrderDetails[0]

        this.setState({
          ItemList: orderInfo,
        })
      } else {
        this.setState({ errors: data.error.responseMessage })
      }
    })
  }

  ConvertDate = (created) => {
    const convertedDate = new Date(created)

    return moment(convertedDate)
      .tz("America/New_York")
      .format("dddd MMMM Do YYYY, h:mm:ss a")
  }

  handleShowInvoice = (item) => {
    this.setState({ showInvoice: true, ItemList: item })
  }

  OrderBuyMp = (ItemList) => {
    return (
      !_.isEmpty(ItemList) &&
      ItemList.map((val, i) => (
        <tr>
          <td>
            {val.productQuantity} {val.productName}
          </td>

          <td>
            <span>
              ${val.productPrice} *{" "}
              {val.productQuantity ? val.productQuantity : ""} {val.unit}
            </span>
          </td>
          <td>
            <span className="inlinetext-align">
              <span className="inabs">
                <input
                  type="checkbox"
                  indeterminate
                  value={val.productId}
                  onChange={(e) =>
                    this.handleItemMaltiSelct(
                      e,
                      val.productId && val.productId,
                      ItemList.buyLists,
                      ItemList._id
                    )
                  }
                />
              </span>
              <span className="cm-aligntext">
                {val.itemStatus === 1
                  ? "In Harvest List"
                  : val.itemStatus === 2
                  ? "Fulfilled"
                  : val.itemStatus === 3
                  ? "Out of Stock"
                  : "New Order"}
              </span>
            </span>
          </td>
          <td>
            {val.image && (
              <img alt="" src={val.image} height="40px" width="40px" />
            )}
          </td>
        </tr>
      ))
    )
  }

  OrderMp = (ItemList) => {
    return (
      !_.isEmpty(ItemList.buyLists) &&
      ItemList.buyLists.map((val, i) => <>{this.OrderBuyMp(val.productList)}</>)
    )
  }

  OrderBuyInvoice = (ItemList) => {
    return (
      ItemList &&
      ItemList.map((val, i) => (
        <tr>
          &nbsp;&nbsp;&nbsp;
          <td className="marGinOrderTwo">
            {" "}
            {val.productName +
              "" +
              (val.variety ? ", " + val.variety : "")} - {val.productQuantity}
          </td>
          <td>
            <span>
              ${val.productPrice} x {val.productQuantity} {val.unit}
            </span>
          </td>
          <td>
            <span>{val.address}</span>
          </td>
        </tr>
      ))
    )
  }

  OrderMpInvoice = (ItemList) => {
    return (
      ItemList.buyList &&
      ItemList.buyList
        .filter((val) => val.farmUserId === this.state.userId)
        .map((val, i) => <>{this.OrderBuyInvoice(val.productList)}</>)
    )
  }

  OrderHarvest1 = (ItemList) => {
    return (
      ItemList.buyLists &&
      ItemList.buyLists.map((val, i) => (
        <>
          {val.productName} <br />
        </>
      ))
    )
  }

  OrderHarvest2 = (ItemList) => {
    return (
      ItemList.buyLists &&
      ItemList.buyLists.map((val, i) => (
        <>
          {val.productQuantity ? val.productQuantity : ""} <br />
        </>
      ))
    )
  }

  OrderHarvest3 = (ItemList) => {
    return (
      ItemList.buyLists &&
      ItemList.buyLists.map((val, i) => (
        <>
          $ {val.productQuantity * val.productPrice}
          <br />
        </>
      ))
    )
  }

  getCourierTypeLocation = (ItemList) => {
    const { farmId } = this.state

    if (!ItemList.buyList || !ItemList.buyList.length || !farmId) {
      return null
    }

    const { buyList } = ItemList
    const currentFarmerBuyList = buyList.find((list) => list.farmId === farmId)

    if (!currentFarmerBuyList) {
      return null
    }

    const { courierType, address, day } = currentFarmerBuyList
    const location = courierType === "Delivery" ? `${address}, ${day}` : address

    return (
      <>
        <p>
          {courierType} Location: <span>{location}</span>
        </p>
      </>
    )
  }

  render() {
    let { ItemList, profilePicPreview } = this.state

    return (
      <>
        <Sidebar />
        <div className="body_container">
          <div className="body_inner_wrapper">
            {this.state.loader ? (
              <div className="loader_wrapper">
                <div class="spinner-border text-info" role="status"></div>
              </div>
            ) : (
              <>
                <div className="invoicewrapper">
                  <Preview id={"jsx-template"}>
                    <header className="header_content">
                      <p>Invoice</p>
                      <div className="d-flex text-nowrap header_btn">
                        <div className="print_button orderabs noabs">
                          <Button onClick={this.modalPrint} variant="light">
                            <i className="fas fa-print"></i> Print{" "}
                          </Button>
                        </div>
                      </div>
                    </header>
                    <div id={"divIdToPrint"} className="pdfwrapper">
                      <Row className="p-3">
                        <Col md="12">
                          <p className="text-center ">
                            {profilePicPreview && (
                              <img
                                alt=""
                                src={profilePicPreview}
                                height="160px"
                                className="cm-mr printlogo header"
                              />
                            )}
                          </p>

                          {ItemList.billingInfo &&
                            ItemList.billingInfo.firstName && (
                              <p>
                                Customer:{" "}
                                <span>
                                  {ItemList.billingInfo.firstName}{" "}
                                  {ItemList.billingInfo.lastName}
                                </span>
                              </p>
                            )}

                          {ItemList.billingInfo &&
                            ItemList.billingInfo.phoneNumber && (
                              <p>
                                Phone:{" "}
                                <span>{ItemList.billingInfo.phoneNumber} </span>
                              </p>
                            )}

                          {ItemList.billingInfo && (
                            <p>
                              Billing Address:{" "}
                              <span>
                                {ItemList.billingInfo.addressLine1}{" "}
                                {ItemList.billingInfo.addressLine2}{" "}
                                {ItemList.billingInfo.city}{" "}
                                {ItemList.billingInfo.state}{" "}
                                {ItemList.billingInfo.country}{" "}
                                {ItemList.billingInfo.postalCode}
                              </span>
                            </p>
                          )}

                          {
                            <p>
                              Placed On:
                              <span>{this.ConvertDate(ItemList.created)}</span>
                            </p>
                          }

                          {this.getCourierTypeLocation(ItemList)}
                        </Col>
                      </Row>
                      <div className="order_items p-3">
                        <div className="table-responsive">
                          <table className="table no-border">
                            <thead>
                              <th className="">Orders:</th>
                            </thead>
                            <tbody>{this.OrderMpInvoice(ItemList)}</tbody>
                          </table>
                        </div>
                      </div>
                      <div>
                        <br />
                        <br />

                        <table
                          cellPadding="8"
                          width="600"
                          className="tablecenter "
                        >
                          <tbody>
                            <tr>
                              <td>Order Total:</td>
                              <td></td>
                              <td>
                                <span>
                                  ${this.sumOrder(ItemList && ItemList.buyList)}
                                </span>
                              </td>
                            </tr>
                            <tr>
                              <td>Delivery Charges:</td>
                              <td></td>
                              <td>
                                <span>
                                  $
                                  {this.sumDelivery(
                                    ItemList && ItemList.buyList
                                  )}
                                </span>
                              </td>
                            </tr>
                            <tr>
                              <td>Total:</td>
                              <td></td>
                              <td>
                                <span>
                                  {" "}
                                  $
                                  {this.sumOrderAndDelivery(
                                    ItemList && ItemList.buyList
                                  )}
                                </span>
                              </td>
                            </tr>
                          </tbody>
                        </table>

                        <br />
                        <br />
                        <p className="text-center">
                          {profilePicPreview && (
                            <img
                              alt=""
                              src={Logo}
                              height="130px"
                              className="cm-mr printlogo"
                            />
                          )}
                        </p>
                      </div>
                    </div>

                    <br />
                    <br />
                  </Preview>
                </div>
                <div className="footer_btn_wrapper"></div>
              </>
            )}{" "}
          </div>
        </div>
      </>
    )
  }
}

function mapStateToProps(state) {
  const { props, login, user, mapReducer } = state
  return {
    props,
    login,
    user,
    mapReducer,
  }
}

export default connect(mapStateToProps)(ViewOrder)
