import { combineReducers } from "redux"
import { login, user } from "./users.reducer"
import { inventory } from "./inventory.reducer"
import { distribution } from "./distribution.reducer"
import { farm } from "./farm.reducer"
import {
  mapReducer,
  mapFarmReducer,
  mapPlaceReducer,
  mapPickReducer,
  mapDeliveryReducer,
} from "./map.reducer"

const rootReducer = combineReducers({
  login,
  user,
  inventory,
  farm,
  mapReducer,
  distribution,
  mapFarmReducer,
  mapPlaceReducer,
  mapPickReducer,
  mapDeliveryReducer,
})

export default rootReducer
