import React, { Component } from "react"
import { distributionActions } from "../../_actions"
import { DistributionForm } from "healthyplaneat-common"

import { Link } from "react-router-dom"
import Sidebar from "../../components/sidebar"
import _ from "lodash"
import { connect } from "react-redux"

import siteSetting from "../../config/env/index"

const debug = require("debug")("EditDistribution")

class AddDistribution extends Component {
  constructor(props) {
    super(props)

    this.state = {
      userId: localStorage.getItem("userId"),
      apiDomain: siteSetting.api.url,
    }
  }

  redirectToDistributionList() {
    this.props.history.push("/distribution")
  }

  handleSubmit({ distribution }) {
    let { dispatch } = this.props
    debug("handleSubmit")
    dispatch(distributionActions.addDistibution(distribution)).then((data) => {
      debug("result")
      debug(data)
      this.setState({ loader: false })
      if (data.statusCode === 1) {
        this.setState({ errors: "" })
        this.redirectToDistributionList()
      } else {
        this.setState({ errors: data.error.responseMessage })
      }
    })
  }

  render() {
    const { userId, apiDomain } = this.state

    return (
      <>
        <Sidebar />
        <div>
          <div className="header_wrapper">
            <div className="header_logo">
              <img src={require("../../assets/img/OFFICIAL LOGO.png")} />
            </div>
            <div className="header_search">
              <div className="form-group has-search"></div>
            </div>
            <div className="bell_icon">
              <i className="fas fa-bell"></i>
            </div>
          </div>
          <div className="body_container cmeditdistribution">
            <div className="body_inner_wrapper">
              <header className="header_content">
                <p>
                  <i className="fas fa-caret-left text_dark mr-2"></i>
                  <Link to="/distribution" className="text-dark">
                    Back
                  </Link>
                </p>
              </header>
              <div className="page_heading">
                <h6>Add Distribution</h6>
              </div>

              <div className="main_content adddistribution">
                <DistributionForm
                  userId={userId}
                  apiDomain={apiDomain}
                  handleSubmit={this.handleSubmit.bind(this)}
                  handleClose={() => {
                    this.redirectToDistributionList()
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
}

function mapStateToProps(state) {
  const {
    props,
    login,
    mapPickReducer,
    mapPlaceReducer,
    mapDeliveryReducer,
    user,
  } = state
  return {
    props,
    user,
    mapPickReducer,
    mapPlaceReducer,
    mapDeliveryReducer,
  }
}

export default connect(mapStateToProps)(AddDistribution)
