export const usersConstants = {
  SIGNUP_REQUEST: "SIGNUP_REQUEST",
  SIGNUP_SUCCESS: "SIGNUP_SUCCESS",
  SIGNUP_ERROR: "SIGNUP_ERROR",

  FORGOT_REQUEST: "FORGOT_REQUEST",
  FORGOT_SUCCESS: "FORGOT_SUCCESS",
  FORGOT_ERROR: "FORGOT_ERROR",

  RESET_REQUEST: "FORGOT_REQUEST",
  RESET_SUCCESS: "RESET_SUCCESS",
  RESET_ERROR: "RESET_ERROR",

  FORGOT_RESEND_OTP_REQUEST: "FORGOT_RESEND_OTP_REQUEST",
  FORGOT_RESEND_OTP_SUCCESS: "FORGOT_RESEND_OTP_SUCCESS",
  FORGOT_RESEND_OTP_ERROR: "FORGOT_RESEND_OTP_ERROR",

  LOGIN_REQUEST: "LOGIN_REQUEST",
  LOGIN_SUCCESS: "LOGIN_SUCCESS",
  LOGIN_ERROR: "LOGIN_ERROR",

  CANCEL_LABEL_REQUEST: "CANCEL_LABEL_REQUEST",
  CANCEL_LABEL_SUCCESS: "CANCEL_LABEL_SUCCESS",
  CANCEL_LABEL_ERROR: "CANCEL_LABEL_ERROR",

  USPS_TOKEN_REQUEST: "USPS_TOKEN_REQUEST",
  USPS_TOKEN_SUCCESS: "USPS_TOKEN_SUCCESS",
  USPS_TOKEN_ERROR: "USPS_TOKEN_ERROR",

  USPS_LOGGED_REQUEST: "USPS_LOGGED_REQUEST",
  USPS_LOGGED_SUCCESS: "USPS_LOGGED_SUCCESS",
  USPS_LOGGED_ERROR: "USPS_LOGGED_ERROR",

  GET_USER_INFO_REQUEST: "GET_USER_INFO_REQUEST",
  GET_USER_INFO_SUCCESS: "GET_USER_INFO_SUCCESS",
  GET_USER_INFO_ERROR: "GET_USER_INFO_ERROR",

  GET_BANK_REQUEST: "GET_BANK_REQUEST",
  GET_BANK_SUCCESS: "GET_BANK_SUCCESS",
  GET_BANK_ERROR: "GET_BANK_ERROR",

  LOGOUT_REQUEST: "LOGOUT_REQUEST",
  LOGOUT_SUCCESS: "LOGOUT_SUCCESS",
  LOGOUT_ERROR: "LOGOUT_ERROR",

  EDIT_PROFILE_REQUEST: "EDIT_PROFILE_REQUEST",
  EDIT_PROFILE_SUCCESS: "EDIT_PROFILE_SUCCESS",
  EDIT_PROFILE_ERROR: "EDIT_PROFILE_ERROR",

  ADD_ADDRESS_REQUEST: "ADD_ADDRESS_REQUEST",
  ADD_ADDRESS_SUCCESS: "ADD_ADDRESS_SUCCESS",
  ADD_ADDRESS_ERROR: "ADD_ADDRESS_ERROR",

  EDIT_ADDRESS_REQUEST: "EDIT_ADDRESS_REQUEST",
  EDIT_ADDRESS_SUCCESS: "EDIT_ADDRESS_SUCCESS",
  EDIT_ADDRESS_ERROR: "EDIT_ADDRESS_ERROR",

  UPLOAD_DOC_REQUEST: "UPLOAD_DOC_REQUEST",
  UPLOAD_DOC_SUCCESS: "UPLOAD_DOC_SUCCESS",
  UPLOAD_DOC_ERROR: "UPLOAD_DOC_ERROR",

  GET_INVENTORY_LIST_REQUEST: "GET_INVENTORY_LIST_REQUEST",
  GET_INVENTORY_LIST_SUCCESS: "GET_INVENTORY_LIST_SUCCESS",
  GET_INVENTORY_LIST_ERROR: "GET_INVENTORY_LIST_ERROR",

  GET_INV_LIST_REQUEST: "GET_INV_LIST_REQUEST",
  GET_INV_LIST_SUCCESS: "GET_INV_LIST_SUCCESS",
  GET_INV_LIST_ERROR: "GET_INV_LIST_ERROR",

  ADD_INVENTORY_LIST_REQUEST: "ADD_INVENTORY_LIST_REQUEST",
  ADD_INVENTORY_LIST_SUCCESS: "ADD_INVENTORY_LIST_SUCCESS",
  ADD_INVENTORY_LIST_ERROR: "ADD_INVENTORY_LIST_ERROR",

  EDIT_INVENTORY_ITEM_REQUEST: "EDIT_INVENTORY_ITEM_REQUEST",
  EDIT_INVENTORY_ITEM_SUCCESS: "EDIT_INVENTORY_ITEM_SUCCESS",
  EDIT_INVENTORY_ITEM_ERROR: "EDIT_INVENTORY_ITEM_ERROR",

  DELETE_INVENTORY_REQUEST: "DELETE_INVENTORY_REQUEST",
  DELETE_INVENTORY_SUCCESS: "DELETE_INVENTORY_SUCCESS",
  DELETE_INVENTORY_ERROR: "DELETE_INVENTORY_ERROR",

  CHANGE_INVENTORY_REQUEST: "CHANGE_INVENTORY_REQUEST",
  CHANGE_INVENTORY_SUCCESS: "CHANGE_INVENTORY_SUCCESS",
  CHANGE_INVENTORY_ERROR: "CHANGE_INVENTORY_ERROR",

  CHANGE_PASS_REQUEST: "CHANGE_PASS_REQUEST",
  CHANGE_PASS_SUCCESS: "CHANGE_PASS_SUCCESS",
  CHANGE_PASS_ERROR: "CHANGE_PASS_ERROR",

  AUTH_REQUEST: "AUTH_REQUEST",
  AUTH_SUCCESS: "AUTH_SUCCESS",
  AUTH_ERROR: "AUTH_ERROR",

  ADD_FARM_REQUEST: "ADD_FARM_REQUEST",
  ADD_FARM_SUCCESS: "ADD_FARM_SUCCESS",
  ADD_FARM_ERROR: "ADD_FARM_ERROR",

  ADD_ACCOUNT_REQUEST: "ADD_ACCOUNT_REQUEST",
  ADD_ACCOUNT_SUCCESS: "ADD_ACCOUNT_SUCCESS",
  ADD_ACCOUNT_ERROR: "ADD_ACCOUNT_ERROR",

  EDIT_FARM_REQUEST: "EDIT_FARM_REQUEST",
  EDIT_FARM_SUCCESS: "EDIT_FARM_SUCCESS",
  EDIT_FARM_ERROR: "EDIT_FARM_ERROR",

  GET_FARM_REQUEST: "GET_FARM_REQUEST",
  GET_FARM_SUCCESS: "GET_FARM_SUCCESS",
  GET_FARM_ERROR: "GET_FARM_ERROR",

  EDIT_MEDIA_REQUEST: "EDIT_MEDIA_REQUEST",
  EDIT_MEDIA_SUCCESS: "EDIT_MEDIA_SUCCESS",
  EDIT_MEDIA_ERROR: "EDIT_MEDIA_ERROR",

  DELETE_MEDIA_REQUEST: "DELETE_MEDIA_REQUEST",
  DELETE_MEDIA_SUCCESS: "DELETE_MEDIA_SUCCESS",
  DELETE_MEDIA_ERROR: "DELETE_MEDIA_ERROR",

  MAP_REQUEST: "MAP_REQUEST",
  MAP_SUCCESS: "MAP_SUCCESS",
  MAP_ERROR: "MAP_ERROR",

  MAP_FARM_REQUEST: "MAP_FARM_REQUEST",
  MAP_FARM_SUCCESS: "MAP_FARM_SUCCESS",
  MAP_FARM_ERROR: "MAP_FARM_ERROR",

  MAP_PLACE_REQUEST: "MAP_PLACE_REQUEST",
  MAP_PLACE_SUCCESS: "MAP_PLACE_SUCCESS",
  MAP_PLACE_ERROR: "MAP_PLACE_ERROR",

  MAP_PICK_REQUEST: "MAP_PICK_REQUEST",
  MAP_PICK_SUCCESS: "MAP_PICK_SUCCESS",
  MAP_PICK_ERROR: "MAP_PICK_ERROR",

  MAP_DELIVERY_REQUEST: "MAP_DELIVERY_REQUEST",
  MAP_DELIVERY_SUCCESS: "MAP_DELIVERY_SUCCESS",
  MAP_DELIVERY_ERROR: "MAP_DELIVERY_ERROR",

  VERIFY_ADDRESS_REQUEST: "VERIFY_ADDRESS_REQUEST",
  VERIFY_ADDRESS_SUCCESS: "VERIFY_ADDRESS_SUCCESS",
  VERIFY_ADDRESS_ERROR: "VERIFY_ADDRESS_ERROR",

  DOMESTIC_RATE_REQUEST: "DOMESTIC_RATE_REQUEST",
  DOMESTIC_RATE_SUCCESS: "DOMESTIC_RATE_SUCCESS",
  DOMESTIC_RATE_ERROR: "DOMESTIC_RATE_ERROR",

  DOMESTIC_PACKAGE_LABEL_REQUEST: "DOMESTIC_PACKAGE_LABEL_REQUEST",
  DOMESTIC_PACKAGE_LABEL_SUCCESS: "DOMESTIC_PACKAGE_LABEL_SUCCESS",
  DOMESTIC_PACKAGE_LABEL_ERROR: "DOMESTIC_PACKAGE_LABEL_ERROR",

  TRACK_DOMESTIC_PACKAGE_REQUEST: "TRACK_DOMESTIC_PACKAGE_REQUEST",
  TRACK_DOMESTIC_PACKAGE_SUCCESS: "TRACK_DOMESTIC_PACKAGE_SUCCESS",
  TRACK_DOMESTIC_PACKAGE_ERROR: "TRACK_DOMESTIC_PACKAGE_ERROR",
}

export const USER_TYPE = {
  FARMER: 1,
  CUSTOMER: 2,
  WHOLESALER: 3,
}
