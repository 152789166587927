import { Button, Modal, Nav } from "react-bootstrap"

import { NavLink } from "react-router-dom"
import React from "react"
import _ from "lodash"
import { connect } from "react-redux"
import { usersActions } from "../_actions"
import siteSetting from "../config/env/index"

const isFarmer = siteSetting.isFarmer

class Sidebar extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      loader: false,
      activestrat: false,
      activeuser: true,
      logout: false,
      profilePicPreview: "",
      fisrtName: "",
      lastName: "",
    }
  }

  UNSAFE_componentWillReceiveProps(props) {
    let self = this
    if (props !== undefined) {
      if (!_.isEmpty(props.user.userInfo)) {
        if (props.user.userInfo.responseData) {
          let data = props.user.userInfo.responseData.userProfile
          self.setState({
            profilePicPreview: data.profileImage ? data.profileImage : "",
            profilePic: data.profileImage ? data.profileImage : "",
            firstName: data.firstName ? data.firstName : "",
            lastName: data.lastName ? data.lastName : "",
          })
        }
      }
    }
  }

  componentDidMount() {
    this.getUserInfo()
  }

  getUserInfo = () => {
    this.setState({ errors: "" })

    let self = this
    this.setState({ redirect: false, loader: true })

    let { dispatch } = this.props
    dispatch(usersActions.getUserInfo()).then((data) => {
      this.setState({ loader: false })

      if (data.statusCode == 1) {
        let userInfo =
          this.props && this.props.user.userInfo.responseData.userProfile
            ? this.props.user.userInfo.responseData.userProfile
            : ""
        if (userInfo) {
          localStorage.setItem("userId", userInfo._id)

          this.setState({
            userInfo: userInfo,

            firstName: userInfo.firstName,
            lastName: userInfo.lastName,
            phone: userInfo.phone,
            email: userInfo.email,
            address: userInfo.address,
            addressLine1:
              userInfo.address.length > 0
                ? userInfo.address[0].addressLine1
                  ? userInfo.address[0].addressLine1
                  : ""
                : "",
            addressLine2:
              userInfo.address.length > 0
                ? userInfo.address[0].addressLine2
                  ? userInfo.address[0].addressLine2
                  : ""
                : "",
            state:
              userInfo.address.length > 0
                ? userInfo.address[0].state
                  ? userInfo.address[0].state
                  : ""
                : "",
            postalCode:
              userInfo.address.length > 0
                ? userInfo.address[0].postalCode
                  ? userInfo.address[0].postalCode
                  : ""
                : "",
            city:
              userInfo.address.length > 0
                ? userInfo.address[0].city
                  ? userInfo.address[0].city
                  : ""
                : "",
            addressId:
              userInfo.address.length > 0
                ? userInfo.address[0]._id
                  ? userInfo.address[0]._id
                  : ""
                : "",
            minimumOrderValue: userInfo.minimumOrderValue,
            profilePic: userInfo.profileImage,
            profilePicPreview: userInfo.profileImage,
            certificate: userInfo.certificateImage,
            certificatePreview: userInfo.certificateImage,
            insuranceDoc: userInfo.insuranceImage,
            insurancePreview: userInfo.insuranceImage,
            radius: userInfo.radius,
            emailPopup: userInfo.notificationEmail,
            messagePopup: userInfo.notificationTextMassage,
            alertPopup: userInfo.notificationAppAlert,
            lat: userInfo.geoLocation[0],
            long: userInfo.geoLocation[1],
          })
        }
      } else {
        this.setState({ errors: data.error.responseMessage })
      }
    })
  }

  handleLogOut = () => {
    this.setState({ logoutdeletePopup: true })

    localStorage.removeItem("accessToken")
    localStorage.removeItem("userId")
    window.location.href = "/"
  }

  Closelogout = () => {
    this.setState({ logout: false })
  }

  openPopup = () => {
    this.setState({ logout: true })
  }

  render() {
    let { logout, profilePicPreview, firstName, lastName } = this.state

    return (
      <>
        <div className="sidebar farmer-host-sidebar">
          {/* <div className="toggle_icon_menu">
                    <button className="btn" type="button" ><i className="fas fa-bars"></i></button>
                </div> */}
          <div className="logo">
            {/*{profilePicPreview?profilePicPreview:  <img src={require('../assets/img/adminlogo.png')}/>}*/}
            {profilePicPreview && profilePicPreview ? (
              <img className="rounded-circle" src={profilePicPreview} alt="" />
            ) : (
              <span className="profile_icon_Admin ">
                <img src={require("../assets/img/OFFICIAL LOGO.png")} alt="" />
              </span>
            )}

            <p>
              {firstName} {lastName}
            </p>
          </div>

          <div className="sidebar_menu">
            {isFarmer && (
              <Nav.Item>
                <NavLink to="/order">
                  <div className="sidebar-item-icon">
                    <img
                      src={require("../assets/img/orders.png")}
                      alt="orders"
                    />
                  </div>
                  Orders
                </NavLink>
              </Nav.Item>
            )}
            {isFarmer && (
              <Nav.Item>
                <NavLink to="/distribution">
                  <div className="sidebar-item-icon">
                    <img
                      src={require("../assets/img/distribution.png")}
                      alt="distributions"
                    />
                  </div>
                  Distribution
                </NavLink>
              </Nav.Item>
            )}
            {isFarmer && (
              <Nav.Item>
                <NavLink to="/farm">
                  <div className="sidebar-item-icon">
                    <img
                      src={require("../assets/img/farm.png")}
                      alt="farm info"
                    />
                  </div>
                  Farm
                </NavLink>
              </Nav.Item>
            )}
            {isFarmer && (
              <Nav.Item>
                <NavLink to="/inventory">
                  <div className="sidebar-item-icon">
                    <img
                      src={require("../assets/img/inventory.png")}
                      alt="inventory"
                    />
                  </div>
                  Inventory
                </NavLink>
              </Nav.Item>
            )}
            <Nav.Item>
              <NavLink to="/pickups">
                <div className="sidebar-item-icon">
                  <img
                    src={require("../assets/img/pickups.png")}
                    alt="pick up host"
                  />
                </div>
                Pick Up Host
              </NavLink>
            </Nav.Item>
            <Nav.Item>
              <NavLink to="/settings">
                <div className="sidebar-item-icon">
                  <img
                    src={require("../assets/img/settings.png")}
                    alt="settings"
                  />
                </div>
                Settings
              </NavLink>
            </Nav.Item>
          </div>
          <div className="sidebar_button">
            <Button onClick={this.openPopup} variant="info">
              Logout
            </Button>
          </div>
        </div>
        <Modal
          show={logout}
          onHide={this.Closelogout}
          className="cm_order_view dlt_item"
        >
          <Modal.Header closeButton>
            {/*<Modal.Title></Modal.Title>*/}
          </Modal.Header>
          <Modal.Body>
            {/*<img src={require('../../../assets/img/Delete-Email.png')}/>*/}

            <p>Are you sure you want to logout?</p>
          </Modal.Body>
          <Modal.Footer>
            <div className="footer_btn_wrapper">
              <Button variant="warning" onClick={this.handleLogOut}>
                Yes
              </Button>
              {/*<span><i onClick={(e)=>this.handleDelete(e,item)} class="fas fa-trash-alt"></i></span>*/}

              <Button variant="dark" onClick={this.Closelogout}>
                No
              </Button>
            </div>
          </Modal.Footer>
        </Modal>
      </>
    )
  }
}

// export default

function mapStateToProps(state) {
  const { props, login, user, mapReducer } = state
  return {
    props,
    login,
    user,
    mapReducer,
  }
}

export default connect(mapStateToProps)(Sidebar)
