import { usersConstants } from "../_constants"

const initialState = {}

export function farm(state = initialState, action) {
  switch (action.type) {
    case usersConstants.ADD_FARM_SUCCESS:
      let addfarmSuccessInfo = action.addfarmSuccess
      return addfarmSuccessInfo

    case usersConstants.GET_FARM_SUCCESS:
      let getFarmDetailsSuccessInfo = action.getFarmDetailsSuccess
      state.getFarmDetailsSuccessInfo = getFarmDetailsSuccessInfo
      return { ...state }

    case usersConstants.EDIT_FARM_SUCCESS:
      let editfarmSuccessInfo = action.editfarmSuccess
      return editfarmSuccessInfo

    case usersConstants.EDIT_MEDIA_SUCCESS:
      let editMediaSuccessInfo = action.editMediaSuccess
      return editMediaSuccessInfo

    case usersConstants.DELETE_MEDIA_SUCCESS:
      let deleteMediaSuccessInfo = action.deleteMediaSuccess
      return deleteMediaSuccessInfo

    default:
      return { ...state }
  }
}
