import React, { Component } from "react"
import { distributionActions } from "../../_actions"
import { DistributionForm } from "healthyplaneat-common"

import { Link } from "react-router-dom"
import Sidebar from "../../components/sidebar"
import { isEmpty } from "lodash"
import { connect } from "react-redux"

import siteSetting from "../../config/env/index"

const debug = require("debug")("EditDistributionDetails")

class EditDistribution extends Component {
  constructor(props) {
    super(props)

    this.state = {
      distribution: undefined,
      userId: localStorage.getItem("userId"),
      apiDomain: siteSetting.api.url,
    }
  }

  componentDidMount() {
    this.getDistibutionDetailsById()
  }

  redirectToDistributionList() {
    this.props.history.push("/distribution")
  }

  handleSubmit({ distribution }) {
    let { dispatch } = this.props
    dispatch(distributionActions.editDistibution(distribution)).then((data) => {
      this.setState({ loader: false })
      if (data.statusCode === 1) {
        this.setState({ errors: "" })
        this.redirectToDistributionList()
      } else {
        this.setState({ errors: data.error.responseMessage })
      }
    })
  }

  getDistibutionDetailsById() {
    let { userId } = this.state
    this.setState({ errors: "" })

    let self = this
    this.setState({ loader: true })

    let params = `?userId=${userId}&distributionId=${this.props.match.params.id}`

    let { dispatch } = this.props
    dispatch(distributionActions.getDistibutionDetailsById(params)).then(
      (data) => {
        self.setState({ loader: false })
        if (!isEmpty(data)) {
          if (data.statusCode === 1) {
            const distribution = data.responseData.result
            self.setState({ distribution })
          } else {
            self.setState({ errors: data.error.responseMessage })
          }
        }
      }
    )
  }

  render() {
    const { distribution, userId, apiDomain } = this.state

    return (
      <>
        <Sidebar />
        <div>
          <div className="header_wrapper">
            <div className="header_logo">
              <img src={require("../../assets/img/OFFICIAL LOGO.png")} />
            </div>
            <div className="header_search">
              <div className="form-group has-search"></div>
            </div>
            <div className="bell_icon">
              <i className="fas fa-bell"></i>
            </div>
          </div>
          <div className="body_container cmeditdistribution">
            <div className="body_inner_wrapper">
              <header className="header_content">
                <p>
                  <i className="fas fa-caret-left text_dark mr-2"></i>
                  <Link to="/distribution" className="text-dark">
                    Back
                  </Link>
                </p>
              </header>
              <div className="page_heading">
                <h6>Edit Distribution</h6>
              </div>

              <div className="main_content adddistribution">
                {distribution && (
                  <DistributionForm
                    distribution={distribution}
                    userId={userId}
                    apiDomain={apiDomain}
                    handleSubmit={this.handleSubmit.bind(this)}
                    handleClose={() => {
                      this.redirectToDistributionList()
                    }}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
}

function mapStateToProps(state) {
  const {
    props,
    login,
    mapPickReducer,
    mapPlaceReducer,
    mapDeliveryReducer,
    user,
  } = state
  return {
    props,
    user,
    mapPickReducer,
    mapPlaceReducer,
    mapDeliveryReducer,
  }
}

export default connect(mapStateToProps)(EditDistribution)
