import { isEmpty } from "lodash"
import { DISTRIBUTION_TYPE } from "./distribution"
const debug = require("debug")("util-order")

const isFarmerBuyList =
  ({ farmUserId }) =>
  (buyListEntry) => {
    return buyListEntry.farmUserId === farmUserId
  }

const hasNonEmptyProductList = (buyListEntry) => {
  return buyListEntry.productList.length > 0
}

export const distributionStringForShippedProducts = ({ shippedBuyList }) => {
  return `Shipping: ${shippedBuyList[0].productList[0].distributionAddress}`
}

const nonShippedCourierType = ({ nonShippedBuyList }) => {
  return nonShippedBuyList[0].courierType
}

export const distributionStringForNonShippedProducts = ({
  nonShippedBuyList,
}) => {
  return `${nonShippedCourierType({ nonShippedBuyList })}: ${
    nonShippedBuyList[0].address
  }`
}

export const productListEntryIsShipped = (productListEntry) => {
  return productListEntry.distributionType === DISTRIBUTION_TYPE.SHIPPING
}

export const productListEntryIsNotShipped = (productListEntry) => {
  return productListEntry.distributionType !== DISTRIBUTION_TYPE.SHIPPING
}

export const orderShippedBuyList = ({ order, farmUserId }) => {
  if (isEmpty(order)) return []

  return order.buyList
    .filter(isFarmerBuyList({ farmUserId }))
    .map((buyListEntry) => {
      const shippedProductList = buyListEntry.productList.filter(
        productListEntryIsShipped
      )
      return {
        ...buyListEntry,
        productList: shippedProductList,
      }
    })
    .filter(hasNonEmptyProductList)
}

export const orderNonShippedBuyList = ({ order, farmUserId }) => {
  if (isEmpty(order)) return []

  return order.buyList
    .filter(isFarmerBuyList({ farmUserId }))
    .map((buyListEntry) => {
      const nonShippedProductList = buyListEntry.productList.filter(
        productListEntryIsNotShipped
      )
      return {
        ...buyListEntry,
        productList: nonShippedProductList,
      }
    })
    .filter(hasNonEmptyProductList)
}

export const orderDistributions = ({ order, farmUserId }) => {
  const distributions = []
  const shippedBuyList = orderShippedBuyList({ order, farmUserId })
  if (shippedBuyList.length > 0) {
    // all should have the same address for the order
    distributions.push(distributionStringForShippedProducts({ shippedBuyList }))
  }
  const nonShippedBuyList = orderNonShippedBuyList({ order, farmUserId })
  if (nonShippedBuyList.length > 0) {
    distributions.push(
      distributionStringForNonShippedProducts({ nonShippedBuyList })
    )
  }
  return distributions
}

export const getDeliveryAddressFromOrder = (order, farmUserId) => {
  const buyList = order.buyList.filter(isFarmerBuyList({ farmUserId }))

  for (const buyListEntry of buyList) {
    const { addressLine1, addressLine2, city, state, postalCode } = buyListEntry
    if (addressLine1 && city && state && postalCode) {
      const address = {
        addressLine1: addressLine1.trim(),
        addressLine2: addressLine2 ? addressLine2.trim() : "",
        city: city.trim(),
        state: state.trim(),
        postalCode: postalCode.trim(),
      }
      return address
    }
  }

  return {
    addressLine1: "",
    addressLine2: "",
    city: "",
    state: "",
    postalCode: "",
  }
}

export const orderDetailsTable = ({ order, farmUserId }) => {
  if (isEmpty(order)) return []
  const items = []
  const shippedBuyList = orderShippedBuyList({ order, farmUserId })
  const nonShippedBuyList = orderNonShippedBuyList({ order, farmUserId })
  order.buyList.forEach((buyListEntry) => {
    buyListEntry.productList.forEach((productListEntry) => {
      const tableRowValues = {
        productName: `${productListEntry.productQuantity} ${productListEntry.productName}`,
        variety: productListEntry.variety,
        price: `$${productListEntry.productPrice} * ${
          productListEntry.productQuantity
            ? productListEntry.productQuantity
            : ""
        } ${productListEntry.unit}`,
        distributionString: productListEntryIsShipped(productListEntry)
          ? distributionStringForShippedProducts({ shippedBuyList })
          : distributionStringForNonShippedProducts({ nonShippedBuyList }),
        image: productListEntry.image,
      }
      items.push({ tableRowValues, productListEntry })
    })
  })
  items.sort((a, b) => {
    const aDistributionString = a.tableRowValues.distributionString
    const bDistributionString = b.tableRowValues.distributionString
    if (aDistributionString > bDistributionString) return -1
    if (aDistributionString < bDistributionString) return 1
    return 0
  })
  return items
}

export const orderTimeString = ({ order, farmUserId }) => {
  let timeString = order.time
  const nonShippedBuyList = orderNonShippedBuyList({ order, farmUserId })
  if (nonShippedBuyList.length > 0) {
    timeString = `${nonShippedCourierType({
      nonShippedBuyList,
    })}: ${timeString}`
  }
  return timeString
}
