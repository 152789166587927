import { distributionConstants } from "../_constants"

const initialState = {}

export function distribution(state = initialState, action) {
  switch (action.type) {
    case distributionConstants.GET_DISTRIBUTION_SUCCESS:
      let getDistributionSuccessInfo = action.getDistributionSuccess
      return getDistributionSuccessInfo
    case distributionConstants.GET_DISTRIBUTION_BY_ID_SUCCESS:
      let getDistibutionDetailsByIdSuccessInfo =
        action.getDistibutionDetailsByIdSuccess
      return getDistibutionDetailsByIdSuccessInfo

    case distributionConstants.GET_MAR_LIST_SUCCESS:
      let getMarListSuccessInfo = action.getMarListSuccess
      return getMarListSuccessInfo

    case distributionConstants.ADD_DISTRIBUTION_SUCCESS:
      let addDistributionSuccessInfo = action.addDistributionSuccess
      return addDistributionSuccessInfo

    case distributionConstants.EDIT_DISTRIBUTION_SUCCESS:
      let editDistributionSuccessInfo = action.editDistributionSuccess
      return editDistributionSuccessInfo

    case distributionConstants.DELETE_DISTRIBUTION_SUCCESS:
      let deleteDistributionSuccessInfo = action.deleteDistributionSuccess
      return deleteDistributionSuccessInfo

    default:
      return state
  }
}
