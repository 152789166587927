import React, { Component } from "react"
import {
  Redirect,
  Route,
  BrowserRouter as Router,
  Switch,
} from "react-router-dom"

import siteSetting from "./config/env/index"

import AccountToken from "./views/settings/accountToken"
import Distribution from "./views/distribution/distribution"
import EditDistribution from "./views/distribution/EditDistribution"
import AddDistribution from "./views/distribution/AddDistribution"
import Farm from "./views/farm/farm"
import FarmEdit from "./views/farm/farmEdit"
import ForgotPassword from "./views/changeAuth/ForgotPassword"
import Inventory from "./views/inventory/Inventory"
import Order from "./views/order/order"
import Order1 from "./views/inventory/dsfv"
import Page404 from "./views/page404"
import ResetPassword from "./views/changeAuth/ResetPassword"
import Settings from "./views/settings/settings"
import Successfullyreset from "./views/changeAuth/successfullyreset"
import ViewOrder from "./views/order/viewOrder"
import ViewOrderDetails from "./views/order/viewOrderDetails"
import axios from "axios"
import { connect } from "react-redux"
import { createBrowserHistory } from "history"
import login from "./auth/login"
import printInv from "./views/inventory/printInv"
import PickUpHost from "./views/pickUpHost/pickUpHost"

export const history = createBrowserHistory()

//preety
const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      localStorage.getItem("accessToken") ? (
        <Component {...props} />
      ) : (
        <Redirect to={{ pathname: "/", state: { from: props.location } }} />
      )
    }
  />
)

const PrivateOuterRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) => {
      const accessToken = localStorage.getItem("accessToken")
      if (accessToken && siteSetting.isFarmer) {
        return (
          <Redirect
            to={{ pathname: "/order", state: { from: props.location } }}
          />
        )
      } else if (accessToken && siteSetting.isHost) {
        return (
          <Redirect
            to={{ pathname: "/pickups", state: { from: props.location } }}
          />
        )
      } else {
        return <Component {...props} />
      }
    }}
  />
)

class Routes extends Component {
  constructor(props) {
    super(props)
    const { dispatch } = this.props
    history.listen((location, action) => {})
  }

  render() {
    let accessToken = localStorage.getItem("accessToken")
    if (accessToken) {
      axios.defaults.headers.common["accessToken"] = `${accessToken}`
    }
    axios.defaults.headers.common[
      "Authorization"
    ] = `${"Basic aGVhbHRoeVBsYW5ldF9hZG1pbl92WnBHaDQ6YWRtaW5AaGVhbHRoeVBsYW5ldF9GVGtWcFE="}`

    return (
      <>
        <Router history={history}>
          <Switch>
            <PrivateOuterRoute exact path="/" component={login} />
            <PrivateOuterRoute
              exact
              path="/forgot"
              component={ForgotPassword}
            />
            <PrivateOuterRoute
              exact
              path="/resetPassword/:id"
              component={ResetPassword}
            />
            <PrivateRoute path="/order" component={Order} />
            <PrivateRoute path="/vieworder/:id" component={ViewOrder} />
            <PrivateRoute
              path="/vieworderdetails/:id"
              component={ViewOrderDetails}
            />
            <PrivateRoute
              path="/account-oauth-token"
              component={AccountToken}
            />
            <PrivateRoute exact={true} path="/farm" component={Farm} />
            <PrivateRoute path="/farm/:id" component={FarmEdit} />
            <PrivateRoute
              path="/distribution/edit/:id"
              component={EditDistribution}
            />
            <PrivateRoute exact path="/distribution" component={Distribution} />
            <PrivateRoute
              exact
              path="/distribution/add"
              component={AddDistribution}
            />
            <PrivateRoute path="/inventory" component={Inventory} />
            <PrivateRoute path="/invenPrint" component={printInv} />
            <PrivateRoute path="/ord" component={Order1} />
            <PrivateRoute path="/settings" component={Settings} />
            <PrivateRoute path="/pickups" component={PickUpHost} />
            <PrivateOuterRoute
              path="/successfullyreset"
              component={Successfullyreset}
            />

            <Route exact component={Page404} />
            <Redirect exact from="*" to="/404" />
          </Switch>
        </Router>
      </>
    )
  }
}

function mapStateToProps(state) {
  const { alert } = state
  return {
    alert,
  }
}

export default connect(mapStateToProps)(Routes)
