import { ResponseFilter } from "../config/response-handler"
import { orderConstants } from "../_constants"
import { orderService } from "../_services"
import promise from "bluebird"

export const orderActions = {
  getOrderList,
  viewOrderList,
  getOrderListByFarmer,
  changeHarvestStatus,
  changeHarvestStatusByFarmer,
  changeOrderStatus,
  changeOrderStatusByFarmer,
  getOrderExcel,
  getAllOrderExcel,
  changeOrderItemStatus,
}

function getOrderList(params) {
  return function (dispatch) {
    return new promise(function (resolve, reject) {
      dispatch(request(params))
      orderService
        .getOrderList(params)
        .then((response) => {
          let { serverResponseData } = ResponseFilter(response)
          resolve(serverResponseData)
          dispatch(success(serverResponseData))
        })
        .catch((err) => {
          reject(err)
          dispatch(failure(err))
        })
    })
  }

  function request(getOrderRequest) {
    return { type: orderConstants.GET_ORDER_REQUEST, getOrderRequest }
  }

  function success(getOrderSuccess) {
    return { type: orderConstants.GET_ORDER_SUCCESS, getOrderSuccess }
  }

  function failure(getOrderFail) {
    return { type: orderConstants.GET_ORDER_ERROR, getOrderFail }
  }
}

// Refactor to replace /getFarmerOrder route (getOrderList function)
function getOrderListByFarmer(params) {
  return function (dispatch) {
    return new promise(function (resolve, reject) {
      dispatch(request(params))
      orderService
        .getOrderListByFarmer(params)
        .then((response) => {
          let { serverResponseData } = ResponseFilter(response)
          resolve(serverResponseData)
          dispatch(success(serverResponseData))
        })
        .catch((err) => {
          reject(err)
          dispatch(failure(err))
        })
    })
  }

  function request(getOrderRequest) {
    return { type: orderConstants.GET_ORDER_REQUEST, getOrderRequest }
  }

  function success(getOrderSuccess) {
    return { type: orderConstants.GET_ORDER_SUCCESS, getOrderSuccess }
  }

  function failure(getOrderFail) {
    return { type: orderConstants.GET_ORDER_ERROR, getOrderFail }
  }
}

function getOrderExcel(params) {
  return function (dispatch) {
    return new promise(function (resolve, reject) {
      dispatch(request(params))
      orderService
        .getOrderExcel(params)
        .then((response) => {
          let { serverResponseData } = ResponseFilter(response)
          resolve(serverResponseData)
          dispatch(success(serverResponseData))
        })
        .catch((err) => {
          reject(err)
          dispatch(failure(err))
        })
    })
  }
  function request(getOrderExcelRequest) {
    return {
      type: orderConstants.GET_ORDER_EXCEL_REQUEST,
      getOrderExcelRequest,
    }
  }
  function success(getOrderExcelSuccess) {
    return {
      type: orderConstants.GET_ORDER_EXCEL_SUCCESS,
      getOrderExcelSuccess,
    }
  }
  function failure(getOrderExcelFail) {
    return { type: orderConstants.GET_ORDER_EXCEL_ERROR, getOrderExcelFail }
  }
}

function getAllOrderExcel(params) {
  return function (dispatch) {
    return new promise(function (resolve, reject) {
      dispatch(request(params))
      orderService
        .getAllOrderExcel(params)
        .then((response) => {
          let { serverResponseData } = ResponseFilter(response)
          resolve(serverResponseData)
          dispatch(success(serverResponseData))
        })
        .catch((err) => {
          reject(err)
          dispatch(failure(err))
        })
    })
  }
  function request(getAllOrderExcelRequest) {
    return {
      type: orderConstants.GET_ALL_ORDER_EXCEL_REQUEST,
      getAllOrderExcelRequest,
    }
  }
  function success(getAllOrderExcelSuccess) {
    return {
      type: orderConstants.GET_ALL_ORDER_EXCEL_SUCCESS,
      getAllOrderExcelSuccess,
    }
  }
  function failure(getAllOrderExcelFail) {
    return {
      type: orderConstants.GET_ALL_ORDER_EXCEL_ERROR,
      getAllOrderExcelFail,
    }
  }
}

function viewOrderList(params) {
  return function (dispatch) {
    return new promise(function (resolve, reject) {
      dispatch(request(params))
      orderService
        .viewOrderList(params)
        .then((response) => {
          let { serverResponseData } = ResponseFilter(response)
          resolve(serverResponseData)
          dispatch(success(serverResponseData))
        })
        .catch((err) => {
          reject(err)
          dispatch(failure(err))
        })
    })
  }
  function request(viewOrderRequest) {
    return { type: orderConstants.VIEW_ORDER_REQUEST, viewOrderRequest }
  }
  function success(viewOrderSuccess) {
    return { type: orderConstants.VIEW_ORDER_SUCCESS, viewOrderSuccess }
  }
  function failure(viewOrderFail) {
    return { type: orderConstants.VIEW_ORDER_ERROR, viewOrderFail }
  }
}

function changeHarvestStatus(params) {
  return function (dispatch) {
    return new promise(function (resolve, reject) {
      dispatch(request(params))
      orderService
        .changeHarvestStatus(params)
        .then((response) => {
          let { serverResponseData } = ResponseFilter(response)
          resolve(serverResponseData)
          dispatch(success(serverResponseData))
        })
        .catch((err) => {
          reject(err)
          dispatch(failure(err))
        })
    })
  }

  function request(changeHarvestStatusRequest) {
    return {
      type: orderConstants.CHANGE_HARVEST_REQUEST,
      changeHarvestStatusRequest,
    }
  }
  function success(changeHarvestStatusSuccess) {
    return {
      type: orderConstants.CHANGE_HARVEST_SUCCESS,
      changeHarvestStatusSuccess,
    }
  }
  function failure(changeHarvestStatusFail) {
    return {
      type: orderConstants.CHANGE_HARVEST_ERROR,
      changeHarvestStatusFail,
    }
  }
}

function changeHarvestStatusByFarmer(params) {
  return function (dispatch) {
    return new promise(function (resolve, reject) {
      dispatch(request(params))
      orderService
        .changeHarvestStatusByFarmer(params)
        .then((response) => {
          let { serverResponseData } = ResponseFilter(response)
          resolve(serverResponseData)
          dispatch(success(serverResponseData))
        })
        .catch((err) => {
          reject(err)
          dispatch(failure(err))
        })
    })
  }

  function request(changeHarvestStatusByFarmerRequest) {
    return {
      type: orderConstants.CHANGE_HARVEST_BY_FARMER_REQUEST,
      changeHarvestStatusByFarmerRequest,
    }
  }
  function success(changeHarvestStatusByFarmerSuccess) {
    return {
      type: orderConstants.CHANGE_HARVEST_BY_FARMER_SUCCESS,
      changeHarvestStatusByFarmerSuccess,
    }
  }
  function failure(changeHarvestStatusByFarmerFail) {
    return {
      type: orderConstants.CHANGE_HARVEST_BY_FARMER_ERROR,
      changeHarvestStatusByFarmerFail,
    }
  }
}

function changeOrderStatus(params) {
  return function (dispatch) {
    return new promise(function (resolve, reject) {
      dispatch(request(params))
      orderService
        .changeOrderStatus(params)
        .then((response) => {
          let { serverResponseData } = ResponseFilter(response)
          resolve(serverResponseData)
          dispatch(success(serverResponseData))
        })
        .catch((err) => {
          reject(err)
          dispatch(failure(err))
        })
    })
  }

  function request(changeOrderStatusRequest) {
    return {
      type: orderConstants.CHANGE_ORDER_REQUEST,
      changeOrderStatusRequest,
    }
  }
  function success(changeOrderStatusSuccess) {
    return {
      type: orderConstants.CHANGE_ORDER_SUCCESS,
      changeOrderStatusSuccess,
    }
  }
  function failure(changeOrderStatusFail) {
    return { type: orderConstants.CHANGE_ORDER_ERROR, changeOrderStatusFail }
  }
}

function changeOrderStatusByFarmer(params) {
  return function (dispatch) {
    return new promise(function (resolve, reject) {
      dispatch(request(params))
      orderService
        .changeOrderStatusByFarmer(params)
        .then((response) => {
          let { serverResponseData } = ResponseFilter(response)
          resolve(serverResponseData)
          dispatch(success(serverResponseData))
        })
        .catch((err) => {
          reject(err)
          dispatch(failure(err))
        })
    })
  }

  function request(changeOrderStatusByFarmerRequest) {
    return {
      type: orderConstants.CHANGE_ORDER_BY_FARMER_REQUEST,
      changeOrderStatusByFarmerRequest,
    }
  }
  function success(changeOrderStatusByFarmerSuccess) {
    return {
      type: orderConstants.CHANGE_ORDER_BY_FARMER_SUCCESS,
      changeOrderStatusByFarmerSuccess,
    }
  }
  function failure(changeOrderStatusByFarmerFail) {
    return {
      type: orderConstants.CHANGE_ORDER_BY_FARMER_ERROR,
      changeOrderStatusByFarmerFail,
    }
  }
}

function changeOrderItemStatus(params) {
  return function (dispatch) {
    return new promise(function (resolve, reject) {
      dispatch(request(params))
      orderService
        .changeOrderItemStatus(params)
        .then((response) => {
          let { serverResponseData } = ResponseFilter(response)
          resolve(serverResponseData)
          dispatch(success(serverResponseData))
        })
        .catch((err) => {
          reject(err)
          dispatch(failure(err))
        })
    })
  }

  function request(changeOrderItemStatusRequest) {
    return {
      type: orderConstants.CHANGE_ORDER_ITEM_REQUEST,
      changeOrderItemStatusRequest,
    }
  }
  function success(changeOrderItemStatusSuccess) {
    return {
      type: orderConstants.CHANGE_ORDER_ITEM_SUCCESS,
      changeOrderItemStatusSuccess,
    }
  }
  function failure(changeOrderItemStatusFail) {
    return {
      type: orderConstants.CHANGE_ORDER_ITEM_ERROR,
      changeOrderItemStatusFail,
    }
  }
}
