import API from "../config/API"
import axios from "axios"

export const orderService = {
  getOrderList,
  getOrderListByFarmer,
  getOrderExcel,
  getAllOrderExcel,
  viewOrderList,
  changeHarvestStatus,
  changeHarvestStatusByFarmer,
  changeOrderStatus,
  changeOrderStatusByFarmer,
  changeOrderItemStatus,
}

function getOrderList(params) {
  return axios.get(`${API.GET_ORDER_LIST}/${params}`)
}

function getOrderListByFarmer(params) {
  return axios.get(`${API.GET_ORDERS_BY_FARMER}/${params}`)
}

function getOrderExcel(params) {
  return axios.get(`${API.GET_ORDER_EXCEL_LIST}/${params}`)
}

function getAllOrderExcel(params) {
  return axios.get(`${API.GET_ALL_ORDER_EXCEL_LIST}/${params}`)
}

function viewOrderList(params) {
  return axios.get(`${API.VIEW_ORDER_LIST}/${params}`)
}

function changeHarvestStatus(params) {
  return axios.post(API.CHANGE_HARVEST_STATUS, params)
}

function changeHarvestStatusByFarmer(params) {
  return axios.post(API.CHANGE_HARVEST_STATUS_BY_FARMER, params)
}

function changeOrderStatus(params) {
  return axios.post(API.CHANGE_ORDER_STATUS, params)
}

function changeOrderStatusByFarmer(params) {
  return axios.post(API.CHANGE_ORDER_STATUS_BY_FARMER, params)
}

function changeOrderItemStatus(params) {
  return axios.post(API.CHANGE_ORDER_ITEM_STATUS, params)
}
