import promise from "bluebird"
import { distributionConstants, usersConstants } from "../_constants"
import { distributionService, usersService } from "../_services"
import { ResponseFilter } from "../config/response-handler"
import { usersActions } from "./users.actions"

export const distributionActions = {
  addDistibution,
  getDistibutionDetails,
  getDistibutionDetailsById,
  editDistibution,
  deleteDistibutionDetails,
  getMarketList,
}

function addDistibution(params) {
  return function (dispatch) {
    return new promise(function (resolve, reject) {
      dispatch(request(params))
      distributionService
        .addDist(params)
        .then((response) => {
          let { serverResponseData } = ResponseFilter(response)
          resolve(serverResponseData)
          dispatch(success(serverResponseData))
        })
        .catch((err) => {
          reject(err)
          dispatch(failure(err))
        })
    })
  }

  function request(addDistributionRequest) {
    return {
      type: distributionConstants.ADD_DISTRIBUTION_REQUEST,
      addDistributionRequest,
    }
  }
  function success(addDistributionSuccess) {
    return {
      type: distributionConstants.ADD_DISTRIBUTION_SUCCESS,
      addDistributionSuccess,
    }
  }
  function failure(addDistributionFail) {
    return {
      type: distributionConstants.ADD_DISTRIBUTION_ERROR,
      addDistributionFail,
    }
  }
}

function getDistibutionDetails(params) {
  return function (dispatch) {
    return new promise(function (resolve, reject) {
      dispatch(request(params))
      distributionService
        .getDist(params)
        .then((response) => {
          let { serverResponseData } = ResponseFilter(response)
          resolve(serverResponseData)
          dispatch(success(serverResponseData))
        })
        .catch((err) => {
          reject(err)
          dispatch(failure(err))
        })
    })
  }

  function request(getDistributionRequest) {
    return {
      type: distributionConstants.GET_DISTRIBUTION_REQUEST,
      getDistributionRequest,
    }
  }
  function success(getDistributionSuccess) {
    return {
      type: distributionConstants.GET_DISTRIBUTION_SUCCESS,
      getDistributionSuccess,
    }
  }
  function failure(getDistributionFail) {
    return {
      type: distributionConstants.GET_DISTRIBUTION_ERROR,
      getDistributionFail,
    }
  }
}
function getDistibutionDetailsById(params) {
  return function (dispatch) {
    return new promise(function (resolve, reject) {
      dispatch(request(params))
      distributionService
        .getDistibutionDetailsById(params)
        .then((response) => {
          let { serverResponseData } = ResponseFilter(response)
          resolve(serverResponseData)
          dispatch(success(serverResponseData))
        })
        .catch((err) => {
          reject(err)
          dispatch(failure(err))
        })
    })
  }

  function request(getDistibutionDetailsByIdRequest) {
    return {
      type: distributionConstants.GET_DISTRIBUTION_BY_ID_REQUEST,
      getDistibutionDetailsByIdRequest,
    }
  }
  function success(getDistibutionDetailsByIdSuccess) {
    return {
      type: distributionConstants.GET_DISTRIBUTION_BY_ID_SUCCESS,
      getDistibutionDetailsByIdSuccess,
    }
  }
  function failure(getDistibutionDetailsByIdFail) {
    return {
      type: distributionConstants.GET_DISTRIBUTION_BY_ID_ERROR,
      getDistibutionDetailsByIdFail,
    }
  }
}

function getMarketList(params) {
  return function (dispatch) {
    return new promise(function (resolve, reject) {
      dispatch(request(params))
      distributionService
        .getMarketList(params)
        .then((response) => {
          let { serverResponseData } = ResponseFilter(response)
          resolve(serverResponseData)
          dispatch(success(serverResponseData))
        })
        .catch((err) => {
          reject(err)
          dispatch(failure(err))
        })
    })
  }
  function request(getMarListRequest) {
    return {
      type: distributionConstants.GET_MAR_LIST_REQUEST,
      getMarListRequest,
    }
  }
  function success(getMarListSuccess) {
    return {
      type: distributionConstants.GET_MAR_LIST_SUCCESS,
      getMarListSuccess,
    }
  }
  function failure(getMarListFail) {
    return { type: distributionConstants.GET_MAR_LIST_ERROR, getMarListFail }
  }
}

function deleteDistibutionDetails(params) {
  return function (dispatch) {
    return new promise(function (resolve, reject) {
      dispatch(request(params))
      distributionService
        .deleteDist(params)
        .then((response) => {
          let { serverResponseData } = ResponseFilter(response)
          resolve(serverResponseData)
          dispatch(success(serverResponseData))
        })
        .catch((err) => {
          reject(err)
          dispatch(failure(err))
        })
    })
  }

  function request(deleteDistributionRequest) {
    return {
      type: distributionConstants.DELETE_DISTRIBUTION_REQUEST,
      deleteDistributionRequest,
    }
  }
  function success(deleteDistributionSuccess) {
    return {
      type: distributionConstants.DELETE_DISTRIBUTION_SUCCESS,
      deleteDistributionSuccess,
    }
  }
  function failure(deleteDistributionFail) {
    return {
      type: distributionConstants.DELETE_DISTRIBUTION_ERROR,
      deleteDistributionFail,
    }
  }
}
function editDistibution(params) {
  return function (dispatch) {
    return new promise(function (resolve, reject) {
      dispatch(request(params))
      distributionService
        .editDist(params)
        .then((response) => {
          let { serverResponseData } = ResponseFilter(response)
          resolve(serverResponseData)
          dispatch(success(serverResponseData))
        })
        .catch((err) => {
          reject(err)
          dispatch(failure(err))
        })
    })
  }

  function request(editDistributionRequest) {
    return {
      type: distributionConstants.EDIT_DISTRIBUTION_REQUEST,
      editDistributionRequest,
    }
  }
  function success(editDistributionSuccess) {
    return {
      type: distributionConstants.EDIT_DISTRIBUTION_SUCCESS,
      editDistributionSuccess,
    }
  }
  function failure(editDistributionFail) {
    return {
      type: distributionConstants.EDIT_DISTRIBUTION_ERROR,
      editDistributionFail,
    }
  }
}
