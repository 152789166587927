export const obj2queryString = (obj) => {
  if (!obj || Array.isArray(obj) || typeof obj !== "object")
    throw Error("attempted to create a query string from a non-object")

  return Object.keys(obj)
    .filter((key) => {
      const value = obj[key]
      return (
        value !== undefined &&
        value !== null &&
        !Array.isArray(value) &&
        !(typeof value === "string" && value.length === 0)
      )
    })
    .map((key) => {
      return [key, obj[key]].map(encodeURIComponent).join("=")
    })
    .join("&")
}

export const queryString2Obj = ({ queryString }) => {
  const queryStringParts = queryString.split("?")

  if (queryStringParts.length !== 2) return undefined

  const query = queryStringParts[1]
  const params = query
    .split("&")
    .map((s) => {
      let [key, value] = s.split("=")
      if (!isNaN(parseInt(value))) value = +value
      return { key, value }
    })
    .reduce((params, kv) => {
      params[kv.key] = kv.value
      return params
    }, {})

  return params
}
