// import React from 'react'
import React, { Component } from "react"
import { Button, Col, Container, Form, Navbar, Row } from "react-bootstrap"
import { usersActions } from "../../_actions"
import { connect } from "react-redux"
import { getUserType } from "../../util/user"

const userType = getUserType()

class ResetPassword extends Component {
  constructor(props) {
    super(props)

    this.state = {
      email: "",
      password: "",
      userType,
      lat: "",
      long: "",
      confirmPassword: "",
      confirmPasswordErrorMsg: "",
      passwordErrorMsg: "",
      emailErrorMsg: "",
      errors: "",
      success: "",
    }
  }

  componentDidMount() {}

  validateEmail(email) {
    const pattern =
      /(([a-zA-Z0-9\-?\.?]+)@(([a-zA-Z0-9\-_]+\.)+)([a-z]{2,3}))+$/
    const result = pattern.test(email)
    return result
  }

  handleValidation = () => {
    let validate = true
    let { email, password, confirmPassword, userType } = this.state

    if (password === "" || password === undefined) {
      validate = false
      this.setState({
        passwordErrorMsg: "Please enter your password.",
      })
    } else if (password.length <= 4) {
      validate = false
      this.setState({
        passwordErrorMsg: "Password must be greater than 4 digits.",
      })
    } else {
      this.setState({
        passwordErrorMsg: "",
      })
    }

    if (confirmPassword === "" || confirmPassword === undefined) {
      validate = false
      this.setState({
        confirmPasswordErrorMsg: "Please enter confirm password.",
      })
    } else if (confirmPassword !== password) {
      validate = false
      this.setState({
        confirmPasswordErrorMsg:
          "Password and confirm password does not match.",
      })
    } else {
      this.setState({
        confirmPasswordErrorMsg: "",
      })
    }

    return validate
  }

  //sign in
  handleResetSubmit = (e) => {
    let { email, long, lat, password, userType } = this.state
    this.setState({ redirect: false, errors: "" })
    e.preventDefault()
    // let token= this.props.match.params.id;

    let self = this
    if (this.handleValidation()) {
      this.setState({ redirect: false, loader: true })
      let token = this.props.match.params.id

      let obj = {
        newPassword: password,
      }

      let { dispatch } = this.props
      dispatch(usersActions.reset(obj, token)).then((data) => {
        console.log(data, "Data:::::::lkjhgfdsdfghj")

        this.setState({ loader: false })

        if (data.data.statusCode == 1) {
          let success = data.data.responseData.message
          self.props.history.push("/successfullyreset")
          self.setState({ success: success })
        } else {
          self.setState({ errors: data.data.error.responseMessage })
        }
      })
    }
  }

  handleChanged = (e) => {
    console.log("onchange names", e)
    this.setState({
      [e.target.name]: e.target.value,
      confirmPasswordErrorMsg: "",
      passwordErrorMsg: "",
      // emailError: ''
    })
  }

  handleCloseModal = () => {
    this.setState({
      rs: "",
      success: "",
      firstNameErrorMsg: "",
      errors: "",
      emailErrorMsg: "",
      passwordErrorMsg: "",
      confirmPasswordErrorMsg: "",
    })
  }

  render() {
    let {
      email,
      password,
      passwordErrorMsg,
      emailErrorMsg,
      errors,
      success,
      confirmPasswordErrorMsg,
      confirmPassword,
    } = this.state
    return (
      <div className="body_container_wrapper login_page">
        <Container>
          <h1>
            <Navbar.Brand href="#home">
              <img
                src={require("../../assets/img/OFFICIAL LOGO TM WITH HEALTHY PLANEAT BLOCK.png")}
                alt="Logo"
              />
            </Navbar.Brand>
          </h1>
          <div className="login_wrapper">
            <Row>
              <Col md="4" className="mx-auto text-center">
                <div>
                  <img src={require("../../assets/img/OFFICIAL LOGO.png")} />
                </div>
                <h6>Reset Password</h6>
                {console.log(errors, "rrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrr")}
                <div className="error"> {errors} </div>
                <div className="success"> {success} </div>
                <Form.Group>
                  <Form.Control
                    type="Password"
                    onChange={this.handleChanged}
                    name="password"
                    value={password}
                    placeholder="New Password"
                  />
                  {passwordErrorMsg ? (
                    <div className="error">{passwordErrorMsg}</div>
                  ) : null}
                </Form.Group>{" "}
                <Form.Group>
                  <Form.Control
                    type="Password"
                    onChange={this.handleChanged}
                    name="confirmPassword"
                    value={confirmPassword}
                    placeholder="Confirm New Password"
                  />
                  {confirmPasswordErrorMsg ? (
                    <div className="error">{confirmPasswordErrorMsg}</div>
                  ) : null}
                </Form.Group>
                <Form.Group
                  controlId="formBasicCheckbox"
                  className="forgot_wrapper"
                >
                  {/*<p><small><Link to="/">Login</Link></small></p>*/}
                </Form.Group>
                <Button
                  type="submit"
                  onClick={this.handleResetSubmit}
                  variant="dark"
                >
                  Submit
                </Button>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    )
  }
}

function mapStateToProps(state) {
  const { props, login, user } = state
  return {
    props,
    login,
    user,
  }
}

export default connect(mapStateToProps)(ResetPassword)
// export default Header

// export default Login
