import React from "react"

export const STRIPE_SUBSCRIPTION_INTERVAL = {
  DAY: "day",
  MONTH: "month",
  YEAR: "year",
}

export const validateStripeSubscriptionInterval = (interval) => {
  return Object.values(STRIPE_SUBSCRIPTION_INTERVAL).includes(interval)
}

export const isSubscriptionItem = ({ inventoryItem }) => {
  return (
    inventoryItem.subscriptionInterval &&
    !isNaN(parseInt(inventoryItem.subscriptionIntervalCount)) &&
    parseInt(inventoryItem.subscriptionIntervalCount) > 0
  )
}

export const subscriptionFormatted = ({ inventoryItem }) => {
  let intervalCount = ""
  if (parseInt(inventoryItem.subscriptionIntervalCount) > 1) {
    intervalCount = inventoryItem.subscriptionIntervalCount
  }
  return `Orders every ${intervalCount} ${inventoryItem.subscriptionInterval}${
    parseInt(inventoryItem.subscriptionIntervalCount) > 1 ? "s" : ""
  }`
}

export const SubscriptionInfo = (props) => {
  const { inventoryItem } = props
  if (!isSubscriptionItem({ inventoryItem })) return null
  return (
    <p class="subscription-info">
      Subscription:
      <br />
      <span class="subscription-info-details">
        {subscriptionFormatted({ inventoryItem })}
      </span>
    </p>
  )
}
