export const distributionConstants = {
  GET_DISTRIBUTION_REQUEST: "GET_DISTRIBUTION_REQUEST",
  GET_DISTRIBUTION_SUCCESS: "GET_DISTRIBUTION_SUCCESS",
  GET_DISTRIBUTION_ERROR: "GET_DISTRIBUTION_ERROR",

  GET_DISTRIBUTION_BY_ID_REQUEST: "GET_DISTRIBUTION_BY_ID_REQUEST",
  GET_DISTRIBUTION_BY_ID_SUCCESS: "GET_DISTRIBUTION_BY_ID_SUCCESS",
  GET_DISTRIBUTION_BY_ID_ERROR: "GET_DISTRIBUTION_BY_ID_ERROR",

  GET_MAR_LIST_REQUEST: "GET_MAR_LIST_REQUEST",
  GET_MAR_LIST_SUCCESS: "GET_MAR_LIST_SUCCESS",
  GET_MAR_LIST_ERROR: "GET_MAR_LIST_ERROR",

  ADD_DISTRIBUTION_REQUEST: "ADD_DISTRIBUTION_REQUEST",
  ADD_DISTRIBUTION_SUCCESS: "ADD_DISTRIBUTION_SUCCESS",
  ADD_DISTRIBUTION_ERROR: "ADD_DISTRIBUTION_ERROR",

  EDIT_DISTRIBUTION_REQUEST: "EDIT_DISTRIBUTION_REQUEST",
  EDIT_DISTRIBUTION_SUCCESS: "EDIT_DISTRIBUTION_SUCCESS",
  EDIT_DISTRIBUTION_ERROR: "EDIT_DISTRIBUTION_ERROR",

  DELETE_DISTRIBUTION_REQUEST: "DELETE_DISTRIBUTION_REQUEST",
  DELETE_DISTRIBUTION_SUCCESS: "DELETE_DISTRIBUTION_SUCCESS",
  DELETE_DISTRIBUTION_ERROR: "DELETE_DISTRIBUTION_ERROR",
}

export const DISTRIBUTION_CATEGORY = {
  FARM: 3,
  FARMERS_MARKET: 2,
  POPUP_MARKET: 1,
}

export const DISTRIBUTION_TYPE = {
  PICKUP: 1,
  DELIVERY: 2,
  SHIPPING: 3,
}

export const DISTRIBUTION_TYPE_STRING = {
  PICKUP: "Pickup",
  DELIVERY: "Delivery",
  SHIPPING: "Shipping",
}

export const distributionTypeStringForInt = ({ distributionType }) => {
  if (distributionType === DISTRIBUTION_TYPE.PICKUP)
    return DISTRIBUTION_TYPE_STRING.PICKUP
  if (distributionType === DISTRIBUTION_TYPE.DELIVERY)
    return DISTRIBUTION_TYPE_STRING.DELIVERY
  if (distributionType === DISTRIBUTION_TYPE.SHIPPING)
    return DISTRIBUTION_TYPE_STRING.SHIPPING
  return null
}

export const DISTRIBUTION_CLIENT = {
  CUSTOMER: 1,
  WHOLESALER: 2,
  CUSTOMER_AND_WHOLESALER: 3,
}

export const DISTRIBUTION_CLIENT_STRING = {
  CUSTOMER: "Customer",
  WHOLESALER: "Wholesaler",
  CUSTOMER_AND_WHOLESALER: "Customer and Wholesaler",
}

export const distributionClientStringForInt = ({ deliveryFor }) => {
  if (deliveryFor === DISTRIBUTION_CLIENT.CUSTOMER)
    return DISTRIBUTION_CLIENT_STRING.CUSTOMER
  if (deliveryFor === DISTRIBUTION_CLIENT.WHOLESALER)
    return DISTRIBUTION_CLIENT_STRING.WHOLESALER
  if (deliveryFor === DISTRIBUTION_CLIENT.CUSTOMER_AND_WHOLESALER)
    return DISTRIBUTION_CLIENT_STRING.CUSTOMER_AND_WHOLESALER
  return null
}
