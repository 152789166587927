import "jspdf-autotable"

import * as html2canvas from "html2canvas"
import * as jsPDF from "jspdf"

import { Button, Col, Row } from "react-bootstrap"
import React, { Component } from "react"
import { farmActions, orderActions, usersActions } from "../../_actions"

import { Preview } from "react-html2pdf"
import Sidebar from "../../components/sidebar"
import _ from "lodash"
import { connect } from "react-redux"
import exportFromJSON from "export-from-json"

const days = (type) => {
  switch (type) {
    case 0:
      return "Sun"
      break
    case 1:
      return "Mon"
      break
    case 2:
      return "Tue"
      break
    case 3:
      return "Wed"
      break
    case 4:
      return "Thur"
      break
    case 5:
      return "Fri"
      break
    case 6:
      return "Sat"
      break
  }
}

class ViewOrderDetails extends Component {
  constructor(props) {
    super(props)

    this.state = {
      orderInfo: [],
      ItemList: [],
      reId: [],
      ItemId: [],
      multiValue: [],
      multiItemValue: [],
      reasonErMsg: "",
      show: false,
      farmName: "",
      showInvoice: false,
      messagePopup: 0,
      alertPopup: 0,
      emailPopup: 0,
      lat: 0,
      long: "",
      count: 10,
      totalCount: 0,
      filterHarvest: "",
      pageNo: 1,
      orderInfo1: [],
      profilePicPreview: "",
      userId: localStorage.getItem("userId"),
    }
  }

  componentDidMount() {
    this.getUserInfo()
    this.getOrderList()
    this.getFarmDetails()
  }

  getFarmDetails = () => {
    let { userId } = this.state
    this.setState({ errors: "" })

    let self = this
    this.setState({ loader: true })

    let params = `?userId=${userId}&farmerId=${userId}`

    let { dispatch } = this.props
    dispatch(farmActions.getFarmDetails(params)).then((data) => {
      if (!_.isEmpty(data)) {
        let farmInfo = data.responseData.result
        self.setState({ loader: false })

        if (data.statusCode === 1) {
          self.setState({
            profilePicPreview: farmInfo.length > 0 ? farmInfo[0].farmLogo : "",
            farmName: farmInfo.length > 0 ? farmInfo[0].farmName : "",

            addressLine1:
              farmInfo.length > 0 && !_.isEmpty(farmInfo[0].farmAddress)
                ? farmInfo[0].farmAddress[0].addressLine1
                : "",
            addressLine2:
              farmInfo.length > 0 && !_.isEmpty(farmInfo[0].farmAddress)
                ? farmInfo[0].farmAddress[0].addressLine2
                : "",
            city:
              farmInfo.length > 0 && !_.isEmpty(farmInfo[0].farmAddress)
                ? farmInfo[0].farmAddress[0].city
                : "",
            state:
              farmInfo.length > 0 && !_.isEmpty(farmInfo[0].farmAddress)
                ? farmInfo[0].farmAddress[0].state
                : "",
            postalCode:
              farmInfo.length > 0 && !_.isEmpty(farmInfo[0].farmAddress)
                ? farmInfo[0].farmAddress[0].postalCode
                : "",
          })
        } else {
          self.setState({ errors: data.error.responseMessage })
        }
      }
    })
  }

  printDocument = () => {
    let self = this

    let { userId } = this.state
    let params = `?&userId=${userId}&count=${500}`

    let { dispatch } = this.props
    dispatch(orderActions.getOrderList(params)).then((data) => {
      let inventoryData = data

      self.setState({ loader: false })

      if (inventoryData.statusCode === 1) {
        let inventoryInfo1 = data && data.responseData.OrderDetails.getOrder

        var doc = new jsPDF()

        if (inventoryInfo1) {
          doc.autoTable({ html: "#divToPrint1" })
          let table1 = inventoryInfo1.map((item, index) => [
            index + 1,
            self.ConvertDate(item.created),
            item.billingInfo.firstName + " " + item.billingInfo.lastName,
            "$" + item.amount,
            item.billingInfo.addressLine1 +
              item.billingInfo.addressLine2 +
              item.billingInfo.city +
              item.billingInfo.state +
              item.billingInfo.country +
              item.billingInfo.postalCode,
            item.harvestStatus === "1"
              ? "In Harvest List"
              : item.harvestStatus === "2"
              ? "Fulfilled"
              : item.harvestStatus === "3"
              ? "Out of Stock"
              : "New Order",
            item.description ? item.description : "",
          ])

          doc.autoTable({
            head: [
              [
                "Order",
                "Placed On",
                "Placed On",
                "Order Total",
                "Pick Up/Delivery Location",
                "Status",
              ],
            ],
            body: table1.length === 0 ? [["no data available "]] : table1,
          })
          setTimeout(() => doc.save("order.pdf"), 3000)
        }
      } else {
        this.setState({ errors: inventoryData.error.responseMessage })
      }
    })
  }

  modalPrint = () => {
    html2canvas(document.getElementById("divIdToPrint"), {
      useCORS: true,
    }).then((canvas1) => {
      html2canvas(document.getElementById("divIdToPrint"), {
        useCORS: true,
      }).then((canvas) => {
        let w = window.innerWidth
        let h = window.innerHeight
        const imgData = canvas.toDataURL("image/png")
        const pdf = new jsPDF()
        pdf.addImage(imgData, "JPEG", 5, 10, 220, 100)
        pdf.save("download.pdf")
      })
    })
  }

  printExcel = () => {
    let self = this

    let { userId } = this.state
    let params = `?&userId=${userId}&count=${500}`

    let { dispatch } = this.props
    dispatch(orderActions.getOrderList(params)).then((data) => {
      let inventoryData = data

      self.setState({ loader: false })

      if (inventoryData.statusCode === 1) {
        let inventoryInfo1 = data && data.responseData.OrderDetails.getOrder

        var doc = new jsPDF()

        if (inventoryInfo1) {
          let arr = []
          doc.autoTable({ html: "#divToPrint1" })
          inventoryInfo1.map((item, index) => {
            arr.push({
              Order: index + 1,
              "Placed On": self.ConvertDate(item.created),
              Customer:
                item.billingInfo.firstName + " " + item.billingInfo.lastName,
              Total: "$" + item.amount,
              Location:
                item.billingInfo.addressLine1 +
                item.billingInfo.addressLine2 +
                item.billingInfo.city +
                item.billingInfo.state +
                item.billingInfo.country +
                item.billingInfo.postalCode,
              Status:
                item.harvestStatus === "1"
                  ? "Fulfilled"
                  : item.harvestStatus === "2"
                  ? "In Harvest List"
                  : item.harvestStatus === "3"
                  ? "Out of Stock"
                  : "New Order",
            })
          })

          exportFromJSON({
            data: arr,
            fileName: "data",
            exportType: exportFromJSON.types.xls,
          })
        }
      } else {
        this.setState({ errors: inventoryData.error.responseMessage })
      }
    })
  }

  sum = (product) => {
    if (!_.isEmpty(product)) {
      let countPrice = 0
      for (let i = 0; i < product.length; i++) {
        countPrice =
          (product[i].productPrice * product[i].productQuantity
            ? product[i].productQuantity
            : "") + countPrice
      }
      return countPrice
    }
  }

  sumDelivery = (product) => {
    if (!_.isEmpty(product)) {
      let countPrice = 0
      for (let i = 0; i < product.length; i++) {
        countPrice = product[i].deliveryFees + countPrice
      }

      return countPrice.toFixed(2)
    }
  }

  getUserInfo = () => {
    this.setState({ errors: "", redirect: false, loader: false })

    let { dispatch } = this.props
    dispatch(usersActions.getUserInfo()).then((data) => {
      this.setState({ loader: false })

      if (data.statusCode === 1) {
        let userInfo = data.responseData.userProfile
        localStorage.setItem("userId", userInfo._id)

        this.setState({
          userInfo: userInfo,
          firstName: userInfo.firstName,
          lastName: userInfo.lastName,
          phone: userInfo.phone,
          email: userInfo.email,
          address: userInfo.address,
          addressLine1:
            userInfo.address.length > 0
              ? userInfo.address[0].addressLine1
                ? userInfo.address[0].addressLine1
                : ""
              : "",
          addressLine2:
            userInfo.address.length > 0
              ? userInfo.address[0].addressLine2
                ? userInfo.address[0].addressLine2
                : ""
              : "",
          state:
            userInfo.address.length > 0
              ? userInfo.address[0].state
                ? userInfo.address[0].state
                : ""
              : "",
          postalCode:
            userInfo.address.length > 0
              ? userInfo.address[0].postalCode
                ? userInfo.address[0].postalCode
                : ""
              : "",
          city:
            userInfo.address.length > 0
              ? userInfo.address[0].city
                ? userInfo.address[0].city
                : ""
              : "",
          addressId:
            userInfo.address.length > 0
              ? userInfo.address[0]._id
                ? userInfo.address[0]._id
                : ""
              : "",
          minimumOrderValue: userInfo.minimumOrderValue,
          certificate: userInfo.certificateImage,
          certificatePreview: userInfo.certificateImage,
          insuranceDoc: userInfo.insuranceImage,
          insurancePreview: userInfo.insuranceImage,
          radius: userInfo.radius,
          emailPopup: userInfo.notificationEmail,
          messagePopup: userInfo.notificationTextMassage,
          alertPopup: userInfo.notificationAppAlert,
          lat: userInfo.geoLocation[0],
          long: userInfo.geoLocation[1],
        })
      } else {
        this.setState({ errors: data.error.responseMessage })
      }
    })
  }
  getOrderList = () => {
    this.setState({ errors: "" })
    let { userId } = this.state
    this.setState({ redirect: false, loader: true })
    let params = `?&userId=${userId ? userId : ""}&orderId=${
      this.props.match.params.id
    }`

    let { dispatch } = this.props
    dispatch(orderActions.viewOrderList(params)).then((data) => {
      this.setState({ loader: false })

      if (data.statusCode === 1) {
        let orderInfo = data.responseData.OrderDetails[0]

        this.setState({
          ItemList: orderInfo,
        })
      } else {
        this.setState({ errors: data.error.responseMessage })
      }
    })
  }

  handleItemMaltiSelct = (e, val, ItemList) => {
    let { orderInfo, multiItemValue } = this.state
    this.setState({ reasonErMsg: "" })
    multiItemValue = []

    console.log(e, val, "this.state.itemId", ItemList)
    if (this.state.itemId !== undefined && this.state.itemId.includes(val)) {
      for (var i = 0; i < this.state.itemId.length; i++) {
        if (this.state.itemId[i] === val) {
          this.state.itemId.splice(i, 1)
        }
      }
    } else {
      if (this.state.itemId !== undefined) {
        this.state.itemId.push(val)
      }
    }

    ItemList.map((value, index) => {
      if (value._id === val) {
        ItemList[index].status = orderInfo[index].status === 2 ? 3 : 2

        if (ItemList[index].itemStatus === 2) {
          multiItemValue.push(value._id)
        } else if (ItemList[index].itemStatus === 3) {
          multiItemValue.splice(value._id)
        }
      }
    })
    this.setState({ multiItemValue: [...this.state.multiItemValue, val] })
  }

  handleItemAction = (id, harvestStatus) => {
    let unique = [...new Set(this.state.multiItemValue)]
    let params = {
      orderId: id,
      itemId: unique,
      itemStatus: harvestStatus,
    }

    let self = this
    let { dispatch } = this.props
    this.setState({ loader: true })
    self.setState({ show: false })

    dispatch(orderActions.changeOrderItemStatus(params)).then((data) => {
      if (data.statusCode === 1) {
        self.setState({
          loader: false,
          itemId: [],
          multiValue: "",
          reasonErMsg: "",
        })
        self.getOrderList()
      } else {
        self.setState({ loader: false })

        this.setState({
          itemId: [],
          multiValue: "",
          reasonErMsg: "",
          errors: data.error.responseMessage,
        })
      }
    })
  }

  ConvertDate = (dateToConvert) => {
    var created = new Date(dateToConvert)

    let year = created.getFullYear()
    let month = created.getMonth()
    let date = created.getDate()
    let Hour = created.getHours()
    let Minutes = created.getMinutes()
    let day = created.getDay()

    let formedDate = month + "/" + date + "/" + year
    let formedTime = Hour + ":" + Minutes
    let am = Hour > 12 ? "pm" : "am"
    let data = formedTime + " " + am + " " + days(day) + " " + formedDate

    return data
  }

  handleShowInvoice = (item) => {
    this.setState({ showInvoice: true, ItemList: item })
  }

  OrderMp = (ItemList) => {
    console.log(ItemList, "ItemListItemList")
    let Json =
      !_.isEmpty(ItemList.buyLists) &&
      ItemList.buyLists.map((val, i) => (
        <tr>
          <td>
            {val.productQuantity} {val.productName}
          </td>
          <td>
            <span>
              ${val.productPrice}x
              {val.productQuantity + " " + (val.unit ? val.unit : "")}
            </span>
          </td>
          <td>
            <span className="inlinetext-align">
              <span className="inabs">
                <input
                  type="checkbox"
                  indeterminate
                  value={val.productId}
                  onChange={(e) =>
                    this.handleItemMaltiSelct(
                      e,
                      val.productId && val.productId,
                      ItemList.buyLists,
                      ItemList._id
                    )
                  }
                />
              </span>
              <span className="cm-aligntext">
                {val.itemStatus === 1
                  ? "In Harvest List"
                  : val.itemStatus === 2
                  ? "Fulfilled"
                  : val.itemStatus === 3
                  ? "Out of Stock"
                  : "New Order"}
              </span>
            </span>
          </td>
          <td>
            {val.image && <img src={val.image} height="40px" width="40px" />}
          </td>
          <td>{val.farmName}</td>
          <td>{val.address}</td>
        </tr>
      ))

    return Json
  }

  OrderMpInvoice = (ItemList) => {
    return (
      ItemList.buyLists &&
      ItemList.buyLists.map((val, i) => (
        <tr>
          &nbsp;&nbsp;&nbsp;
          <td className="marGinOrderTwo">
            {" "}
            {val.productName} - {val.productQuantity}
          </td>
          <td>
            <span>
              ${val.productPrice} x {val.productQuantity} {val.unit}
            </span>
          </td>
          <td>
            <span>{val.address}</span>
          </td>
        </tr>
      ))
    )
  }

  OrderHarvest1 = (ItemList) => {
    return (
      ItemList.buyLists &&
      ItemList.buyLists.map((val, i) => (
        <>
          {val.productName} <br />
        </>
      ))
    )
  }

  OrderHarvest2 = (ItemList) => {
    return (
      ItemList.buyLists &&
      ItemList.buyLists.map((val, i) => (
        <>
          {val.productQuantity ? val.productQuantity : ""} <br />
        </>
      ))
    )
  }

  OrderHarvest3 = (ItemList) => {
    return (
      ItemList.buyLists &&
      ItemList.buyLists.map((val, i) => (
        <>
          $ {val.productQuantity * val.productPrice}
          <br />
        </>
      ))
    )
  }

  render() {
    let { ItemList } = this.state

    return (
      <>
        <Sidebar />
        <div className="body_container">
          <div className="body_inner_wrapper">
            {this.state.loader ? (
              <div className="loader_wrapper">
                <div class="spinner-border text-info" role="status"></div>
              </div>
            ) : (
              <>
                <div className="invoicewrapper">
                  <Preview id={"jsx-template"}>
                    <header className="header_content">
                      <p>Order Details</p>
                      <div className="d-flex text-nowrap header_btn">
                        <div className="print_button orderabs noabs ">
                          <Button onClick={this.modalPrint} variant="light">
                            <i className="fas fa-print"></i> Print{" "}
                          </Button>
                        </div>
                      </div>
                    </header>

                    <div id={"divIdToPrint"} className="pdfwrapper orderview">
                      <Row className="p-3">
                        <Col>
                          {ItemList.billingInfo &&
                          ItemList.billingInfo.firstName ? (
                            <p>
                              Customer:{" "}
                              <span>
                                {ItemList.billingInfo.firstName}{" "}
                                {ItemList.billingInfo.lastName}
                              </span>
                            </p>
                          ) : (
                            ""
                          )}

                          {ItemList.billingInfo &&
                          ItemList.billingInfo.phoneNumber ? (
                            <p>
                              Phone:{" "}
                              <span>{ItemList.billingInfo.phoneNumber} </span>
                            </p>
                          ) : (
                            ""
                          )}

                          {ItemList.billingInfo && ItemList.billingInfo ? (
                            <p>
                              Billing Address:{" "}
                              <span>
                                {ItemList.billingInfo.addressLine1}{" "}
                                {ItemList.billingInfo.addressLine2}{" "}
                                {ItemList.billingInfo.city}{" "}
                                {ItemList.billingInfo.state}{" "}
                                {ItemList.billingInfo.country}{" "}
                                {ItemList.billingInfo.postalCode}
                              </span>
                            </p>
                          ) : (
                            ""
                          )}

                          {
                            <p>
                              Placed On:
                              <span>{this.ConvertDate(ItemList.created)}</span>
                            </p>
                          }
                        </Col>
                      </Row>

                      <div className="order_items px-3">
                        <div className="table-responsive">
                          <table className="table">
                            <thead>
                              <th className="ws-nowrap">Product Name</th>
                              <th className="ws-nowrap">Product Price</th>
                              <th className="ws-nowrap">Status</th>
                              <th className="ws-nowrap">Product Image</th>
                              <th className="ws-nowrap">Farm Name</th>
                              <th className="ws-nowrap">Farm Address</th>
                            </thead>
                            <tbody>{this.OrderMp(ItemList)}</tbody>
                          </table>
                        </div>
                      </div>

                      <div className="px-3">
                        <p>
                          Order Total:
                          <span>${ItemList.amount}</span>
                        </p>

                        <p>
                          Delivery Charges:
                          <span>
                            ${this.sumDelivery(ItemList && ItemList.buyLists)}
                          </span>
                        </p>

                        <p>
                          Total:
                          <span>
                            {" "}
                            $
                            {ItemList.amount +
                              this.sumDelivery(ItemList && ItemList.buyLists)}
                          </span>
                        </p>
                      </div>

                      <div className="print_button orderabs"></div>
                    </div>
                    <div className="pdfbtn">
                      <Button
                        variant="warning"
                        className="text-light"
                        onClick={() => this.handleItemAction(ItemList._id, 2)}
                      >
                        Order Fulfilled
                      </Button>
                      <Button
                        variant="dark"
                        className=""
                        onClick={() => this.handleItemAction(ItemList._id, 3)}
                      >
                        Out Of Stock
                      </Button>
                      <Button
                        variant="info"
                        onClick={() => this.handleItemAction(ItemList._id, 1)}
                      >
                        Add To Harvest
                      </Button>
                    </div>
                    <br />
                    <br />
                  </Preview>
                </div>
                <div className="footer_btn_wrapper"></div>
              </>
            )}{" "}
          </div>
        </div>
      </>
    )
  }
}

function mapStateToProps(state) {
  const { props, login, user, mapReducer } = state

  return {
    props,
    login,
    user,
    mapReducer,
  }
}

export default connect(mapStateToProps)(ViewOrderDetails)
