import { usersConstants } from "../_constants"

const initialState = {}

export function login(state = initialState, action) {
  switch (action.type) {
    case usersConstants.LOGIN_SUCCESS:
      let userInfo = action.loginSuccess
      return userInfo
    default:
      return state
  }
}

export function user(state = initialState, action) {
  switch (action.type) {
    case usersConstants.GET_USER_INFO_SUCCESS:
      let userInfo = action.getUserInfoSuccess
      state.userInfo = userInfo
      return { ...state }
    case usersConstants.LOGOUT_SUCCESS:
      let logoutInfo = action.logoutSuccess
      return { logoutInfo }
    case usersConstants.SIGNUP_SUCCESS:
      let signupInfo = action.signupSuccess
      return { signupInfo }
    case usersConstants.FORGOT_SUCCESS:
      let forgotInfo = action.forgotSuccess
      return forgotInfo
    case usersConstants.RESET_SUCCESS:
      let resetInfo = action.resetSuccess
      return resetInfo
    case usersConstants.EDIT_PROFILE_SUCCESS:
      let editProfileInfo = action.editProfileSuccess
      return editProfileInfo
    case usersConstants.CHANGE_PASS_SUCCESS:
      let editPassInfo = action.editPassSuccess
      return editPassInfo
    case usersConstants.AUTH_SUCCESS:
      let authInfo = action.authSuccess
      return authInfo
    case usersConstants.ADD_ACCOUNT_SUCCESS:
      let addAccountInfo = action.addAccountSuccess
      return addAccountInfo
    case usersConstants.GET_BANK_SUCCESS:
      let getBankInfoInfo = action.getBankInfoSuccess
      return getBankInfoInfo
    case usersConstants.ADD_ADDRESS_SUCCESS:
      let addAddressSuccessInfo = action.addAddressSuccess
      return addAddressSuccessInfo
    case usersConstants.EDIT_ADDRESS_SUCCESS:
      let editAddressSuccessInfo = action.editaddressSuccess
      return editAddressSuccessInfo
    case usersConstants.UPLOAD_DOC_SUCCESS:
      let uploadDocumenSuccessInfo = action.uploadDocumentSuccess
      return uploadDocumenSuccessInfo
    default:
      return { ...state }
  }
}
