import React, { Component } from "react"
import { Modal, Button } from "react-bootstrap"
import siteSetting from "../config/env/index"

class USPSLoginModal extends Component {
  handleUSPSLogin = () => {
    const { farmId } = this.props
    const uspsLoginUrl = `${siteSetting.config.USPSLoginURL}&state=${farmId}&redirect_uri=${siteSetting.config.USPSRedirectURL}&reauth=true`
    window.location.href = uspsLoginUrl
  }

  render() {
    const { show, onHide } = this.props

    return (
      <Modal show={show} onHide={onHide}>
        <Modal.Header closeButton>
          <Modal.Title>Login to USPS</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Please log in to USPS to proceed with printing labels.</p>
          <Button variant="primary" onClick={this.handleUSPSLogin}>
            Login to USPS
          </Button>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={onHide}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    )
  }
}

export default USPSLoginModal
