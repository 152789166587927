import { Button, Col, Container, Form, Navbar, Row } from "react-bootstrap"
import React, { Component } from "react"

import CryptoJS from "crypto-js"
import { Link } from "react-router-dom"
import axios from "axios"
import { connect } from "react-redux"

import { usersActions } from "../_actions"
import { getUserType } from "../util/user"
import siteSetting from "../config/env"

const userType = getUserType()

class Login extends Component {
  constructor(props) {
    super(props)

    this.state = {
      email: "",
      password: "",
      userType,
      lat: "",
      long: "",
      passwordErrorMsg: "",
      emailErrorMsg: "",
      errors: "",
      success: "",
      checkBoxValue: 0,
      rememberMe: true,
    }
    this.convertHash = this.convertHash.bind(this)
  }

  componentDidMount() {}

  componentWillMount() {
    if (localStorage.getItem("hash_e") || localStorage.getItem("hash_p")) {
      let mykeyd = CryptoJS.AES.decrypt(
        localStorage.getItem("hash_p"),
        "secret123"
      )
      let mysd = mykeyd.toString(CryptoJS.enc.Utf8)

      let myEmal = localStorage.getItem("hash_e")
      this.setState({ email: myEmal, password: mysd, rememberMe: true })
    } else {
      this.setState({ rememberMe: false })
    }
  }

  validateEmail(email) {
    const pattern =
      /(([a-zA-Z0-9\-?\.?]+)@(([a-zA-Z0-9\-_]+\.)+)([a-z]{2,3}))+$/
    const result = pattern.test(email)
    return result
  }

  convertHash(e) {
    let { email, password } = this.state
    if (e == 0) {
      var myEml = email
      var pass = CryptoJS.AES.encrypt(password, "secret123").toString()

      sessionStorage.setItem("hash_e", myEml)
      sessionStorage.setItem("hash_p", pass)

      localStorage.setItem("hash_e", myEml)
      localStorage.setItem("hash_p", pass)
      this.setState({ checkBoxValue: 1, rememberMe: true })
    } else if (e == 1) {
      sessionStorage.removeItem("hash_e")
      sessionStorage.removeItem("hash_p")
      localStorage.removeItem("hash_e")
      localStorage.removeItem("hash_p")
      this.setState({ checkBoxValue: 0, rememberMe: false })
    }
  }

  handleSignInValidation = () => {
    let validate = true
    let { email, password, userType } = this.state

    if (email === "" || email === undefined) {
      validate = false
      this.setState({
        emailErrorMsg: "Please enter your email address.",
      })
    } else if (!this.validateEmail(String(email.toLowerCase()))) {
      validate = false
      this.setState({ emailErrorMsg: "Please enter a valid email address." })
    } else {
      this.setState({
        emailErrorMsg: "",
      })
    }

    if (password === "" || password === undefined) {
      validate = false
      this.setState({
        passwordErrorMsg: "Please enter your password.",
      })
    } else if (password.length <= 4) {
      validate = false
      this.setState({
        passwordErrorMsg: "Password must be greater than 4 digits.",
      })
    } else {
      this.setState({
        passwordErrorMsg: "",
      })
    }

    return validate
  }

  //sign in
  handleSignInSubmit = (e) => {
    let { email, long, lat, password, userType } = this.state
    this.setState({ redirect: false, errors: "" })
    e.preventDefault()

    let self = this
    if (this.handleSignInValidation()) {
      this.setState({ redirect: false, loader: true })

      let obj = {
        email: email,
        password: password,
        userType: userType,
      }

      let { dispatch } = this.props
      dispatch(usersActions.login(obj)).then((data) => {
        this.setState({ loader: false })

        if (data.data.statusCode == 1) {
          axios.defaults.headers.common[
            "accessToken"
          ] = `${data.data.responseData.accessToken}`

          localStorage.setItem(
            "accessToken",
            data.data.responseData.accessToken
          )

          if (siteSetting.isFarmer) self.props.history.push("/order")
          if (siteSetting.isHost) self.props.history.push("/pickups")
        } else {
          this.setState({ errors: data.data.error.responseMessage })
          self.props.history.push("/")
        }
      })
    }
  }

  handleChanged = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    })
  }

  handleCloseModal = () => {
    this.setState({
      rs: "",
      success: "",
      firstNameErrorMsg: "",
      errors: "",
      emailErrorMsg: "",
      passwordErrorMsg: "",
      confirmPasswordErrorMsg: "",
    })
  }

  render() {
    let { email, password, passwordErrorMsg, emailErrorMsg, errors, success } =
      this.state
    return (
      <div className="body_container_wrapper login_page">
        <Container>
          <h1>
            <Navbar.Brand href="#home">
              <img
                src={require("../assets/img/OFFICIAL LOGO TM WITH HEALTHY PLANEAT BLOCK.png")}
                alt="Logo"
              />
            </Navbar.Brand>
          </h1>
          <div className="login_wrapper">
            <Row>
              <Col lg="4" className="mx-auto text-center">
                <div>
                  <img src={require("../assets/img/OFFICIAL LOGO.png")} />
                </div>
                <h6>Sign In</h6>
                <div className="error"> {errors} </div>
                <div className="success"> {success} </div>
                <Form.Group>
                  <Form.Control
                    autoComplete="off"
                    type="email"
                    onChange={this.handleChanged}
                    name="email"
                    value={email}
                    placeholder="Email Address"
                  />
                  {emailErrorMsg ? (
                    <div className="error">{emailErrorMsg}</div>
                  ) : null}
                </Form.Group>
                <Form.Group>
                  <Form.Control
                    autoComplete="off"
                    type="Password"
                    onChange={this.handleChanged}
                    name="password"
                    value={password}
                    placeholder="Password"
                  />
                  {passwordErrorMsg ? (
                    <div className="error">{passwordErrorMsg}</div>
                  ) : null}
                </Form.Group>
                <Form.Group
                  controlId="formBasicCheckbox"
                  className="forgot_wrapper"
                >
                  <div>
                    <label className="cm_checkbox">
                      <input
                        type="checkbox"
                        checked={this.state.rememberMe}
                        onChange={this.convertHash.bind(
                          this,
                          this.state.checkBoxValue
                        )}
                      />
                      <span></span>
                    </label>
                    <label htmlFor="test">Remember Me</label>
                  </div>
                  <p>
                    <small>
                      <Link to="/forgot">Forgot Password?</Link>
                    </small>
                  </p>
                </Form.Group>
                <Button
                  type="submit"
                  className="max-w-100"
                  onClick={this.handleSignInSubmit}
                  variant="dark"
                >
                  Sign In
                </Button>
              </Col>
            </Row>
          </div>
        </Container>
        <div className="footerloginbg"></div>
      </div>
    )
  }
}

function mapStateToProps(state) {
  const { props, login, user } = state
  return {
    props,
    login,
    user,
  }
}

export default connect(mapStateToProps)(Login)
